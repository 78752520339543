import React from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#e6e6e6',
    borderRadius: '15px',
    padding: '0.5rem 1rem',
  },
  spaceRoot: {
    justifyContent: 'space-between',
  },
}));

const SharedDescription = ({ requester, date, service, protocol }) => {
  const classes = useStyles();

  return (
    <Box display="flex" flexDirection="column" mb={4} className={classes.root}>
      <Box display="flex" alignItems="center" mb={1} className={classes.spaceRoot}>
        <Box display="flex" alignItems="center">
          <Box fontSize={12} fontWeight={700}>
            Solicitante da Ocorrência:
          </Box>
          <Box ml={2} fontSize={12} fontWeight={500}>
            {requester}
          </Box>
        </Box>
        <Box display="flex" alignItems="center">
          <Box fontSize={12} fontWeight={700}>
            Protocolo:
          </Box>
          <Box ml={2} fontSize={12} fontWeight={500}>
            {protocol}
          </Box>
        </Box>
      </Box>
      <Box display="flex" alignItems="center" className={classes.spaceRoot}>
        <Box display="flex" alignItems="center">
          <Box fontSize={12} fontWeight={700}>
            Serviço:
          </Box>
          <Box ml={2} fontSize={12} fontWeight={500}>
            {service}
          </Box>
        </Box>
        <Box ml={4} display="flex" alignItems="center">
          <Box fontSize={12} fontWeight={700}>
            Aberta em:
          </Box>
          <Box ml={2} fontSize={12} fontWeight={500}>
            {date}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default SharedDescription;
