import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesCounter } from '../../../redux/actions/f5-actions/ocurrences-counter';
import OcurrenceNavItem from './OcurrenceNavItem';
import OcurrenceNavMenu from './OcurrenceNavMenu';
import { Box } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  navRoot: {
    backgroundColor: 'white',
    borderRadius: '15px',
  },
}));

const OcurrenceNavbar = () => {
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const [selected, setSelected] = useState(0);
  const matchesLg = useMediaQuery(theme => theme.breakpoints.up('lg'));
  const matchesMd = useMediaQuery(theme => theme.breakpoints.up('md'));
  const countData = useSelector(({ ocurrences_counter }) => ocurrences_counter);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesCounter());
  }, [dispatch]);

  const classes = useStyles();

  // Os valores em "query" representam as medias queries definidas (1 = sm ... 3 = lg). Esse valor é usado para criar o menu dropdown responsivo.
  const ocurrencesTabs = [
    {
      name: 'Geral',
      bgcolor: 'rgba(35, 35, 35, 1)',
      page: '/ocorrencias',
      notifications: 0,
      query: 1,
      id: 0,
      access: ['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
    },
    {
      name: 'Recebidas',
      bgcolor: '#255ca8',
      page: '/ocorrencias/recebidas',
      notifications: countData.contagem ? countData.contagem.entrada : 0,
      query: 1,
      id: 1,
      access: ['admin', 'triagem', 'ouvidor'],
    },
    {
      // Recebidas para departamento é pendente
      name: 'Recebidas',
      bgcolor: '#255ca8',
      page: '/ocorrencias/recebidas',
      notifications: countData.contagem ? countData.contagem.entrada : 0,
      query: 1,
      id: 10,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
    },
    {
      name: 'Pendentes',
      bgcolor: '#b57628',
      page: '/ocorrencias/pendentes',
      notifications: countData.contagem ? countData.contagem.pendente : 0,
      query: 1,
      id: 2,
      access: ['admin', 'triagem', 'ouvidor'],
    },
    {
      name: ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)
        ? 'Em análise'
        : 'Em andamento',
      bgcolor: '#368f6e',
      page: '/ocorrencias/andamento',
      notifications: countData.contagem ? countData.contagem.andamento : 0,
      query: 2,
      id: 3,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
    },
    {
      name: 'A vencer',
      bgcolor: '#ad3b15',
      page: '/ocorrencias/vencer',
      notifications: countData.contagem ? countData.contagem.aVencer : 0,
      query: 3,
      id: 4,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
    },
    {
      name: 'Vencidas',
      bgcolor: '#9c677b',
      page: '/ocorrencias/vencidas',
      notifications: countData.contagem ? countData.contagem.vencidas : 0,
      query: 3,
      id: 5,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
    },
    {
      name: 'Respondidas',
      bgcolor: '#31bd52',
      page: '/ocorrencias/respondidas',
      notifications: countData.contagem ? countData.contagem.solucionada : 0,
      query: 3,
      id: 6,
      access: ['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
    },
    {
      name: 'Finalizadas',
      bgcolor: '#35728c',
      page: '/ocorrencias/finalizadas',
      notifications: countData.contagem ? countData.contagem.finalizada : 0,
      query: 2,
      id: 7,
      access: ['admin', 'triagem', 'ouvidor'],
    },
    {
      name: 'Recorrentes',
      bgcolor: '#ad5391',
      page: '/ocorrencias/recorrentes',
      notifications: 0,
      query: 3,
      id: 8,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
    },
  ];

  const getNavItems = () => {
    if (matchesLg) {
      return ocurrencesTabs.filter(item => item.query <= 3 && item.access.includes(userAccess));
    } else if (matchesMd) {
      return ocurrencesTabs.filter(item => item.query <= 2 && item.access.includes(userAccess));
    } else {
      return ocurrencesTabs.filter(item => item.query <= 1 && item.access.includes(userAccess));
    }
  };

  const getMenus = () => {
    if (matchesLg) {
      return ocurrencesTabs.filter(item => item.query > 3 && item.access.includes(userAccess));
    } else if (matchesMd) {
      return ocurrencesTabs.filter(item => item.query > 2 && item.access.includes(userAccess));
    } else {
      return ocurrencesTabs.filter(item => item.query > 1 && item.access.includes(userAccess));
    }
  };

  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      component="nav"
      mb={5}
      className={classes.navRoot}
      boxShadow={1}>
      {getNavItems().map(navItem => (
        <OcurrenceNavItem
          key={navItem.id}
          bgcolor={navItem.bgcolor}
          selected={navItem.id === selected ? true : false}
          page={navItem.page}
          name={navItem.name}
          notifications={navItem.notifications}
        />
      ))}
      <OcurrenceNavMenu menus={getMenus()} selected={selected} setSelected={setSelected} />
    </Box>
  );
};

export default OcurrenceNavbar;
