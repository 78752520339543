import React from 'react';

import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import makeStyles from '@material-ui/core/styles/makeStyles';

import CmtVertical from '../../../../../@coremat/CmtNavigation/Vertical';
import { getSidebarNavs } from '../menus';

const useStyles = makeStyles(() => ({
  perfectScrollbarSidebar: {
    height: '100%',
    transition: 'all 0.3s ease',
    '.Cmt-sidebar-fixed &, .Cmt-Drawer-container &': {
      height: '100%',
    },
    '.Cmt-modernLayout &': {
      height: 'calc(100% - 72px)',
    },
    '.Cmt-miniLayout &': {
      height: 'calc(100% - 91px)',
    },
    '.Cmt-miniLayout .Cmt-sidebar-content:hover &': {
      height: '100%',
    },
  },
}));

const SideBar = () => {
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const userOffice = useSelector(({ department_ombudsman }) => department_ombudsman.office);
  const classes = useStyles();

  const isDepartment = ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess);

  return (
    <PerfectScrollbar className={classes.perfectScrollbarSidebar}>
      {userAccess ? <CmtVertical menuItems={getSidebarNavs(userAccess, userOffice, isDepartment)} /> : <React.Fragment />}
    </PerfectScrollbar>
  );
};

export default SideBar;
