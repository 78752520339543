import React, { useState } from 'react';
import { Box, Button, TextField, Paper, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import { TableContainer, Table, TableHead, TableRow, TableCell, TableBody } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { makeStyles } from '@material-ui/core/styles';

import FeedbackAlert from '../OcurrenceDetails/ActionsModals/FeedbackAlert';

const useStyles = makeStyles(theme => ({
  addButton: {
    color: 'white',
    backgroundColor: '#0ddb48',
    '&:hover': {
      backgroundColor: '#26ed5e',
    },
  },
  cellAction: {
    width: '10%',
  },
}));

const OtherRequesters = ({ createOcurrenceForm, handleFormChange }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [cpf, setCpf] = useState('');

  const headers = ['Nome', 'CPF', 'E-mail', 'Ações'];

  const addRequesters = () => {
    if (name) {
      const id = createOcurrenceForm.coletiveList.length + 1;

      handleFormChange(null, {
        coletiveList: [...createOcurrenceForm.coletiveList, { name, cpf, email, id }],
      });

      setName('');
      setEmail('');
      setCpf('');
    } else {
      setAlertOpen(true);
    }
  };

  const deleteRequesters = id => {
    handleFormChange(null, {
      coletiveList: createOcurrenceForm.coletiveList.filter(item => item.id !== id),
    });
  };

  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column">
      <Box display="flex" mb={8}>
        <Box width="25%">
          <TextField
            inputProps={{ tabIndex: 100 }}
            fullWidth
            size="small"
            id="requesters-name-input"
            label="Nome"
            variant="outlined"
            required
            value={name}
            onChange={e => setName(e.target.value)}
          />
        </Box>
        <Box width="25%" pl={4}>
          <TextField
            inputProps={{ tabIndex: 101 }}
            type="email"
            fullWidth
            size="small"
            id="requesters-name-input"
            label="E-mail"
            variant="outlined"
            value={email}
            onChange={e => setEmail(e.target.value)}
          />
        </Box>
        <Box width="25%" pl={4}>
          <InputMask mask="999.999.999-99" value={cpf} onChange={e => setCpf(e.target.value)}>
            <TextField
              inputProps={{ tabIndex: 102 }}
              fullWidth
              size="small"
              id="coletive-add-cpf"
              label="CPF"
              variant="outlined"
            />
          </InputMask>
        </Box>
        <Box width="25%" pl={4}>
          <Button variant="contained" classes={{ root: classes.addButton }} startIcon={<AddIcon />} onClick={addRequesters}>
            Adicionar Solicitante
          </Button>
        </Box>
      </Box>
      <Box mb={8}>
        <TableContainer component={Paper}>
          <Table aria-label="histórico da ocorrência">
            <TableHead>
              <TableRow>
                {headers.map((item, index) => {
                  return (
                    <TableCell key={index} classes={index === 3 ? { root: classes.cellAction } : {}}>
                      <Box fontWeight={700}>{item}</Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {createOcurrenceForm.coletiveList.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.cpf}</TableCell>
                    <TableCell>{item.email}</TableCell>
                    <TableCell>
                      <IconButton aria-label="excluir solicitante" onClick={() => deleteRequesters(item.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <FeedbackAlert message="Por favor, preencha ao menos o nome." open={alertOpen} setOpen={setAlertOpen} />
    </Box>
  );
};

export default OtherRequesters;
