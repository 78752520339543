import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesMap } from '../../../redux/actions/f5-actions/ocurrences-map';
import { GoogleMap, HeatmapLayer, Marker } from '@react-google-maps/api';
import { Box, Paper } from '@material-ui/core';
import ExploreIcon from '@material-ui/icons/Explore';
import { parseISO, isBefore, isAfter, isEqual, addDays } from 'date-fns';

const containerStyle = {
  width: '100%',
  height: '400px',
};

const center = {
  lat: -23.492056182131666,
  lng: -47.45532788523062,
};

const isPending = value => {
  return ['pendente', 'ouv-pendente'].includes(value);
};

const isOngoing = value => {
  return ['andamento', 'ouv-andamento'].includes(value);
};

const GeneralMap = () => {
  const [google, setGoogle] = useState(null);
  const mapData = useSelector(({ ocurrences_map }) => ocurrences_map.data);
  const mapMode = useSelector(({ ocurrences_map }) => ocurrences_map.mode);
  const filtersData = useSelector(({ ocurrences_map }) => ocurrences_map.filters);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesMap());
  }, [dispatch]);

  const filteredMapData = () => {
    let filtered = [...mapData];

    if (filtersData.department !== 0) {
      filtered = filtered.filter(item => item.departamento_id === filtersData.department);
    }

    filtered = filtered.reduce((acc, item) => {
      if (filtersData.sectors[item.setor_id]) {
        acc.push(item);
      } else if (item.setor_id === null) {
        acc.push(item);
      }
      return acc;
    }, []);

    return filtered;
  };

  const getLocationData = style => {
    if (style === 'heat') {
      return filteredMapData().map(item => {
        return new google.maps.LatLng(item.lat, item.lng);
      });
    } else if (style === 'marker') {
      return filteredMapData().map(item => {
        let imgPath = '/images/f5-assets/156_verde.png';
        const deadline = parseISO(item.prazo);
        if (isPending(item.passo_interno)) {
          imgPath = '/images/f5-assets/156_azul.png';
        } else if (isOngoing(item.passo_interno)) {
          if (
            deadline &&
            (isBefore(deadline, addDays(new Date(), 3)) || isEqual(deadline, addDays(new Date(), 3))) &&
            isAfter(deadline, new Date())
          ) {
            imgPath = '/images/f5-assets/156_amarelo.png';
          } else if (deadline && (isBefore(deadline, new Date()) || isEqual(deadline, new Date()))) {
            imgPath = '/images/f5-assets/156_vermelho.png';
          } else {
            imgPath = '/images/f5-assets/156_azul.png';
          }
        }

        return { img: imgPath, pos: new google.maps.LatLng(item.lat, item.lng) };
      });
    }
  };

  const renderData = () => {
    if (mapMode === 'marker') {
      return getLocationData(mapMode).map((item, index) => <Marker icon={item.img} key={index} position={item.pos} />);
    } else if (mapMode === 'heat') {
      return <HeatmapLayer data={getLocationData(mapMode)} />;
    }
  };

  return (
    <Paper>
      <Box p={2} fontWeight={700} display="flex" alignItems="center" height={50}>
        <ExploreIcon />
        <Box ml={2}>Representação Geográfica de Ocorrências por Região</Box>
      </Box>
      <GoogleMap
        mapContainerStyle={containerStyle}
        center={center}
        zoom={10}
        onLoad={() => {
          setGoogle(window.google);
        }}>
        {google && renderData()}
      </GoogleMap>
    </Paper>
  );
};

export default GeneralMap;
