import React, { useState } from 'react';
import { Paper, Box } from '@material-ui/core';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import ContactsIcon from '@material-ui/icons/Contacts';
import LockOpenIcon from '@material-ui/icons/LockOpen';

import AccountTabPanel from './AccountTabPanel';

import Profile from './Profile';
import ChangeInfo from './ChangeInfo';
import ChangePassword from './ChangePassword';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const AccountTabs = ({ data, updateUser }) => {
  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Paper className={classes.root}>
      <Tabs orientation="vertical" variant="scrollable" value={value} onChange={handleChange} className={classes.tabs}>
        <Tab
          label={
            <Box display="flex" alignItems="center">
              <AssignmentIndIcon />
              <Box ml={2} fontSize={12} fontWeight={500}>
                Perfil
              </Box>
            </Box>
          }
        />
        <Tab
          label={
            <Box display="flex" alignItems="center">
              <ContactsIcon />
              <Box ml={2} fontSize={12} fontWeight={500}>
                Minhas Informações
              </Box>
            </Box>
          }
        />
        <Tab
          label={
            <Box display="flex" alignItems="center">
              <LockOpenIcon />
              <Box ml={2} fontSize={12} fontWeight={500}>
                Redefinir Senha
              </Box>
            </Box>
          }
        />
      </Tabs>
      <AccountTabPanel value={value} index={0}>
        <Profile data={data} />
      </AccountTabPanel>
      <AccountTabPanel value={value} index={1}>
        <ChangeInfo data={data} updateUser={updateUser} />
      </AccountTabPanel>
      <AccountTabPanel value={value} index={2}>
        <ChangePassword updateUser={updateUser} />
      </AccountTabPanel>
    </Paper>
  );
};

export default AccountTabs;
