import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import {
  Box,
  TextField,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';
import { format, parseISO } from 'date-fns';
import RestoreIcon from '@material-ui/icons/Restore';

import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import FeedbackAlert from './FeedbackAlert';
import sweetAlert from 'f5/utils/sweetAlert';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
  breakText: {
    wordBreak: 'break-all',
    maxWidth: '75%',
  },
}));

const VerifyExtendDeadline = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [alertOpen, setAlertOpen] = useState(false);
  const [note, setNote] = useState(
    ocurrence.prorrogacao && ocurrence.prorrogacao.justificativa ? ocurrence.prorrogacao.justificativa : '',
  );
  const [verification, setVerification] = useState(
    ocurrence.prorrogacao ? (ocurrence.prorrogacao.aceito ? 'yes' : ocurrence.prorrogacao.rejeitado ? 'no' : null) : null,
  );

  const dispatch = useDispatch();
  const classes = useStyles();

  // TODO: Melhorar validações de formulário
  const validateForm = () => {
    if (!note || !verification) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading({ status: true, message: 'Verificando prorrogação...' });

      const postData = {
        extensao: ocurrence.prorrogacao.id,
        justificativa: note,
        accept: verification === 'yes' ? true : false,
      };

      await ocurrencesActionService.sendVerifyExtendDeadline(ocurrence.ocorrencia_id, postData);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      sweetAlert('success', 'Sucesso', 'Prorrogação verificada com sucesso!', () => {
        setLoading({ status: false, message: '' });
      });
    } else {
      setAlertOpen(true);
    }
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  if (!ocurrence.prorrogacao) {
    return (
      <div className={classes.root}>
        <Box textAlign="center" fontWeight={700} fontSize={12}>
          Não há dados de prorrogação.
        </Box>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<RestoreIcon />} title="Verificar Prorrogação" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={ocurrence.abertura}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box display="flex">
        <Box display="flex" flexDirection="column">
          <Box display="flex" alignItems="center" mb={1}>
            <Box fontWeight={700} fontSize={12}>
              Solicitante da prorrogação:
            </Box>
            <Box ml={2} fontWeight={500}>
              {ocurrence.prorrogacao.funcionario.nome}
            </Box>
          </Box>
          <Box display="flex">
            <Box fontWeight={700} fontSize={12}>
              Motivo da prorrogação:
            </Box>
            <Box ml={2} fontWeight={500} fontSize={12} className={classes.breakText}>
              {ocurrence.prorrogacao.motivo}
            </Box>
          </Box>
        </Box>
        <Box flex={1} />
        <Box display="flex" flexDirection="column" minWidth="15%">
          <Box display="flex" alignItems="center">
            <Box fontWeight={700} fontSize={12} mb={1}>
              Data antiga:
            </Box>
            <Box ml={2} fontWeight={500} fontSize={12}>
              {format(parseISO(ocurrence.prorrogacao.data_antiga), 'dd/MM/yyyy')}
            </Box>
          </Box>
          <Box display="flex" alignItems="center">
            <Box fontWeight={700} fontSize={12}>
              Data nova:
            </Box>
            <Box ml={2} fontWeight={500} fontSize={12}>
              {format(parseISO(ocurrence.prorrogacao.data_nova), 'dd/MM/yyyy')}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box my={4}>
        <Divider />
      </Box>
      <Box width="100%" mb={4}>
        <FormControl component="fieldset" required>
          <FormLabel component="legend">Aprovar prorrogação?</FormLabel>
          <RadioGroup
            row
            name="verify-appeal-input"
            value={verification}
            onChange={e => {
              setVerification(e.target.value);
            }}>
            <FormControlLabel value="yes" control={<Radio />} label="Aprovado" />
            <FormControlLabel value="no" control={<Radio />} label="Não Aprovado" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box width={'100%'}>
        <TextField
          label="Justificativa"
          required
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          salvar
        </Button>
      </Box>
      <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
  );
};

export default VerifyExtendDeadline;
