import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, TextField, Button, CircularProgress, Divider } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';

import FeedbackAlert from './FeedbackAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import sweetAlert from 'f5/utils/sweetAlert';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

// TODO: Checar com o backend se existe um recurso aberto.
const VerifyAppeal = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [alertOpen, setAlertOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');
  const [approved, setApproved] = useState('');
  const [appealStatus, setAppealStatus] = useState({
    can: false,
    message: '',
    info: '',
  });

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const checkAppeal = async () => {
      setLoading({ status: true, message: 'Checando informações internas...' });
      const res = await ocurrencesActionService.canCheckAppeal(ocurrence.ocorrencia_id);

      setAppealStatus({
        can: res.success,
        message: res.message ? res.message : '',
        info: res.info ? res.info : '',
      });
      setLoading({ status: false, message: '' });
    };

    checkAppeal();
  }, [ocurrence.ocorrencia_id]);

  // TODO: Melhorar validações de formulário
  const validateForm = () => {
    if (!reason || !approved) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading({ status: true, message: 'Verificando recurso...' });

      const postData = {
        observacao: note,
        razao: reason,
        accept: approved,
        ocorrencia_id: ocurrence.ocorrencia_id,
      };

      await ocurrencesActionService.sendAppealVerification(postData);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      sweetAlert('success', 'Sucesso', 'Recurso verificado com sucesso!', () => {
        setLoading({ status: false, message: '' });
      });
    } else {
      setAlertOpen(true);
    }
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  if (!appealStatus.can) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height={300} width="100%">
        <Box fontSize={20} fontWeight={500}>
          {appealStatus.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<AssignmentTurnedInIcon />} title="Verificar Recurso" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={ocurrence.abertura}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%" mb={4} display="flex" flexDirection="column">
        <Box fontSize={12} fontWeight={700} mb={2}>
          Motivo do recurso:
        </Box>
        <Box px={2}>{appealStatus.info}</Box>
      </Box>
      <Box my={4}>
        <Divider />
      </Box>
      <Box width="100%" mb={4}>
        <FormControl component="fieldset" required>
          <FormLabel component="legend">Aprovação</FormLabel>
          <RadioGroup
            row
            name="verify-appeal-input"
            value={approved}
            onChange={e => {
              setApproved(e.target.value);
            }}>
            <FormControlLabel value="yes" control={<Radio />} label="Aprovado" />
            <FormControlLabel value="no" control={<Radio />} label="Não Aprovado" />
          </RadioGroup>
        </FormControl>
      </Box>
      <Box width="100%" mb={4}>
        <TextField
          label="Razão da escolha"
          required
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <TextField
          label="Observação"
          fullWidth
          variant="outlined"
          size="small"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          enviar
        </Button>
      </Box>
      <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
  );
};

export default VerifyAppeal;
