import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReceiptIcon from '@material-ui/icons/Receipt';
import { PDFViewer } from '@react-pdf/renderer';

import SharedTitle from './SharedTitle';
import PrintOrderPDF from './PrintOrderPDF';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
}));

const PrintOrder = ({ ocurrence }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <SharedTitle icon={<ReceiptIcon />} title="Ordem de Serviço" />

      <Box width="100%" height="75vh">
        <PDFViewer width="100%" height="100%">
          <PrintOrderPDF ocurrence={ocurrence} />
        </PDFViewer>
      </Box>
    </div>
  );
};

export default PrintOrder;
