import ocurrencesService from '../../../services/f5-services/ocurrencesService';
import {
  INIT_OCURRENCES_ALL,
  INIT_OCURRENCES_RECEIVED,
  INIT_OCURRENCES_NEW,
  INIT_OCURRENCES_RETURNED,
  INIT_OCURRENCES_PENDING,
  INIT_OCURRENCES_ONGOING,
  INIT_OCURRENCES_ONTIME,
  INIT_OCURRENCES_DUE,
  INIT_OCURRENCES_OVERDUE,
  INIT_OCURRENCES_COMPLETED,
  INIT_OCURRENCES_SOLVED,
  INIT_OCURRENCES_APPEALED,
  INIT_OCURRENCES_EXTEND,
  INIT_OCURRENCES_EXTEND_ACCEPTED,
  INIT_OCURRENCES_EXTEND_REJECTED,
} from './action-types';

export const initializeOcurrences = () => {
  return async dispatch => {
    const [
      receivedOcurrences,
      newOcurrences,
      returnedOcurrences,
      pendingOcurrences,
      ongoingOcurrences,
      ontimeOcurrences,
      dueOcurrences,
      overdueOcurrences,
      completedOcurrences,
      solvedOcurrences,
      appealedOcurrences,
      extendOcurrences,
      extendAcceptedOcurrences,
      extendRejectedOcurrences,
    ] = await Promise.all([
      ocurrencesService.getReceivedOcurrences(),
      ocurrencesService.getNewOcurrences(),
      ocurrencesService.getReturnedOcurrences(),
      ocurrencesService.getPendingOcurrences(),
      ocurrencesService.getOngoingOcurrences(),
      ocurrencesService.getOntimeOcurrences(),
      ocurrencesService.getDueOcurrences(),
      ocurrencesService.getOverdueOcurrences(),
      ocurrencesService.getCompletedOcurrences(),
      ocurrencesService.getSolvedOcurrences(),
      ocurrencesService.getAppealedOcurrences(),
      ocurrencesService.getExtendOcurrences(),
      ocurrencesService.getExtendAcceptedOcurrences(),
      ocurrencesService.getExtendRejectedOcurrences(),
    ]);
    dispatch({
      type: INIT_OCURRENCES_ALL,
      received: receivedOcurrences,
      new: newOcurrences,
      returned: returnedOcurrences,
      pending: pendingOcurrences,
      ongoing: ongoingOcurrences,
      ontime: ontimeOcurrences,
      due: dueOcurrences,
      overdue: overdueOcurrences,
      completed: completedOcurrences,
      solved: solvedOcurrences,
      appealed: appealedOcurrences,
      extend: extendOcurrences,
      extend_accepted: extendAcceptedOcurrences,
      extend_rejected: extendRejectedOcurrences,
    });
  };
};

export const initializeOcurrencesReceived = () => {
  return async dispatch => {
    const receivedOcurrences = await ocurrencesService.getReceivedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_RECEIVED,
      data: receivedOcurrences,
    });
  };
};

export const initializeOcurrencesNew = () => {
  return async dispatch => {
    const newOcurrences = await ocurrencesService.getNewOcurrences();
    dispatch({
      type: INIT_OCURRENCES_NEW,
      data: newOcurrences,
    });
  };
};

export const initializeOcurrencesReturned = () => {
  return async dispatch => {
    const returnedOcurrences = await ocurrencesService.getReturnedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_RETURNED,
      data: returnedOcurrences,
    });
  };
};

export const initializeOcurrencesPending = () => {
  return async dispatch => {
    const pendingOcurrences = await ocurrencesService.getPendingOcurrences();
    dispatch({
      type: INIT_OCURRENCES_PENDING,
      data: pendingOcurrences,
    });
  };
};

export const initializeOcurrencesOngoing = () => {
  return async dispatch => {
    const ongoingOcurrences = await ocurrencesService.getOngoingOcurrences();
    dispatch({
      type: INIT_OCURRENCES_ONGOING,
      data: ongoingOcurrences,
    });
  };
};

export const initializeOcurrencesOntime = () => {
  return async dispatch => {
    const ontimeOcurrences = await ocurrencesService.getOntimeOcurrences();
    dispatch({
      type: INIT_OCURRENCES_ONTIME,
      data: ontimeOcurrences,
    });
  };
};

export const initializeOcurrencesDue = () => {
  return async dispatch => {
    const dueOcurrences = await ocurrencesService.getDueOcurrences();
    dispatch({
      type: INIT_OCURRENCES_DUE,
      data: dueOcurrences,
    });
  };
};

export const initializeOcurrencesOverdue = () => {
  return async dispatch => {
    const overdueOcurrences = await ocurrencesService.getOverdueOcurrences();
    dispatch({
      type: INIT_OCURRENCES_OVERDUE,
      data: overdueOcurrences,
    });
  };
};

export const initializeOcurrencesCompleted = () => {
  return async dispatch => {
    const completedOcurrences = await ocurrencesService.getCompletedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_COMPLETED,
      data: completedOcurrences,
    });
  };
};

export const initializeOcurrencesSolved = () => {
  return async dispatch => {
    const solvedOcurrences = await ocurrencesService.getSolvedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_SOLVED,
      data: solvedOcurrences,
    });
  };
};

export const initializeOcurrencesAppealed = () => {
  return async dispatch => {
    const appealedOcurrences = await ocurrencesService.getAppealedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_APPEALED,
      data: appealedOcurrences,
    });
  };
};

export const initializeOcurrencesExtend = () => {
  return async dispatch => {
    const extendOcurrences = await ocurrencesService.getExtendOcurrences();
    dispatch({
      type: INIT_OCURRENCES_EXTEND,
      data: extendOcurrences,
    });
  };
};

export const initializeOcurrencesExtendAccepted = () => {
  return async dispatch => {
    const extendAcceptedOcurrences = await ocurrencesService.getExtendAcceptedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_EXTEND_ACCEPTED,
      data: extendAcceptedOcurrences,
    });
  };
};

export const initializeOcurrencesExtendRejected = () => {
  return async dispatch => {
    const extendRejectedOcurrences = await ocurrencesService.getExtendRejectedOcurrences();
    dispatch({
      type: INIT_OCURRENCES_EXTEND_REJECTED,
      data: extendRejectedOcurrences,
    });
  };
};
