import React from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  overdueStamp: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 0, 0, 0.75)',
    top: '-2px',
    right: '-2px',
    zIndex: 10,
    fontSize: '9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      color: 'white',
    },
  },
  dueStamp: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'rgba(255, 155, 0, 0.75)',
    top: '-2px',
    right: '-2px',
    zIndex: 10,
    fontSize: '9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      color: 'white',
    },
  },
  todueStamp: {
    position: 'absolute',
    width: '15px',
    height: '15px',
    borderRadius: '50%',
    backgroundColor: 'rgba(0, 0, 255, 0.75)',
    top: '-2px',
    right: '-2px',
    zIndex: 10,
    fontSize: '9px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& span': {
      color: 'white',
    },
  },
}));

const GeneralCalendarStamp = ({ count, stampStyle }) => {
  const classes = useStyles();

  const parsedCount = parseInt(count);
  const renderCount = parsedCount === 0 ? '' : parsedCount > 9 ? '9+' : count;

  if (parsedCount === 0) {
    return <React.Fragment />;
  }

  if (stampStyle === 'due') {
    return (
      <Box className={classes.dueStamp}>
        <span>{renderCount}</span>
      </Box>
    );
  }

  if (stampStyle === 'overdue') {
    return (
      <Box className={classes.overdueStamp}>
        <span>{renderCount}</span>
      </Box>
    );
  }

  return (
    <Box className={classes.todueStamp}>
      <span>{renderCount}</span>
    </Box>
  );
};

export default GeneralCalendarStamp;
