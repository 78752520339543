import React, { useEffect, useContext, useState } from 'react';
import { initializeOcurrencesCounter } from 'redux/actions/f5-actions/ocurrences-counter';
import generalService from '../../../../../services/f5-services/generalService';
import { useHistory, useLocation } from 'react-router-dom';
import LayoutContext from '@coremat/CmtLayouts/LayoutContext';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../../services/auth';
import { CurrentAuthMethod } from '../../../../constants/AppConstants';
import SidebarToggleHandler from '../../../../../@coremat/CmtLayouts/Vertical/SidebarToggleHandler';
import Toolbar from '@material-ui/core/Toolbar';
import { Box, Divider, Button, IconButton } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
// import LanguageSwitcher from "../LanguageSwitcher";
import makeStyles from '@material-ui/core/styles/makeStyles';
// import AppsMenu from './AppsMenu';
import Hidden from '@material-ui/core/Hidden';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

import F5HeaderButton from './F5HeaderButton';
import F5HeaderIconButton from './F5HeaderIconButton';

import ForumIcon from '@material-ui/icons/Forum';
import SyncProblemIcon from '@material-ui/icons/SyncProblem';
import ScheduleIcon from '@material-ui/icons/Schedule';

import LaunchIcon from '@material-ui/icons/Launch';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#3f51b5',
    width: '100%',
    paddingLeft: 16,
    paddingRight: 16,
    minHeight: 64,
    [theme.breakpoints.up('md')]: {
      minHeight: 72,
    },
    [theme.breakpoints.up('md')]: {
      paddingLeft: 24,
      paddingRight: 24,
    },
    '& hr': {
      margin: '0 1rem',
    },
  },
  searchRoot: {
    position: 'relative',
    width: 200,
    [theme.breakpoints.up('md')]: {
      width: 300,
    },
    '& .MuiSvgIcon-root': {
      position: 'absolute',
      left: 18,
      top: '50%',
      transform: 'translateY(-50%)',
      zIndex: 1,
    },
    '& .MuiInputBase-root': {
      width: '100%',
    },
    '& .MuiInputBase-input': {
      height: 48,
      borderRadius: 30,
      backgroundColor: alpha(theme.palette.common.dark, 0.38),
      color: alpha(theme.palette.common.white, 0.7),
      boxSizing: 'border-box',
      padding: '5px 15px 5px 50px',
      transition: 'all 0.3s ease',
      '&:focus': {
        backgroundColor: alpha(theme.palette.common.dark, 0.58),
        color: alpha(theme.palette.common.white, 0.7),
      },
    },
  },
  iconBtn: {
    color: alpha(theme.palette.common.white, 0.38),
    '&:hover, &:focus': {
      color: theme.palette.common.white,
    },
  },
  usernameTitle: {
    color: 'yellow',
    fontWeight: 700,
  },
  tag: {
    backgroundColor: 'yellow',
    borderRadius: '0.25rem',
    padding: '0.25rem 1rem',
    marginRight: '0.25rem',
  },
  tagText: {
    fontSize: '14px',
    color: 'black',
    fontWeight: 700,
  },
}));

const Header = () => {
  const userName = useSelector(({ auth }) => (auth.authUser ? auth.authUser.nome : null));
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const countData = useSelector(({ ocurrences_counter }) => ocurrences_counter);
  const [userDepartment, setUserDepartment] = useState('');

  const location = useLocation();
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();

  const matches = useMediaQuery(theme => theme.breakpoints.up('md'));

  const { setSidebarOpen } = useContext(LayoutContext);

  useEffect(() => {
    dispatch(initializeOcurrencesCounter());
  }, [dispatch]);

  useEffect(() => {
    const getDepartmentData = async () => {
      const departmentMeta = await generalService.getDepartmentMeta();

      setUserDepartment(departmentMeta.departamento ? departmentMeta.departamento.nome : '');
    };

    getDepartmentData();
  }, [setUserDepartment]);

  useEffect(() => {
    if (matches) {
      setSidebarOpen(false);
    }
  }, [matches, setSidebarOpen]);

  const onSubmit = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const checkCommunication = () => {
    history.push(`/comunique`);
  };

  const checkExtend = () => {
    history.push(`/ocorrencias/prorrogacoes`);
  };

  const checkAppeal = () => {
    history.push(`/ocorrencias/recurso`);
  };

  const checkAccount = () => {
    history.push(`/conta`);
  };

  const showExtend = () => {
    if (userAccess === 'ouv_master') {
      return (
        <React.Fragment>
          <Hidden smDown>
            <F5HeaderButton
              title="Prorrogações"
              icon={<ScheduleIcon />}
              handler={checkExtend}
              buttonProps={{ variant: 'contained' }}
              count={countData.contagem ? countData.contagem.prorrogacao : 0}
              color="white"
              bgColor="#0cada3"
              bgColorHover="#26bfb6"
            />
          </Hidden>
          <Hidden mdUp>
            <F5HeaderIconButton
              icon={<ScheduleIcon fontSize="small" />}
              buttonProps={{ color: 'inherit' }}
              handler={checkExtend}
              count={countData.contagem ? countData.contagem.prorrogacao : 0}
            />
          </Hidden>
        </React.Fragment>
      );
    }
  };

  const showAppeal = () => {
    if (userAccess === 'ouvidor') {
      return (
        <React.Fragment>
          <Hidden smDown>
            <F5HeaderButton
              title="Recurso"
              icon={<SyncProblemIcon />}
              handler={checkAppeal}
              buttonProps={{ variant: 'contained' }}
              count={countData.contagem ? countData.contagem.recurso : 0}
              color="white"
              bgColor="#d9216d"
              bgColorHover="#e3367e"
            />
          </Hidden>
          <Hidden mdUp>
            <F5HeaderIconButton
              icon={<SyncProblemIcon fontSize="small" />}
              buttonProps={{ color: 'inherit' }}
              handler={checkAppeal}
              count={countData.contagem ? countData.contagem.recurso : 0}
            />
          </Hidden>
        </React.Fragment>
      );
    }
  };

  const showCommunication = () => {
    if (location.pathname !== '/setores' && userAccess !== 'ouv_cadastro' && userAccess !== 'ouv_master') {
      return (
        <React.Fragment>
          <Hidden smDown>
            <F5HeaderButton
              title="Comunique-se"
              icon={<ForumIcon />}
              handler={checkCommunication}
              buttonProps={{ variant: 'outlined', color: 'inherit' }}
              count={countData.contagem ? countData.contagem.comunique : 0}
            />
          </Hidden>
          <Hidden mdUp>
            <F5HeaderIconButton
              icon={<ForumIcon fontSize="small" />}
              buttonProps={{ color: 'inherit' }}
              handler={checkCommunication}
              count={countData.contagem ? countData.contagem.comunique : 0}
            />
          </Hidden>
        </React.Fragment>
      );
    }
  };

  return (
    <Toolbar className={classes.root}>
      <Hidden lgUp>
        <SidebarToggleHandler edge="start" color="inherit" aria-label="menu" />
      </Hidden>
      <Box className={classes.tag} display="flex" justifyContent="center" alignItems="center">
        <Box className={classes.tagText}>{userDepartment}</Box>
      </Box>
      <Box ml={2} fontWeight={500}>
        Bem-vindo, <span className={classes.usernameTitle}>{userName}</span>!
      </Box>
      <Box flex={1} />
      {showExtend()}
      {showAppeal()}
      {showCommunication()}
      {/* <AppsMenu /> */}
      {/* <HeaderMessages /> */}
      {/* <HeaderNotifications /> */}
      <IconButton color="inherit" aria-label="Sua conta" onClick={checkAccount}>
        <AccountCircleIcon />
      </IconButton>
      <Divider orientation="vertical" flexItem />
      <Button variant="contained" startIcon={<LaunchIcon />} size="small" color="secondary" onClick={onSubmit}>
        Sair
      </Button>
    </Toolbar>
  );
};

export default Header;
