import React from 'react';
import { Box, IconButton } from '@material-ui/core';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
  notificationStyle: {
    position: 'relative',
    '& .notifications': {
      zIndex: 2,
      display: ({ notifications }) => (notifications > 0 ? 'flex' : 'none'),
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',
      padding: '0.25rem',
      position: 'absolute',
      top: '-7px',
      right: '-7px',
      border: '1px solid rgba(150, 127, 14, 0.25)',
      borderRadius: '50%',
      backgroundColor: 'rgba(230, 230, 16, 1)',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },
    '& .notifications > span': {
      fontWeight: '700',
      fontSize: '11px',
      textAlign: 'center',
      color: 'black',
    },
  },
}));

const F5HeaderIconButton = ({ count, icon, handler, buttonProps }) => {
  const classes = useStyles({
    notifications: count ? count : 0,
  });

  return (
    <Box className={classes.notificationStyle}>
      <IconButton {...buttonProps} onClick={handler}>
        {icon}
      </IconButton>
      {count > 0 && (
        <div className="notifications">
          <span>{count > 9 ? '9+' : count}</span>
        </div>
      )}
    </Box>
  );
};

export default F5HeaderIconButton;
