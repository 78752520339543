import React from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  textBreak: {
    wordBreak: 'break-all',
  },
}));

const TextWrapper = ({ text }) => {
  const classes = useStyles();

  return (
    <Box fontSize={12} className={classes.textBreak}>
      {text}
    </Box>
  );
};

export default TextWrapper;
