import React from 'react';
import { Page, Document } from '@react-pdf/renderer';
import {
  RequestInformation,
  RequestLocation,
  RequestTriage,
  RequestDescription,
  RequestCommunication,
  RequestHeader,
} from './SharedPrint';

const PrintOrderPDF = ({ ocurrence, ...props }) => {
  return (
    <Document>
      <Page size="A4" style={{ padding: 5 }}>
        <RequestHeader ocurrence={ocurrence} />
        <RequestInformation ocurrence={ocurrence} />
        <RequestLocation ocurrence={ocurrence} />
        <RequestTriage ocurrence={ocurrence} />
        <RequestDescription ocurrence={ocurrence} />
        {ocurrence.comunicacoes.map((item, pos) => (
          <RequestCommunication communication={item} pos={pos} />
        ))}
      </Page>
    </Document>
  );
};

export default PrintOrderPDF;
