import React, { useState } from 'react';
import { Box, Paper, Divider, Button } from '@material-ui/core';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Dialog from '@material-ui/core/Dialog';
import GeneralPieChart from './GeneralPieChart';
import { useHistory } from 'react-router-dom';
import NotificationsIcon from '@material-ui/icons/Notifications';

import AllInboxIcon from '@material-ui/icons/AllInbox';
import HearingIcon from '@material-ui/icons/Hearing';
import AttachMoneyIcon from '@material-ui/icons/AttachMoney';
import FindInPageIcon from '@material-ui/icons/FindInPage';
import SettingsIcon from '@material-ui/icons/Settings';
import PanToolIcon from '@material-ui/icons/PanTool';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import SchoolIcon from '@material-ui/icons/School';
import BuildIcon from '@material-ui/icons/Build';
import LocalHospitalIcon from '@material-ui/icons/LocalHospital';
import HouseIcon from '@material-ui/icons/House';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import EmojiTransportationIcon from '@material-ui/icons/EmojiTransportation';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperRoot: {
    position: 'relative',
    borderRadius: '1rem',
    margin: theme.spacing(2),
    '& .new-ocurrences': {
      color: 'darkorange',
      position: 'absolute',
      fontSize: 12,
      fontWeight: 700,
      top: 2,
      right: 5,
    },
    '& .new-ocurrences-icon': {
      fontSize: 16,
    },
  },
  cardRoot: {
    '& > *': {
      margin: theme.spacing(1, 0),
    },
  },
  buttonRoot: {
    padding: theme.spacing(0, 2),
    color: 'white',
    backgroundColor: '#1b87b5',
    '&:hover': {
      backgroundColor: '#1b87b5',
      opacity: 0.75,
    },
  },
  negative: {
    color: 'red',
  },
  positive: {
    color: 'green',
  },
  greyed: {
    color: 'grey',
  },
}));

const GeneralSecretaryStats = ({ title, positiveValue, negativeValue, uniqueId, newTag }) => {
  const classes = useStyles();
  const history = useHistory();

  const positiveValueParsed = parseFloat(positiveValue).toFixed(2);
  const negativeValueParsed = parseFloat(negativeValue).toFixed(2);

  const icons = {
    'stats-triagem': <AllInboxIcon />,
    'stats-ouvidoria': <HearingIcon />,
    'stats-financas': <AttachMoneyIcon />,
    'stats-procgeral': <FindInPageIcon />,
    'stats-admnistracao': <SettingsIcon />,
    'stats-assistsocial': <PanToolIcon />,
    'stats-espculrec': <NaturePeopleIcon />,
    'stats-educacao': <SchoolIcon />,
    'stats-obras': <BuildIcon />,
    'stats-saude': <LocalHospitalIcon />,
    'stats-planejaurb': <HouseIcon />,
    'stats-trabindu': <HomeWorkIcon />,
    'stats-secpubtran': <EmojiTransportationIcon />,
  };

  const handleRoute = () => {
    history.push(`/ocorrencias/subdepartamento/${uniqueId}`);
  };

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      {newTag > 0 ? (
        <Box className="new-ocurrences" display="flex" alignItems="center">
          <NotificationsIcon className="new-ocurrences-icon" />
          <Box>{newTag > 99 ? '99+' : newTag}</Box>
        </Box>
      ) : null}
      <Box p={2} display="flex" flexDirection="column" alignItems="center" className={classes.cardRoot}>
        <Box fontWeight={700} fontSize={12} display="flex" alignItems="center">
          {icons[uniqueId]}
          <Box ml={2}>{title}</Box>
        </Box>
        <Box alignSelf="stretch">
          <Divider variant="middle" />
        </Box>
        <Box display="flex" alignItems="center" justifyContent="center" mt={2} mb={4}>
          <Box
            mx={2}
            display="flex"
            alignItems="center"
            className={negativeValueParsed > positiveValueParsed ? classes.negative : classes.greyed}>
            <ThumbDownIcon fontSize="small" />
            <Box ml={2} fontWeight={700}>
              {negativeValueParsed}%
            </Box>
          </Box>
          <Box
            mx={2}
            display="flex"
            alignItems="center"
            className={positiveValueParsed > negativeValueParsed ? classes.positive : classes.greyed}>
            <Box mr={2} fontWeight={700}>
              {positiveValueParsed}%
            </Box>
            <ThumbUpIcon fontSize="small" />
          </Box>
        </Box>
        <Button size="small" classes={{ root: classes.buttonRoot }} onClick={handleRoute}>
          Ver mais
        </Button>
        {/* <Dialog fullWidth maxWidth={'sm'} open={open} onClose={() => setOpen(false)}>
          <Box px={2} py={4} display="flex" alignItems="center">
            {icons[uniqueId]}
            <Box ml={2} fontWeight={700}>
              {title}
            </Box>
          </Box>
          <GeneralPieChart />
        </Dialog> */}
      </Box>
    </Paper>
  );
};

export default GeneralSecretaryStats;
