import { INIT_OCURRENCES_COUNTER } from '../../actions/f5-actions/action-types';

const INIT_STATE = {};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_OCURRENCES_COUNTER: {
      return { ...state, ...action.data };
    }
    default:
      return state;
  }
};
