import { INIT_FORM_DATA, SELECT_OCURRENCE } from '../../actions/f5-actions/action-types';

const INIT_STATE = { triage: [], departments: [], selectedOcurrence: {} };

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_FORM_DATA: {
      return { ...state, triage: action.triage, departments: action.departments };
    }
    case SELECT_OCURRENCE: {
      return { ...state, selectedOcurrence: action.payload };
    }
    default:
      return state;
  }
};
