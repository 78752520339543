import React from 'react';
import Box from '@material-ui/core/Box';

const AccountTabPanel = props => {
  const { children, value, index, ...other } = props;

  return (
    <div role="tabpanel" hidden={value !== index} id={`vertical-tabpanel-${index}`} {...other} style={{ width: '100%' }}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
};

export default AccountTabPanel;
