import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import {
  Box,
  CircularProgress,
  TextField,
  Button,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from '@material-ui/core';
import ForumIcon from '@material-ui/icons/Forum';
import sweetAlert from 'f5/utils/sweetAlert';

import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';
import FeedbackAlert from './FeedbackAlert';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
  inputRoot: {
    backgroundColor: 'white',
  },
}));

const Communication = ({ ocurrence }) => {
  const [alertOpen, setAlertOpen] = useState(false);
  const [loading, setLoading] = useState({ message: '', status: false });
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    setLoading({ message: 'Carregando dados...', status: true });
    const updateMessages = async () => {
      const res = await ocurrencesActionService.getOcurrenceCommunications(ocurrence.ocorrencia_id);
      setMessages(res.comunicacoes);
      setLoading({ message: '', status: false });
    };
    updateMessages();
  }, [ocurrence.ocorrencia_id, setMessages]);

  const headers = ['Mensagem', 'Data/Hora', 'Resposta'];

  // TODO: Melhorar validações de formulário
  const validateForm = () => {
    if (!message) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading({ status: true, message: 'Enviando mensagem...' });

      const postData = { message: message };

      await ocurrencesActionService.sendCommunication(postData, ocurrence.ocorrencia_id);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      sweetAlert('success', 'Sucesso', 'Mensagem enviada com sucesso!', () => {
        setLoading({ status: false, message: '' });
      });
    } else {
      setAlertOpen(true);
    }
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<ForumIcon />} title="Comunique-se" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={ocurrence.abertura}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box px={1} mb={4}>
        <TableContainer component={Paper} elevation={2} variant="outlined">
          <Table aria-label="histórico da ocorrência">
            {messages.length <= 0 ? (
              <caption>Não há mensagens atualmente</caption>
            ) : (
              <caption>Mensagens da comunicação</caption>
            )}
            <TableHead>
              <TableRow>
                {headers.map((item, index) => {
                  return (
                    <TableCell key={index}>
                      <Box fontWeight={700}>{item}</Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {messages.map(item => {
                return (
                  <TableRow key={item.id}>
                    <TableCell>{item.pedido}</TableCell>
                    <TableCell>{item.created_at}</TableCell>
                    <TableCell>{item.resposta}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      <Divider />
      <Box p={1} mt={4}>
        <TextField
          label="Mensagem"
          fullWidth
          variant="outlined"
          classes={{ root: classes.inputRoot }}
          size="small"
          required
          value={message}
          onChange={e => setMessage(e.target.value)}
        />
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          enviar
        </Button>
      </Box>
      <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
  );
};

export default Communication;
