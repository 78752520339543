import axios from '../../../services/auth/jwt/config';
import { SET_DEPARTMENT_OMBUDSMAN, CLEAN_DEPARTMENT_OMBUDSMAN } from '../../actions/f5-actions/action-types';

const INIT_STATE = { office: null };

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_DEPARTMENT_OMBUDSMAN: {
      axios.defaults.params = { sector: action.payload };
      return { ...state, office: action.payload };
    }
    case CLEAN_DEPARTMENT_OMBUDSMAN: {
      axios.defaults.params = {};
      return { ...state, office: null };
    }
    default:
      return state;
  }
};
