import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OngoingOcurrences from '../../../../f5/ocurrences/OngoingOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Ongoing = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <OngoingOcurrences />
    </PageContainer>
  );
};

export default Ongoing;
