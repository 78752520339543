import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeOcurrencesOngoing,
  initializeOcurrencesOntime,
} from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import NoteIcon from '@material-ui/icons/Note';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';

const OngoingOcurrences = () => {
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const [currentList, setCurrentList] = useState('ongoing');
  const ongoingData = useSelector(({ ocurrences_data }) => ocurrences_data.ongoing.caixa);
  const ontimeData = useSelector(({ ocurrences_data }) => ocurrences_data.ontime.caixa);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesOngoing());
    dispatch(initializeOcurrencesOntime());
  }, [dispatch]);

  const ocurrencesData = {
    ongoing: {
      icon: <NoteIcon fontSize="large" />,
      label: ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)
        ? 'Ocorrências em análise'
        : 'Ocorrências em Andamento',
      colors: ['#368f6e', '#055c3b'],
      data: ongoingData,
    },
    ontime: {
      icon: <ThumbUpIcon fontSize="large" />,
      label: 'Ocorrências dentro do Prazo',
      colors: ['#2ea7b3', '#067e8a'],
      data: ontimeData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.ongoing.label}
          icon={ocurrencesData.ongoing.icon}
          colors={ocurrencesData.ongoing.colors}
          value={ocurrencesData.ongoing.data ? ocurrencesData.ongoing.data.length : 0}
          setList={() => setCurrentList('ongoing')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.ontime.label}
          icon={ocurrencesData.ontime.icon}
          colors={ocurrencesData.ontime.colors}
          value={ocurrencesData.ontime.data ? ocurrencesData.ontime.data.length : 0}
          setList={() => setCurrentList('ontime')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default OngoingOcurrences;
