import ocurrencesActionService from '../../../services/f5-services/ocurrencesActionService';
import { INIT_FORM_DATA, SELECT_OCURRENCE } from './action-types';

export const initializeOcurrencesFormData = () => {
  return async dispatch => {
    const triageData = await ocurrencesActionService.getTriageData();
    const departmentsData = await ocurrencesActionService.getDepartmentData();
    dispatch({
      type: INIT_FORM_DATA,
      triage: triageData.items,
      departments: departmentsData.items,
    });
  };
};

export const selectOcurrence = id => {
  return async dispatch => {
    const selectedOcurrence = await ocurrencesActionService.getOcurrenceData(id);
    dispatch({
      type: SELECT_OCURRENCE,
      payload: selectedOcurrence.historico,
    });
  };
};
