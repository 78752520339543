import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Login from './Auth/Login';
import Register from './Auth/Register';
import ForgotPasswordPage from './Auth/ForgotPassword';

import Search from './Pages/Search';
import Ocurrences from './Pages/Ocurrences/General';
import Received from './Pages/Ocurrences/Received';
import Pending from './Pages/Ocurrences/Pending';
import Ongoing from './Pages/Ocurrences/Ongoing';
import Due from './Pages/Ocurrences/Due';
import Overdue from './Pages/Ocurrences/Overdue';
import Solved from './Pages/Ocurrences/Solved';
import Completed from './Pages/Ocurrences/Completed';
import ViewOcurrence from './Pages/Ocurrences/ViewOcurrence';
import CreateOcurrence from './Pages/Ocurrences/CreateOcurrence';
import Appealed from './Pages/Ocurrences/Appealed';
import Extend from './Pages/Ocurrences/Extend';
import Communication from './Pages/Communication';
import DepartmentOmbudsman from './Pages/DepartmentOmbudsman';
import Account from './Pages/Account';
import SubdepartmentOcurrences from './Pages/SubdepartmentOcurrences';
import Category from './Pages/Configuration/Category';
import Sector from './Pages/Configuration/Sector';
import Entry from './Pages/Configuration/Entry';
import Gravity from './Pages/Configuration/Gravity';
import Theme from './Pages/Configuration/Theme';
import Subject from './Pages/Configuration/Subject';
import Service from './Pages/Configuration/Service';

const RestrictedRoute = ({ accessLevel, isSector, component: Component, ...rest }) => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const userOffice = useSelector(({ department_ombudsman }) => department_ombudsman.office);

  if (location.pathname !== '/nova' && location.pathname !== '/conta' && userAccess === 'ouv_cadastro') {
    return <Redirect to={'/nova'} />;
  }

  if (!userOffice && !isSector && ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)) {
    return <Redirect to={'/setores'} />;
  }

  if (isSector && !['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(userAccess)) {
    return <Redirect to={'/ocorrencias'} />;
  }

  if (accessLevel && !accessLevel.includes(userAccess)) {
    return authUser ? <Redirect to={'/ocorrencias'} /> : <Redirect to={'/signin'} />;
  }

  return (
    <Route
      {...rest}
      render={props =>
        authUser ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/signin',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const Routes = () => {
  const { authUser } = useSelector(({ auth }) => auth);
  const location = useLocation();

  if (location.pathname === '' || location.pathname === '/') {
    return <Redirect to={'/ocorrencias'} />;
  } else if (authUser && location.pathname === '/signin') {
    return <Redirect to={'/ocorrencias'} />;
  }

  return (
    <React.Fragment>
      <Switch>
        <RestrictedRoute path="/conta" component={Account} />
        <RestrictedRoute
          accessLevel={[
            'admin',
            'triagem',
            'ouvidor',
            'gestao',
            'funcionario',
            'departamento',
            'sub_departamento',
            'ouv_cadastro',
          ]}
          path="/nova"
          component={CreateOcurrence}
        />
        <RestrictedRoute
          accessLevel={[
            'admin',
            'triagem',
            'ouvidor',
            'gestao',
            'funcionario',
            'departamento',
            'sub_departamento',
            'ouv_master',
          ]}
          path="/pesquisar"
          component={Search}
        />
        <RestrictedRoute
          accessLevel={[
            'admin',
            'triagem',
            'ouvidor',
            'gestao',
            'funcionario',
            'departamento',
            'sub_departamento',
            'ouv_master',
          ]}
          path="/ocorrencias/ver/:id"
          component={ViewOcurrence}
        />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/comunique"
          component={Communication}
        />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/ocorrencias/recebidas"
          component={Received}
        />
        <RestrictedRoute accessLevel={['admin', 'triagem', 'ouvidor']} path="/ocorrencias/pendentes" component={Pending} />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/ocorrencias/andamento"
          component={Ongoing}
        />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/ocorrencias/vencer"
          component={Due}
        />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/ocorrencias/vencidas"
          component={Overdue}
        />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/ocorrencias/respondidas"
          component={Solved}
        />
        <RestrictedRoute
          accessLevel={['admin', 'triagem', 'ouvidor']}
          path="/ocorrencias/finalizadas"
          component={Completed}
        />
        <RestrictedRoute
          accessLevel={['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento']}
          path="/ocorrencias/subdepartamento/:id"
          component={SubdepartmentOcurrences}
        />
        <RestrictedRoute accessLevel={['admin', 'ouvidor']} path="/ocorrencias/recurso" component={Appealed} />
        <RestrictedRoute accessLevel={['admin', 'ouv_master']} path="/ocorrencias/prorrogacoes" component={Extend} />
        <RestrictedRoute
          accessLevel={[
            'admin',
            'triagem',
            'ouvidor',
            'gestao',
            'funcionario',
            'departamento',
            'sub_departamento',
            'ouv_master',
          ]}
          path="/ocorrencias"
          component={Ocurrences}
        />
        <RestrictedRoute path="/setores" isSector={true} component={DepartmentOmbudsman} />

        <RestrictedRoute path="/configuracoes/categoria" accessLevel={['ouv_master']} component={Category} />
        <RestrictedRoute path="/configuracoes/setor" accessLevel={['ouv_master']} component={Sector} />
        <RestrictedRoute path="/configuracoes/entrada" accessLevel={['ouv_master']} component={Entry} />
        <RestrictedRoute path="/configuracoes/gravidade" accessLevel={['ouv_master']} component={Gravity} />
        <RestrictedRoute path="/configuracoes/tema" accessLevel={['ouv_master']} component={Theme} />
        <RestrictedRoute path="/configuracoes/assunto" accessLevel={['ouv_master']} component={Subject} />
        <RestrictedRoute path="/configuracoes/servico" accessLevel={['ouv_master']} component={Service} />

        <Route path="/signin" component={Login} />
        <Route path="/signup" component={Register} />
        <Route path="/forgot-password" component={ForgotPasswordPage} />
        <Redirect from="*" to="/ocorrencias" />
      </Switch>
    </React.Fragment>
  );
};

export default Routes;
