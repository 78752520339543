import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton, Box } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

const ViewOcurrenceAction = ({ id }) => {
  let history = useHistory();

  const changeRoute = () => {
    history.push(`/ocorrencias/ver/${id}`);
  };

  return (
    <Box display="flex" justifyContent="center">
      <IconButton aria-label="vizualizar" size="small" onClick={() => changeRoute()}>
        <VisibilityIcon />
      </IconButton>
    </Box>
  );
};

export default ViewOcurrenceAction;
