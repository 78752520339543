import React from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  customChip: {
    fontSize: '12px',
    color: 'white',
    backgroundColor: ({ color }) => (color ? color : 'rgba(0, 0, 0, 0.1)'),
    borderRadius: '10px',
    padding: '0.25rem 0.5rem',
  },
}));

const DataChip = ({ label, color }) => {
  const classes = useStyles({ color });

  return (
    <Box size="small" className={classes.customChip}>
      {label}
    </Box>
  );
};

export default DataChip;
