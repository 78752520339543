import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import Common from './Common';
import Auth from './Auth';

import ocurrences_piechart from './f5-reducers/ocurrences-piechart';
import ocurrences_counter from './f5-reducers/ocurrences-counter';
import ocurrences_daily from './f5-reducers/ocurrences-daily';
import ocurrences_map from './f5-reducers/ocurrences-map';
import ocurrences_actions from './f5-reducers/ocurrences-actions';
import ocurrences_create from './f5-reducers/ocurrences-create';
import department_ombudsman from './f5-reducers/department-ombudsman';

import ocurrences_data from './f5-reducers/ocurrences-data';

export default history =>
  combineReducers({
    router: connectRouter(history),
    common: Common,
    auth: Auth,
    ocurrences_data,
    ocurrences_actions,
    ocurrences_create,
    ocurrences_piechart,
    ocurrences_counter,
    ocurrences_daily,
    ocurrences_map,
    department_ombudsman,
  });
