import React, { useState } from 'react';
import { Paper, Box, Button } from '@material-ui/core';
import MoreInfo from './InformationDialogs/MoreInfo';
import HistoryInfo from './InformationDialogs/HistoryInfo';

import InfoIcon from '@material-ui/icons/Info';
import Divider from '@material-ui/core/Divider';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bottomRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  infoButton: {
    fontSize: 12,
    textTransform: 'none',
    color: 'dodgerblue',
  },
  historyButton: {
    fontSize: 12,
    backgroundColor: 'rgba(0, 139, 139, 0.75)',
    textTransform: 'none',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(0, 139, 139, 1)',
    },
  },
}));

const InformationCard = ({ requester, movements, openingDate, coletive }) => {
  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [openHistoryInfo, setOpenHistoryInfo] = useState(false);

  const classes = useStyles();

  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <InfoIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Informações
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box display="flex" flexDirection="column" my={2}>
          <Box display="flex" mb={1}>
            <Box fontWeight={700}>Solicitante:</Box>
            <Box display="flex" alignItems="center">
              <Box ml={2}>{requester.nome}</Box>
              {coletive ? (
                <Box ml={1} fontStyle="italic" fontWeight={500} fontSize={12}>
                  (e mais {coletive}...)
                </Box>
              ) : null}
            </Box>
          </Box>
          <Box display="flex">
            <Box fontWeight={700}>Aberta em:</Box>
            <Box ml={2}>{openingDate}</Box>
          </Box>
        </Box>
        <Box my={2} className={classes.bottomRoot}>
          <Button size="small" classes={{ root: classes.infoButton }} onClick={() => setOpenMoreInfo(true)}>
            Mais informações...
          </Button>
          <MoreInfo open={openMoreInfo} setOpen={setOpenMoreInfo} requester={requester} />

          <Button
            size="small"
            classes={{ root: classes.historyButton }}
            variant="contained"
            onClick={() => setOpenHistoryInfo(true)}>
            Histórico de Atividades
          </Button>
          <HistoryInfo open={openHistoryInfo} setOpen={setOpenHistoryInfo} movements={movements} />
        </Box>
      </Box>
    </Paper>
  );
};

export default InformationCard;
