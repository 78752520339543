import React from 'react';
import { Paper, Box, CircularProgress } from '@material-ui/core';

import OcurrencesDataTable from '../SharedComponents/OcurrencesDataTable';
import OcurrencesHeader from '../SharedComponents/OcurrencesHeader';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paperRoot: {
    overflow: 'hidden',
    backgroundColor: ({ bgcolor }) => (bgcolor ? bgcolor : 'rgba(0, 0, 0, 0)'),
    paddingTop: '0.5rem',
  },
}));

const OcurrencesDataRender = ({ bgcolor, data, label, icon }) => {
  const classes = useStyles({ bgcolor });

  if (!data) {
    return (
      <Paper>
        <Box p={2} minHeight={350} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <CircularProgress />
          <Box mt={2} fontWeight={700} textAlign="center">
            Carregando dados...
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper classes={{ root: classes.paperRoot }}>
      <Box className={classes.root} ml={2}>
        <OcurrencesHeader title={label} icon={icon} size={20} />
      </Box>
      <Box p={2}>
        <OcurrencesDataTable data={data} />
      </Box>
    </Paper>
  );
};

export default OcurrencesDataRender;
