import React, { useState, useEffect } from 'react';
import configurationService from 'services/f5-services/configurationService';
import { Box, TextField, Select, FormControl, InputLabel, MenuItem, CircularProgress } from '@material-ui/core';

const transformedTags = t => {
  if (t) {
    return t.join(', ');
  }

  return '';
};

const ServiceCustomForm = ({ newOrUpdate, setNewOrUpdate, subjects }) => {
  const [loading, setLoading] = useState(false);
  const [newOrUpdateInitial] = useState(newOrUpdate || {});

  useEffect(() => {
    if (newOrUpdateInitial.mode === 'edit') {
      setLoading(true);
      const getData = async () => {
        const results = await configurationService.getConfigParameterId(newOrUpdateInitial.id, 'servico');
        const tagsArray = results.items.tags.map(item => item.nome);
        setNewOrUpdate({
          ...newOrUpdateInitial,
          tags: transformedTags(tagsArray),
        });
        setLoading(false);
      };
      getData();
    } else {
      setNewOrUpdate({ ...newOrUpdateInitial, tags: '' });
      setLoading(false);
    }
  }, [newOrUpdateInitial, setNewOrUpdate]);

  return (
    <Box>
      <Box my={5}>
        <TextField
          fullWidth
          label="Serviço"
          variant="outlined"
          value={newOrUpdate.name || ''}
          onChange={e => setNewOrUpdate({ ...newOrUpdate, name: e.target.value })}
        />
      </Box>
      <Box my={7}>
        <TextField
          fullWidth
          label="Prazo (Em dias)"
          variant="outlined"
          type="number"
          inputProps={{ min: 1 }}
          value={newOrUpdate.duetime || '20'}
          onChange={e => setNewOrUpdate({ ...newOrUpdate, duetime: e.target.value })}
        />
      </Box>
      <Box my={7} display="flex">
        <TextField
          fullWidth
          label="Lista de tags (separado por vírgula)"
          placeholder="Tag 1, tag 2, ..."
          variant="outlined"
          type="text"
          value={newOrUpdate.tags || ''}
          onChange={e => setNewOrUpdate({ ...newOrUpdate, tags: e.target.value })}
        />
        {newOrUpdate.mode === 'edit' && loading ? (
          <Box ml={2}>
            <CircularProgress />
          </Box>
        ) : null}
      </Box>
      <FormControl fullWidth>
        <InputLabel id="service-subject-label">Assunto</InputLabel>
        <Select
          labelId="service-subject-label"
          value={newOrUpdate.subjectId || ''}
          onChange={e => setNewOrUpdate({ ...newOrUpdate, subjectId: e.target.value })}
          label="Assunto">
          {subjects.map(item => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default ServiceCustomForm;
