import React from 'react';
import { Box, Paper, Link } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/People';
import useMediaQuery from '@material-ui/core/useMediaQuery';

const useStyles = makeStyles(theme => ({
  overviewRoot: {
    height: '100%',
    borderRadius: '25px',
  },
  ibgeSectionVertical: {
    color: theme.palette.common.white,
    justifyContent: 'center',
    // height: '45%',
    // borderTopLeftRadius: '25px',
    // borderTopRightRadius: '25px',
    borderRadius: '25px',
    background: 'linear-gradient(170deg, rgba(98,115,196,1) 0%, rgba(45,50,148,1) 100%)',
    '& .highlighted-text': {
      color: 'yellow',
    },
  },
  usersSectionVertical: {
    // height: '55%',
    '& .indicator': {
      color: theme.palette.common.white,
      backgroundColor: 'rgba(0, 0, 0, 0.95)',
      padding: '0.25rem 0.5rem',
      borderRadius: '15px',
    },
  },
  ibgeSectionHorizontal: {
    // width: '50%',
    width: '100%',
    color: theme.palette.common.white,
    // borderTopLeftRadius: '25px',
    // borderBottomLeftRadius: '25px',
    borderRadius: '25px',
    background: 'linear-gradient(170deg, rgba(98,115,196,1) 0%, rgba(45,50,148,1) 100%)',
    '& .highlighted-text': {
      color: 'yellow',
    },
  },
  usersSectionHorizontal: {
    width: '50%',
    '& .indicator': {
      color: theme.palette.common.white,
      backgroundColor: 'rgba(0, 0, 0, 0.95)',
      padding: '0.25rem 0.5rem',
      borderRadius: '15px',
    },
  },
  horizontalLayout: {
    display: 'flex',
    justifyContent: 'space-between',
    height: '100%',
  },
  verticalLayout: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
  },
  linkStyle: {
    color: 'yellow',
    paddingLeft: '0.25rem',
  },
}));

const GeneralOverviewCard = () => {
  const classes = useStyles();

  const matchesSmMd = useMediaQuery(theme => theme.breakpoints.between('sm', 'md'));

  const populationContent = horizontal => {
    return (
      <Box
        className={horizontal ? classes.ibgeSectionHorizontal : classes.ibgeSectionVertical}
        px={4}
        py={2}
        flex={1}
        display="flex"
        flexDirection="column">
        {/* <Box fontSize={12} fontWeight={500}>
          Dados referênte ao município de:
        </Box> */}
        <Box fontSize={18} mb={2} fontWeight={700}>
          Sorocaba - SP
        </Box>
        <Box fontSize={12} mt={1} mb={2} fontWeight={700} display="flex">
          <PeopleIcon />
          <Box mx={1}>População:</Box>
          <Box className="highlighted-text">687.357</Box>
        </Box>
        {/* <Box fontSize={12} mb={1} fontWeight={500} display="flex">
          <Box mx={1}>Homens:</Box>
          <Box className="highlighted-text">323.249</Box>
        </Box>
        <Box fontSize={12} mb={1} fontWeight={500} display="flex">
          <Box mx={1}>Mulheres:</Box>
          <Box className="highlighted-text">364.108</Box>
        </Box> */}
        <Box fontSize={8} fontWeight={700} className="highlighted-text">
          Dados obtidos através do
          <Link href="https://cidades.ibge.gov.br/brasil/sp/sorocaba/panorama" className={classes.linkStyle}>
            IBGE
          </Link>
        </Box>
      </Box>
    );
  };

  const satisfactionContent = horizontal => {
    return <Box />;
    // return (
    //   <Box
    //     className={horizontal ? classes.usersSectionHorizontal : classes.usersSectionVertical}
    //     px={2}
    //     py={4}
    //     display="flex"
    //     flexDirection="column"
    //     alignItems="center">
    //     <Box fontSize={12} my={2} fontWeight={700} textAlign="center">
    //       Nível de satisfação geral da população
    //     </Box>
    //     <Box my={1}>
    //       <img src="images/f5-assets/question-mark.png" alt="Icone de avaliação" width="75px" />
    //     </Box>
    //     <Box className="indicator" fontSize={12} fontWeight={700} mb={2}>
    //       Sem Avaliações
    //     </Box>
    //     <Box textAlign="center" fontSize={12} width={'90%'}>
    //       Dados obtidos através da participação voluntária dos usuários
    //     </Box>
    //   </Box>
    // );
  };

  if (matchesSmMd) {
    return (
      <Paper className={classes.overviewRoot}>
        <Box className={classes.horizontalLayout}>
          {populationContent(true)}
          {satisfactionContent(true)}
        </Box>
      </Paper>
    );
  }

  return (
    <Paper className={classes.overviewRoot}>
      <Box className={classes.verticalLayout}>
        {populationContent(false)}
        {satisfactionContent(false)}
      </Box>
    </Paper>
  );
};

export default GeneralOverviewCard;
