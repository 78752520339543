import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  changeMapMode,
  changeDepartmentFilter,
  initializeOcurrencesFilters,
  changeSectorsFilter,
} from '../../../redux/actions/f5-actions/ocurrences-map';
import {
  Box,
  Paper,
  Switch,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Divider,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  rootPaper: {
    marginBottom: theme.spacing(2),
  },
  rootLastPaper: {
    flexGrow: 1,
  },
  switchBase: {
    color: 'rgba(0, 255, 0, 1)',
    '&$checked': {
      color: 'rgba(255, 0, 0, 1)',
    },
    '&$checked + $track': {
      backgroundColor: 'rgba(200, 0, 0, 1)',
    },
  },
  track: {
    backgroundColor: 'rgba(0, 200, 0, 1)',
  },
  switchWrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& .pinColor': {
      color: 'rgba(0, 200, 0, 1)',
    },
    '& .heatColor': {
      color: 'rgba(200, 0, 0, 1)',
    },
  },
  formControl: {
    width: '90%',
  },
}));

const switchOptions = ['marker', 'heat'];

const GeneralMapActions = () => {
  const [switchOption, setSwitchOption] = useState(0);
  const [departamentFilter, setDepartmentFilter] = useState(0);
  const departmentList = useSelector(({ ocurrences_map }) => ocurrences_map.departments);
  const sectorList = useSelector(({ ocurrences_map }) => ocurrences_map.sectors);
  const [sectorsFilter, setSectorsFilter] = useState({
    0: true,
    1: true,
    2: true,
    3: true,
    4: true,
    5: true,
    6: true,
  });
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(changeMapMode(switchOptions[switchOption]));
  }, [dispatch, switchOption]);

  useEffect(() => {
    dispatch(changeDepartmentFilter(departamentFilter));
  }, [dispatch, departamentFilter]);

  useEffect(() => {
    dispatch(changeSectorsFilter(sectorsFilter));
  }, [dispatch, sectorsFilter]);

  useEffect(() => {
    dispatch(initializeOcurrencesFilters());
  }, [dispatch]);

  const classes = useStyles();

  const changeSwitch = () => {
    setSwitchOption(switchOption === 0 ? 1 : 0);
  };

  const changeDepartment = event => {
    setDepartmentFilter(event.target.value);
  };

  const selectSector = event => {
    setSectorsFilter({
      ...sectorsFilter,
      [event.target.name]: event.target.checked,
    });
  };

  return (
    <Box display="flex" flexDirection="column" height={{ sm: 450 }}>
      <Paper classes={{ root: classes.rootPaper }}>
        <Box mt={2} fontWeight={700} textAlign="center" fontSize={20}>
          Modo de Visualização
        </Box>
        <Box p={1} mb={1} className={classes.switchWrapper}>
          <Box mx={1} className={switchOption === 0 ? 'pinColor' : ''} fontWeight={500}>
            Marcadores
          </Box>
          <Switch
            inputProps={{
              'aria-label': 'checkbox with default color',
            }}
            classes={{
              switchBase: classes.switchBase,
              track: classes.track,
            }}
            onChange={changeSwitch}
          />
          <Box mx={1} className={switchOption === 1 ? 'heatColor' : ''} fontWeight={500}>
            Área de Calor
          </Box>
        </Box>
      </Paper>
      <Paper classes={{ root: classes.rootPaper }}>
        <Box p={3} display="flex" justifyContent="center">
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel id="department-select">Departamento</InputLabel>
            <Select labelId="department-select" value={departamentFilter} onChange={changeDepartment} label="Departamento">
              <MenuItem value={0} key={0}>
                Todos os Departamentos
              </MenuItem>
              {departmentList && Array.isArray(departmentList)
                ? departmentList.map(item => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.nome}
                    </MenuItem>
                  ))
                : null}
            </Select>
          </FormControl>
        </Box>
      </Paper>
      <Paper classes={{ root: classes.rootLastPaper }}>
        <Box my={2} fontWeight={700} textAlign="center" fontSize={20}>
          Filtrar Marcadores
        </Box>
        <Divider />
        <Box my={2} px={3}>
          <Box fontSize={12} fontWeight={700} my={1}>
            Setores
          </Box>
          <Box display="flex" flexWrap="wrap">
            {sectorList
              ? sectorList.map(sector => (
                  <Box width={'50%'} key={sector.id}>
                    <FormControlLabel
                      control={
                        <Checkbox checked={sectorsFilter[sector.id]} onChange={selectSector} name={sector.id.toString()} />
                      }
                      label={sector.nome}
                    />
                  </Box>
                ))
              : null}
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default GeneralMapActions;
