import React from 'react';
import { Box, TextField } from '@material-ui/core';

const GravityCustomForm = ({ newOrUpdate, setNewOrUpdate }) => {
  return (
    <Box>
      <TextField
        fullWidth
        label="Gravidade"
        variant="outlined"
        value={newOrUpdate.name || ''}
        onChange={e => setNewOrUpdate({ ...newOrUpdate, name: e.target.value })}
      />
    </Box>
  );
};

export default GravityCustomForm;
