import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesPending } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const PendingOcurrences = () => {
  const [currentList, setCurrentList] = useState('pending');
  const pendingData = useSelector(({ ocurrences_data }) => ocurrences_data.pending.caixa);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesPending());
  }, [dispatch]);

  const ocurrencesData = {
    pending: {
      icon: <HelpOutlineIcon fontSize="large" />,
      label: 'Ocorrências Pendentes',
      colors: ['#db9237', '#b56c10'],
      data: pendingData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.pending.label}
          icon={ocurrencesData.pending.icon}
          colors={ocurrencesData.pending.colors}
          value={ocurrencesData.pending.data ? ocurrencesData.pending.data.length : 0}
          setList={() => setCurrentList('pending')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default PendingOcurrences;
