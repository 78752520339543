import React from 'react';
import { useParams } from 'react-router-dom';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import SubdepartmentOcurrencesPage from '../../../f5/subdepartment/SubdepartmentOcurrencesPage';
import OcurrenceNavbar from '../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const SubdepartmentOcurrences = () => {
  const { id } = useParams();

  return (
    <PageContainer>
      <OcurrenceNavbar />
      <SubdepartmentOcurrencesPage id={id} />
    </PageContainer>
  );
};

export default SubdepartmentOcurrences;
