import React, { useState, useEffect } from 'react';
import generalService from 'services/f5-services/generalService';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, TextField, Button, CircularProgress, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CallMadeIcon from '@material-ui/icons/CallMade';

import FeedbackAlert from './FeedbackAlert';
import sweetAlert from 'f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

const OmbudsmanRedirect = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [alertOpen, setAlertOpen] = useState(false);
  const [description, setDescription] = useState('');
  const [note, setNote] = useState('');
  const [ombudsOffice, setOmbudsOffice] = useState('');
  const [ombudsOffices, setOmbudsOffices] = useState([]);

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    setLoading({ message: 'Carregando dados...', status: true });
    const getData = async () => {
      const ombuds = await generalService.getOmbudsOffices();
      setOmbudsOffices(ombuds.ouvidorias);
      setLoading({ message: '', status: false });
    };

    getData();
  }, [setLoading, setOmbudsOffices]);

  // TODO: Melhorar validações de formulário
  const validateForm = () => {
    if (!description || !ombudsOffice) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading({ status: true, message: 'Encaminhando...' });

      const postData = {
        observacao: note,
        descricao: description,
        ouvidoria: ombudsOffice,
        ocorrencia_id: ocurrence.ocorrencia_id,
        destinatario_id: 1,
      };

      await ocurrencesActionService.sendOmbudsmanRedirect(postData);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      sweetAlert('success', 'Sucesso', 'Ocorrência encaminhada com sucesso!', () => {
        setLoading({ status: false, message: '' });
      });
    } else {
      setAlertOpen(true);
    }
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<CallMadeIcon />} title="Encaminhar a Ouvidoria" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={ocurrence.abertura}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%" mb={4}>
        <FormControl fullWidth required>
          <InputLabel id="ombudsman-redirect-label">Ouvidoria</InputLabel>
          <Select
            labelId="ombudsman-redirect-label"
            id="ombudsman-redirect-input"
            value={ombudsOffice}
            onChange={e => setOmbudsOffice(e.target.value)}>
            {ombudsOffices.map(item => {
              return (
                <MenuItem key={item.id} value={item.id}>
                  {item.nome}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </Box>
      <Box width="100%" mb={4}>
        <TextField
          label="Descrição"
          required
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={description}
          onChange={e => setDescription(e.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <TextField
          label="Observação"
          fullWidth
          variant="outlined"
          size="small"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          enviar
        </Button>
      </Box>
      <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
  );
};

export default OmbudsmanRedirect;
