import React from 'react';
import { Box, TextField, Select, FormControl, InputLabel, MenuItem } from '@material-ui/core';

const SubjectCustomForm = ({ newOrUpdate, setNewOrUpdate, themes }) => {
  return (
    <Box>
      <Box my={2}>
        <TextField
          fullWidth
          label="Assunto"
          variant="outlined"
          value={newOrUpdate.name || ''}
          onChange={e => setNewOrUpdate({ ...newOrUpdate, name: e.target.value })}
        />
      </Box>
      <FormControl fullWidth>
        <InputLabel id="subject-theme-label">Tema</InputLabel>
        <Select
          labelId="subject-theme-label"
          value={newOrUpdate.themeId || ''}
          onChange={e => setNewOrUpdate({ ...newOrUpdate, themeId: e.target.value })}
          label="Tema">
          {themes.map(item => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default SubjectCustomForm;
