import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import ExtendOcurrences from '../../../../f5/extend/ExtendOcurrences';

const Extend = () => {
  const location = useLocation();
  const query = new URLSearchParams(location.search);

  useEffect(() => {
    window.history.replaceState(
      {
        ...window.history.state,
        as: `#${location.pathname}`,
        url: `#${location.pathname}`,
      },
      '',
      `#${location.pathname}`,
    );
  }, [location.pathname]);

  return (
    <PageContainer>
      <ExtendOcurrences initView={query.get('v')} />
    </PageContainer>
  );
};

export default Extend;
