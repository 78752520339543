import React from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    width: 'fit-content',
    backgroundColor: 'white',
    borderRadius: '10px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    color: 'black',
    '& .custom-divider': {
      bottom: '-10px',
      left: 0,
      width: '100%',
      height: '3px',
      backgroundColor: 'rgba(0, 0, 0, 0.75)',
    },
  },
}));

const OcurrencesHeader = ({ title, icon, size }) => {
  const classes = useStyles();

  return (
    <Box py={4} pl={4} pr={8} fontSize={size} className={classes.root}>
      {icon}
      <Box ml={2} display="flex" flexDirection="column">
        <Box>{title}</Box>
        <Box className="custom-divider" />
      </Box>
    </Box>
  );
};

export default OcurrencesHeader;
