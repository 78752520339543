import React, { useEffect, useState } from 'react';
import { Box, CircularProgress } from '@material-ui/core';
import { useDispatch } from 'react-redux';
import { cleanDepartmentOmbudsman } from '../../../redux/actions/f5-actions/department-ombudsman';
import generalService from 'services/f5-services/generalService';

import ChooseSector from 'f5/sectors/ChooseSector';

const DepartmentOmbudsman = () => {
  const [sectors, setSectors] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(cleanDepartmentOmbudsman());
  }, [dispatch]);

  useEffect(() => {
    const getSectors = async () => {
      const res = await generalService.getSectorsCounter();

      setSectors(res.counters);
    };

    getSectors();
  }, [setSectors]);

  const loader = () => {
    return (
      <Box width="100%" height={150} display="flex" justifyContent="center" alignItems="center" flexDirection="column">
        <CircularProgress />
        <Box fontWeight={700} fontSize={12}>
          Carregando dados...
        </Box>
      </Box>
    );
  };

  return sectors ? <ChooseSector sectors={sectors} /> : loader();
};

export default DepartmentOmbudsman;
