import React from 'react';
import { Page, Document } from '@react-pdf/renderer';
import {
  RequestInformation,
  RequestLocation,
  RequestTriage,
  RequestDescription,
  RequestCommunication,
  RequestRedirect,
  RequestAnswer,
  RequestCompleted,
  RequestAppeal,
  RequestHeader,
  RequestFooter,
} from './SharedPrint';

const PrintOcurrencePDF = ({ ocurrence, ...props }) => {
  return (
    <Document>
      <Page size="A4" style={{ padding: 5 }}>
        <RequestHeader ocurrence={ocurrence} />
        <RequestInformation ocurrence={ocurrence} />
        <RequestLocation ocurrence={ocurrence} />
        <RequestTriage ocurrence={ocurrence} />
        <RequestDescription ocurrence={ocurrence} />
        {ocurrence.comunicacoes.map((item, pos) => (
          <RequestCommunication communication={item} pos={pos} />
        ))}
        {ocurrence.movimentos.map(item => {
          switch (item.passo_interno) {
            case 'pendente':
            case 'ouv-pendente':
              return <RequestRedirect movement={item} />;
            case 'andamento':
            case 'ouv-andamento':
              try {
                let outcome = JSON.parse(item.descricao);
                return <RequestAnswer json={outcome} movement={item} />;
              } catch (error) {
                return null;
              }
            case 'solucionada':
            case 'ouv-solucionada':
              return null;
            case 'finalizada':
            case 'ouv-finalizada':
              try {
                let outcome = JSON.parse(item.descricao);
                return <RequestCompleted json={outcome} movement={item} />;
              } catch (error) {
                return null;
              }
            case 'recurso':
              return <RequestAppeal movement={item} />;
            default:
              return null;
          }
        })}
        <RequestFooter />
      </Page>
    </Document>
  );
};

export default PrintOcurrencePDF;
