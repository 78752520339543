import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesSolved } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import CheckBoxIcon from '@material-ui/icons/CheckBox';

const SolvedOcurrences = () => {
  const [currentList, setCurrentList] = useState('solved');
  const solvedData = useSelector(({ ocurrences_data }) => ocurrences_data.solved.caixa);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesSolved());
  }, [dispatch]);

  const ocurrencesData = {
    solved: {
      icon: <CheckBoxIcon fontSize="large" />,
      label: 'Ocorrências Respondidas',
      colors: ['#3dd461', '#099c2c'],
      data: solvedData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.solved.label}
          icon={ocurrencesData.solved.icon}
          colors={ocurrencesData.solved.colors}
          value={ocurrencesData.solved.data ? ocurrencesData.solved.data.length : 0}
          setList={() => setCurrentList('solved')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default SolvedOcurrences;
