import React, { useState, useEffect } from 'react';
import { Grid } from '@material-ui/core';
import GridContainer from '../../@jumbo/components/GridContainer';
import generalService from '../../services/f5-services/generalService';

import SimpleDataCard from '../ocurrences/SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../ocurrences/SharedComponents/OcurrencesDataRender';

import ForumIcon from '@material-ui/icons/Forum';

const CommunicationPage = () => {
  const [currentList, setCurrentList] = useState('commmunication');
  const [communicationData, setCommunicationData] = useState(null);

  useEffect(() => {
    const getData = async () => {
      const res = await generalService.getCommunicationOcurrences();

      setCommunicationData(res.caixa);
    };

    getData();
  }, [setCommunicationData]);

  const ocurrencesData = {
    commmunication: {
      icon: <ForumIcon fontSize="large" />,
      label: 'Comunicações Respondidas',
      colors: ['#e0754f', '#e68d6e'],
      data: communicationData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.commmunication.label}
          icon={ocurrencesData.commmunication.icon}
          colors={ocurrencesData.commmunication.colors}
          value={ocurrencesData.commmunication.data ? ocurrencesData.commmunication.data.length : 0}
          setList={() => setCurrentList('commmunication')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default CommunicationPage;
