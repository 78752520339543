import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import DueOcurrences from '../../../../f5/ocurrences/DueOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Due = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <DueOcurrences />
    </PageContainer>
  );
};

export default Due;
