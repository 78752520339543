import {
  INIT_OCURRENCES_MAP,
  CHANGE_MAP_MODE,
  INIT_MAP_FILTERS,
  CHANGE_DEPARTMENT_FILTER,
  CHANGE_SECTOR_FILTER,
} from '../../actions/f5-actions/action-types';

const INIT_STATE = {
  data: [],
  mode: 'marker',
  departments: [],
  filters: {
    department: 0,
    sectors: {},
  },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case INIT_OCURRENCES_MAP: {
      return { ...state, data: action.data };
    }
    case INIT_MAP_FILTERS: {
      return {
        ...state,
        departments: action.data.departamentos,
        sectors: action.data.filtros.setor,
      };
    }
    case CHANGE_MAP_MODE: {
      return { ...state, mode: action.payload };
    }
    case CHANGE_DEPARTMENT_FILTER: {
      const newFilters = { ...state.filters, department: action.payload };
      return { ...state, filters: newFilters };
    }
    case CHANGE_SECTOR_FILTER: {
      const newFilters = { ...state.filters, sectors: action.payload };
      return { ...state, filters: newFilters };
    }
    default:
      return state;
  }
};
