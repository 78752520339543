import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';

import PlaylistAddCheckIcon from '@material-ui/icons/PlaylistAddCheck';
import CategoryIcon from '@material-ui/icons/Category';
import FolderIcon from '@material-ui/icons/Folder';
import WarningIcon from '@material-ui/icons/Warning';
import MapIcon from '@material-ui/icons/Map';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  responsiveFlex: {
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
    },
  },
}));

const TriageCard = ({ category, gravity, entry, sector }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <PlaylistAddCheckIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Dados da Triagem
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box display="flex" mb={2} className={classes.responsiveFlex}>
          <Box width={'50%'} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mb={1}>
              <CategoryIcon fontSize="small" />
              <Box ml={1} fontWeight={700}>
                Categoria:
              </Box>
            </Box>
            <Box ml={2}>{category}</Box>
          </Box>
          <Box width={'50%'} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mb={1}>
              <WarningIcon fontSize="small" />
              <Box ml={1} fontWeight={700}>
                Gravidade:
              </Box>
            </Box>
            <Box ml={2}>{gravity}</Box>
          </Box>
        </Box>
        <Box display="flex" className={classes.responsiveFlex}>
          <Box width={'50%'} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mb={1}>
              <MapIcon fontSize="small" />
              <Box ml={1} fontWeight={700}>
                Entrada:
              </Box>
            </Box>
            <Box ml={2}>{entry}</Box>
          </Box>
          <Box width={'50%'} display="flex" alignItems="center">
            <Box display="flex" alignItems="center" mb={1}>
              <FolderIcon fontSize="small" />
              <Box ml={1} fontWeight={700}>
                Setor:
              </Box>
            </Box>
            <Box ml={2}>{sector}</Box>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default TriageCard;
