import ocurrencesActionService from '../../../services/f5-services/ocurrencesActionService';
import { RESET_NEW_OCURRENCE, CHECK_CPF_DATA, CHECK_CNPJ_DATA, INIT_CREATE_FORM_DATA } from './action-types';

export const resetNewOcurrence = mode => {
  return {
    type: RESET_NEW_OCURRENCE,
  };
};

export const initializeOcurrenceCreateFormData = cpf => {
  return async dispatch => {
    const formData = await ocurrencesActionService.getOcurrenceNewFormData();
    dispatch({
      type: INIT_CREATE_FORM_DATA,
      payload: formData.items,
    });
  };
};

export const checkCpf = cpf => {
  return async dispatch => {
    const cpfData = await ocurrencesActionService.getUserDataByCpf(cpf);
    dispatch({
      type: CHECK_CPF_DATA,
      payload: cpfData.data,
    });
  };
};

export const checkCnpj = cnpj => {
  return async dispatch => {
    const cnpjData = await ocurrencesActionService.getUserDataByCnpj(cnpj);
    dispatch({
      type: CHECK_CNPJ_DATA,
      payload: cnpjData.data,
    });
  };
};
