import React from 'react';
import { Box } from '@material-ui/core';

const SharedTitle = ({ icon, title }) => {
  return (
    <Box display="flex" alignItems="center" mb={4}>
      {icon}
      <Box ml={2} fontSize={20} fontWeight={700}>
        {title}
      </Box>
    </Box>
  );
};

export default SharedTitle;
