import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesDue } from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import { useLocation } from 'react-router-dom';
import { isValid, isSameDay } from 'date-fns';

import SimpleDataCard from '../SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../SharedComponents/OcurrencesDataRender';

import WarningIcon from '@material-ui/icons/Warning';

const DueOcurrences = () => {
  const [currentList, setCurrentList] = useState('due');
  const dueData = useSelector(({ ocurrences_data }) => ocurrences_data.due.caixa);
  const dispatch = useDispatch();

  const query = new URLSearchParams(useLocation().search);
  const dateQuery = query.get('data');

  const preparedData = pd => {
    if (dateQuery && pd && isValid(new Date(dateQuery))) {
      const dateFilter = new Date(`${dateQuery}T03:00:00`);
      return pd.filter(item => {
        return isSameDay(dateFilter, new Date(item.prazo));
      });
    } else {
      return pd;
    }
  };

  useEffect(() => {
    dispatch(initializeOcurrencesDue());
  }, [dispatch]);

  const ocurrencesData = {
    due: {
      icon: <WarningIcon fontSize="large" />,
      label: `Ocorrências a Vencer ${dateQuery ? dateQuery : ''}`,
      colors: ['#c7532c', '#962d09'],
      data: dueData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.due.label}
          icon={ocurrencesData.due.icon}
          colors={ocurrencesData.due.colors}
          value={preparedData(ocurrencesData.due.data) ? preparedData(ocurrencesData.due.data).length : 0}
          setList={() => setCurrentList('due')}
        />
      </Grid>
      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={preparedData(ocurrencesData[currentList].data)}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default DueOcurrences;
