import React from 'react';
import ocurrencesActionService from 'services/f5-services/ocurrencesActionService';
import { Box, TextField, Button } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import InputMask from 'react-input-mask';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  checkButton: {
    fontSize: 12,
    color: 'white',
    backgroundColor: '#dbc60d',
    '&:hover': {
      backgroundColor: '#d9c623',
    },
  },
}));

const unformatCep = c => {
  return c.replace(/[^0-9]/g, '');
};

const LocalFields = ({ createOcurrenceForm, handleFormChange, setUseAddressReference, useAddressReference, validateMe }) => {
  const classes = useStyles();

  const handleAddress = async () => {
    const rawCep = unformatCep(createOcurrenceForm.ocurrenceDataCep);
    if (rawCep.length === 8) {
      const addressData = await ocurrencesActionService.getAddressData(rawCep);

      handleFormChange(null, {
        ocurrenceDataAddressComplement: addressData.complemento,
        ocurrenceDataAddressStreet: addressData.logradouro,
        ocurrenceDataAddressDistrict: addressData.bairro,
      });
    }
  };

  return (
    <Box>
      <Box my={4}>
        <FormControlLabel
          control={
            <Checkbox
              inputProps={{ tabIndex: 208 }}
              icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
              checkedIcon={<CheckBoxIcon fontSize="small" />}
              name="use-address-reference"
              value={useAddressReference}
              onChange={() => {
                setUseAddressReference(!useAddressReference);
              }}
            />
          }
          label="Usar o endereço do solicitante como endereço da ocorrência"
        />
      </Box>
      <Box display="flex">
        <Box width="50%" display="flex" flexDirection="column">
          <Box mb={8} display="flex" alignItems="center">
            <InputMask mask="99.999-999" value={createOcurrenceForm.ocurrenceDataCep} onChange={handleFormChange}>
              <TextField
                error={validateMe('ocurrenceDataCep').isError}
                helperText={validateMe('ocurrenceDataCep').message}
                inputProps={{ tabIndex: 209 }}
                name="ocurrenceDataCep"
                fullWidth
                size="small"
                id="cep-search-input"
                label="CEP"
              />
            </InputMask>
            <Box ml={2} display="flex" alignItems="center" width="50%">
              <Button
                disabled={unformatCep(createOcurrenceForm.ocurrenceDataCep).length !== 8}
                variant="contained"
                classes={{ root: classes.checkButton }}
                size="small"
                onClick={handleAddress}>
                Buscar por CEP
              </Button>
            </Box>
          </Box>
          <Box mb={8} display="flex" width="95%">
            <Box width="100%">
              <TextField
                error={validateMe('ocurrenceDataAddressNumber').isError}
                helperText={validateMe('ocurrenceDataAddressNumber').message}
                inputProps={{ tabIndex: 211 }}
                fullWidth
                size="small"
                id="person-number-input"
                label="Número"
                name="ocurrenceDataAddressNumber"
                value={createOcurrenceForm.ocurrenceDataAddressNumber}
                onChange={handleFormChange}
              />
            </Box>
            <Box width="100%" ml={4}>
              <TextField
                error={validateMe('ocurrenceDataAddressComplement').isError}
                helperText={validateMe('ocurrenceDataAddressComplement').message}
                inputProps={{ tabIndex: 212 }}
                fullWidth
                size="small"
                id="person-complement-input"
                label="Complemento"
                name="ocurrenceDataAddressComplement"
                value={createOcurrenceForm.ocurrenceDataAddressComplement}
                onChange={handleFormChange}
              />
            </Box>
          </Box>
        </Box>
        <Box width="50%" display="flex" flexDirection="column">
          <Box width="100%" mb={8}>
            <TextField
              error={validateMe('ocurrenceDataAddressStreet').isError}
              helperText={validateMe('ocurrenceDataAddressStreet').message}
              inputProps={{ tabIndex: 210 }}
              fullWidth
              size="small"
              id="person-street-input"
              label="Rua"
              required
              name="ocurrenceDataAddressStreet"
              value={createOcurrenceForm.ocurrenceDataAddressStreet}
              onChange={handleFormChange}
            />
          </Box>
          <Box mb={8} width="95%">
            <TextField
              error={validateMe('ocurrenceDataAddressDistrict').isError}
              helperText={validateMe('ocurrenceDataAddressDistrict').message}
              inputProps={{ tabIndex: 213 }}
              fullWidth
              size="small"
              id="person-district-input"
              label="Bairro"
              required
              name="ocurrenceDataAddressDistrict"
              value={createOcurrenceForm.ocurrenceDataAddressDistrict}
              onChange={handleFormChange}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default LocalFields;
