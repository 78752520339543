import React from 'react';
import { Box, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  dangerButton: {
    backgroundColor: 'rgba(245, 32, 32, 0.95)',
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(247, 22, 10, 1)',
    },
  },
  updateButton: {
    color: 'white',
    backgroundColor: 'rgba(66, 135, 245, 0.95)',
    '&:hover': {
      backgroundColor: 'rgba(43, 130, 252, 1)',
    },
  },
}));

const SharedActions = ({ editCallback, deleteCallback }) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center">
      <Box mr={2}>
        <IconButton size="small" className={classes.updateButton} onClick={editCallback}>
          <CreateIcon fontSize="small" />
        </IconButton>
      </Box>
      <Box mr={2}>
        <IconButton size="small" className={classes.dangerButton} onClick={deleteCallback}>
          <DeleteForeverIcon fontSize="small" />
        </IconButton>
      </Box>
    </Box>
  );
};

export default SharedActions;
