import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Grid, Button, CircularProgress, Chip } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

import ReceiptIcon from '@material-ui/icons/Receipt';
import PrintOrder from './ActionsModals/PrintOrder';
import ActionsModal from './ActionsModal';

import Actions from './Actions';
import InformationCard from './InformationCard';
import TriageCard from './TriageCard';
import RequestCard from './RequestCard';
import LocationCard from './LocationCard';
import AttachmentCard from './AttachmentCard';
import RedirectionCard from './RedirectionCard';
import AnswerCard from './AnswerCard';
import SolutionCard from './SolutionCard';
import FinishedCard from './FinishedCard';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  headerRoot: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  serviceOrderRoot: {
    fontSize: '12px',
    backgroundColor: '#d9216d',
    color: 'white',
    '&:hover': {
      backgroundColor: '#e63980',
    },
  },
  chipRoot: {
    color: 'rgba(255, 255, 255, 0.95)',
    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.5)',
    backgroundColor: ({ chipColor }) => chipColor,
  },
}));

const chipStyle = (phase, access) => {
  switch (phase) {
    case 'nova':
    case 'ouv-nova':
    case 'ouv-encaminhada':
      return { label: 'Recebida', color: '#2f6bcc' };
    case 'pendente':
    case 'ouv-pendente':
      return ['gestao', 'funcionario', 'departamento', 'sub_departamento'].includes(access)
        ? { label: 'Recebida', color: '#2f6bcc' }
        : { label: 'Pendente', color: '#db9237' };
    case 'andamento':
    case 'ouv-andamento':
      return { label: 'Andamento', color: '#368f6e' };
    case 'solucionada':
    case 'ouv-solucionada':
      return { label: 'Solucionada', color: '#3dd461' };
    case 'finalizada':
    case 'ouv-finalizada':
      return { label: 'Finalizada', color: '#4a99ba' };
    case 'cancelada':
      return { label: 'Cancelada', color: '#b3597b' };
    case 'prefeito':
      return { label: 'Prefeito', color: 'black' };
    case 'estornada':
    case 'ouv-estornada':
      return { label: 'Estornada', color: '#2f6bcc' };
    case 'recurso':
      return { label: 'Recurso', color: '#d9216d' };
    default:
      return { label: '', color: 'grey' };
  }
};

const OcurrenceDetails = ({ ocurrence }) => {
  const userAccess = useSelector(({ auth }) => (auth.authUser ? auth.authUser.cargo.interno : null));
  const [openModal, setOpenModal] = useState(false);

  const classes = useStyles({
    chipColor: chipStyle(ocurrence.passo_interno, userAccess).color,
  });
  let history = useHistory();

  const serviceOrder = {
    text: 'Ordem de Serviço',
    icon: <ReceiptIcon fontSize="small" />,
    component: <PrintOrder ocurrence={ocurrence} />,
    access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
    phases: [
      'pendente',
      'ouv-pendente',
      'andamento',
      'ouv-andamento',
      'solucionada',
      'ouv-solucionada',
      'finalizada',
      'ouv-finalizada',
    ],
  };

  const goBack = () => {
    history.goBack();
  };

  const formattedAddress = () => {
    let addressText;
    addressText = ocurrence.solicitante.rua ? ocurrence.solicitante.rua : '';
    addressText += ocurrence.solicitante.numero ? `, N° ${ocurrence.solicitante.numero}` : '';
    addressText += ocurrence.solicitante.bairro ? `, ${ocurrence.solicitante.bairro}` : '';
    return addressText;
  };

  const dynamicCards = () => {
    return ocurrence.movimentos.reduce((acc, item) => {
      switch (item.passo_interno) {
        case 'pendente':
        case 'ouv-pendente': {
          acc.push(<RedirectionCard movement={item} />);
          return acc;
        }
        case 'andamento':
        case 'ouv-andamento': {
          acc.push(<AnswerCard movement={item} />);
          return acc;
        }
        case 'solucionada':
        case 'ouv-solucionada': {
          acc.push(<SolutionCard movement={item} />);
          return acc;
        }
        case 'finalizada':
        case 'ouv-finalizada': {
          acc.push(<FinishedCard movement={item} />);
          return acc;
        }
        default: {
          return acc;
        }
      }
    }, []);
  };

  if (!ocurrence) {
    return (
      <Box p={2} minHeight={350} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
        <CircularProgress />
        <Box mt={2} fontWeight={700} textAlign="center">
          Carregando dados...
        </Box>
      </Box>
    );
  }

  return (
    <GridContainer>
      <Grid item xs={12}>
        <Box my={2} className={classes.headerRoot}>
          <Box display="flex" alignItems="center">
            <Box display="flex" alignItems="center">
              <LibraryBooksIcon />
              <Box fontWeight={500} ml={2} fontSize={20}>
                Protocolo da Ocorrência: {ocurrence.protocolo}
              </Box>
            </Box>
            <Box ml={2}>
              <Chip
                size="small"
                label={chipStyle(ocurrence.passo_interno, userAccess).label}
                classes={{ root: classes.chipRoot }}
              />
            </Box>
          </Box>
          <Box display="flex">
            {serviceOrder.access.includes(userAccess) && serviceOrder.phases.includes(ocurrence.passo_interno) ? (
              <Box display="flex" alignItems="center">
                <Button
                  classes={{ root: classes.serviceOrderRoot }}
                  variant="contained"
                  startIcon={<ReceiptIcon />}
                  onClick={() => setOpenModal(true)}>
                  Ordem de Serviço
                </Button>
              </Box>
            ) : null}
            <Box ml={2} display="flex" alignItems="center">
              <Button variant="outlined" startIcon={<ArrowBackIcon />} onClick={goBack}>
                Voltar
              </Button>
            </Box>
          </Box>
        </Box>
      </Grid>
      <Grid container spacing={6} item xs={12} md={9}>
        <Grid item xs={12} sm={6}>
          <InformationCard
            requester={ocurrence.solicitante}
            movements={ocurrence.movimentos}
            openingDate={ocurrence.abertura}
            coletive={ocurrence.coletivo}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TriageCard
            category={ocurrence.categoria}
            gravity={ocurrence.gravidade}
            entry={ocurrence.entrada}
            sector={ocurrence.setor}
          />
        </Grid>
        <Grid item xs={12}>
          <RequestCard
            protocol={ocurrence.protocolo}
            theme={ocurrence.tema}
            subject={ocurrence.assunto}
            service={ocurrence.servico}
            description={ocurrence.descricao}
          />
        </Grid>
        {dynamicCards().map((item, index) => (
          <Grid key={index} item xs={12}>
            {item}
          </Grid>
        ))}
        <Grid item xs={12} md={6}>
          <LocationCard lat={ocurrence.lat} lng={ocurrence.lng} address={formattedAddress} />
        </Grid>
        <Grid item xs={12} md={6}>
          <AttachmentCard files={ocurrence.anexos} />
        </Grid>
      </Grid>
      <Grid item xs={12} md={3}>
        <Actions ocurrence={ocurrence} userAccess={userAccess} />
        <ActionsModal open={openModal} handleClose={() => setOpenModal(false)}>
          {serviceOrder.component}
        </ActionsModal>
      </Grid>
    </GridContainer>
  );
};

export default OcurrenceDetails;
