import axios from 'axios';

const appURL = 'https://api-sorocaba.appcidadeinteligente.com.br';

const axios_instance = axios.create({
  baseURL: `${appURL}/api/`,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  },
});

export const config = {
  appURL,
};

export default axios_instance;
