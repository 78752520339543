import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CircularProgress, Box } from '@material-ui/core';
import {
  resetNewOcurrence,
  initializeOcurrenceCreateFormData,
} from '../../../../redux/actions/f5-actions/ocurrences-create';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OcurrenceCreate from '../../../../f5/ocurrences/OcurrenceCreate';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const CreateOcurrence = () => {
  const formFieldReference = useSelector(({ ocurrences_create }) => ocurrences_create.fieldReference);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(resetNewOcurrence());
    dispatch(initializeOcurrenceCreateFormData());
  }, [dispatch]);

  const loader = () => {
    return (
      <Box width="100%" height={150} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Box>
    );
  };

  return (
    <PageContainer>
      <OcurrenceNavbar />
      {formFieldReference !== null ? <OcurrenceCreate formFieldReference={formFieldReference} /> : loader()}
    </PageContainer>
  );
};

export default CreateOcurrence;
