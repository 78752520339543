export const INIT_PIE_CHART = 'init_pie_chart';
export const INIT_OCURRENCES_COUNTER = 'init_ocurrences_counter';
export const INIT_OCURRENCES_DAILY = 'init_ocurrences_daily';
export const INIT_OCURRENCES_MAP = 'init_ocurrences_map';
export const CHANGE_MAP_MODE = 'change_map_mode';
export const INIT_MAP_FILTERS = 'init_map_filters';
export const CHANGE_DEPARTMENT_FILTER = 'change_department_filter';
export const CHANGE_SECTOR_FILTER = 'change_sector_filter';

export const SELECT_OCURRENCE = 'select_ocurrence';
export const INIT_FORM_DATA = 'init_form_data';

export const SET_DEPARTMENT_OMBUDSMAN = 'set_department_ombudsman';
export const CLEAN_DEPARTMENT_OMBUDSMAN = 'clean_department_ombudsman';

export const INIT_CREATE_FORM_DATA = 'init_create_form_data';
export const RESET_NEW_OCURRENCE = 'reset_new_ocurrence';
export const CHECK_CPF_DATA = 'check_cpf_data';
export const CHECK_CNPJ_DATA = 'check_cnpj_data';

export const INIT_OCURRENCES_ALL = 'init_ocurrences_all';
export const INIT_OCURRENCES_RECEIVED = 'init_ocurrences_received';
export const INIT_OCURRENCES_NEW = 'init_ocurrences_new';
export const INIT_OCURRENCES_RETURNED = 'init_ocurrences_returned';
export const INIT_OCURRENCES_PENDING = 'init_ocurrences_pending';
export const INIT_OCURRENCES_ONGOING = 'init_ocurrences_ongoing';
export const INIT_OCURRENCES_ONTIME = 'init_ocurrences_ontime';
export const INIT_OCURRENCES_DUE = 'init_ocurrences_due';
export const INIT_OCURRENCES_OVERDUE = 'init_ocurrences_overdue';
export const INIT_OCURRENCES_COMPLETED = 'init_ocurrences_completed';
export const INIT_OCURRENCES_SOLVED = 'init_ocurrences_solved';
export const INIT_OCURRENCES_APPEALED = 'init_ocurrences_appealed';
export const INIT_OCURRENCES_EXTEND = 'init_ocurrences_extend';
export const INIT_OCURRENCES_EXTEND_ACCEPTED = 'init_ocurrences_extend_accepted';
export const INIT_OCURRENCES_EXTEND_REJECTED = 'init_ocurrences_extend_rejected';
