import axios from '../auth/jwt/config';
import axiosAddress from './axiosConfigs/addressConfig';

const getOcurrenceData = async id => {
  const response = await axios.get(`ouvidoria/historico/${id}`);
  return response.data;
};

const getOcurrenceNewFormData = async () => {
  const response = await axios.get(`ouvidoria/auxiliar/nova`);
  return response.data;
};

const getRelatedSubjects = async id => {
  const response = await axios.get(`ouvidoria/auxiliar/assuntotema/${id}`);
  return response.data;
};

const getRelatedServices = async id => {
  const response = await axios.get(`ouvidoria/auxiliar/servicoassunto/${id}`);
  return response.data;
};

const getAddressData = async cep => {
  const response = await axiosAddress.get(`ws/${cep}/json/`);
  if (response.data.erro) {
    return {
      localidade: '',
      logradouro: '',
      complemento: '',
      bairro: '',
    };
  }
  return response.data;
};

const getUserDataByCpf = async cpf => {
  const response = await axios.post(`ouvidoria/solicitante/cpf`, {
    modo: 'CPF',
    code: cpf,
  });
  return response.data;
};

const getUserDataByCnpj = async cnpj => {
  const response = await axios.post(`ouvidoria/solicitante/cpf`, {
    modo: 'CNPJ',
    code: cnpj,
  });
  return response.data;
};

const getDepartmentData = async () => {
  const response = await axios.get('ouvidoria/auxiliar/departamento', {
    params: {
      dep: true,
    },
  });
  return response.data;
};

const getTriageData = async () => {
  const response = await axios.get('ouvidoria/auxiliar/triagem');
  return response.data;
};

const getOcurrenceByProtocol = async formData => {
  const response = await axios.post('ouvidoria/busca', formData);
  return response.data;
};

const getOcurrenceMessages = async id => {
  const response = await axios.get(`ouvidoria/mensagem/listar/${id}`);
  return response.data;
};

const getOcurrenceCommunications = async id => {
  const response = await axios.get(`ouvidoria/comunicados/${id}`);
  return response.data;
};

const sendQuickAnswer = async formData => {
  const response = await axios.post('ouvidoria/acao/responder_triagem', formData);
  return response.data;
};

const sendAttendOcurrence = async formData => {
  const response = await axios.post('ouvidoria/acao/atender', formData);
  return response.data;
};

const sendRequestDepartment = async formData => {
  const response = await axios.post('ouvidoria/mensagem/criar', formData);
  return response.data;
};

const sendCommunication = async (formData, id) => {
  const response = await axios.post(`ouvidoria/comunicados/${id}/new`, formData);
  return response.data;
};

const sendAppeal = async formData => {
  const response = await axios.post('ouvidoria/acao/abrir_recurso', formData);
  return response.data;
};

const canAppeal = async id => {
  const response = await axios.get(`ouvidoria/recurso/verificar/${id}`);
  return response.data;
};

const canCheckAppeal = async id => {
  const response = await axios.get(`ouvidoria/recurso/carregar/${id}`);
  return response.data;
};

const sendAppealVerification = async formData => {
  const response = await axios.post('ouvidoria/acao/mover_recurso', formData);
  return response.data;
};

const sendOmbudsmanRedirect = async formData => {
  const response = await axios.post('ouvidoria/acao/encaminhar_ouvidor', formData);
  return response.data;
};

const sendDepartmentRedirect = async formData => {
  const response = await axios.post('ouvidoria/acao/encaminhar_depto', formData);
  return response.data;
};

// TODO: Confirmar se transferir e encaminhar a departamento é realmente o mesmo endpoint.
const sendDepartmentTransfer = async formData => {
  const response = await axios.post('ouvidoria/acao/encaminhar_depto', formData);
  return response.data;
};

const sendMayorRedirect = async formData => {
  const response = await axios.post('ouvidoria/email/prefeito', formData);
  return response.data;
};

const sendOcurrenceGroup = async formData => {
  const response = await axios.post('ouvidoria/agrupar', formData);
  return response.data;
};

const sendEmailCopy = async formData => {
  const response = await axios.post('ouvidoria/email/copia', formData);
  return response.data;
};

const sendUngroupOcurrence = async id => {
  // TODO: Esse get deveria ser um post. Arrumar no backend.
  const response = await axios.get(`ouvidoria/ocorrencia/desagrupar/${id}`);
  return response.data;
};

const sendCancelOcurrence = async formData => {
  const response = await axios.post('ouvidoria/acao/cancelar', formData);
  return response.data;
};

// TODO: Atualmente, ambos "solucionadas" e "não solucionadas" estão consumindo o mesmo endpoint
const sendFinishOcurrence = async formData => {
  const response = await axios.post('ouvidoria/acao/finalizar', formData);
  return response.data;
};

const sendExtendDeadline = async (id, formData) => {
  const response = await axios.post(`ouvidoria/prorrogar/${id}`, formData);
  return response.data;
};

const sendVerifyExtendDeadline = async (id, formData) => {
  const response = await axios.post(`ouvidoria/atender/prorrogacao/${id}`, formData);
  return response.data;
};

const sendReturnOcurrence = async formData => {
  const response = await axios.post(`ouvidoria/acao/estornar`, formData);
  return response.data;
};

const sendAnalysisOcurrence = async formData => {
  const response = await axios.post(`ouvidoria/acao/vistoria`, formData);
  return response.data;
};

const sendActionFile = async formData => {
  const response = await axios.post('ouvidoria/anexar', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
  });
  return response.data;
};

const createOcurrence = async formData => {
  const response = await axios.post('ouvidoria/new', formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
      'Access-Control-Allow-Origin': '*',
    },
  });
  return response.data;
};

export default {
  getTriageData,
  getDepartmentData,
  sendQuickAnswer,
  sendDepartmentRedirect,
  sendMayorRedirect,
  sendActionFile,
  getOcurrenceData,
  getUserDataByCpf,
  getOcurrenceNewFormData,
  getAddressData,
  getRelatedSubjects,
  getRelatedServices,
  createOcurrence,
  getOcurrenceByProtocol,
  sendOcurrenceGroup,
  sendEmailCopy,
  sendUngroupOcurrence,
  sendCancelOcurrence,
  sendFinishOcurrence,
  sendDepartmentTransfer,
  sendExtendDeadline,
  sendReturnOcurrence,
  sendAnalysisOcurrence,
  sendAttendOcurrence,
  sendRequestDepartment,
  getOcurrenceMessages,
  getUserDataByCnpj,
  sendCommunication,
  getOcurrenceCommunications,
  sendAppeal,
  sendAppealVerification,
  sendOmbudsmanRedirect,
  canAppeal,
  canCheckAppeal,
  sendVerifyExtendDeadline,
};
