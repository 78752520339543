import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import CompletedOcurrences from '../../../../f5/ocurrences/CompletedOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Completed = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <CompletedOcurrences />
    </PageContainer>
  );
};

export default Completed;
