import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { selectOcurrence } from '../../../../redux/actions/f5-actions/ocurrences-actions';
import ocurrencesActionService from '../../../../services/f5-services/ocurrencesActionService';
import { Box, TextField, Button, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ReportIcon from '@material-ui/icons/Report';

import FeedbackAlert from './FeedbackAlert';
import sweetAlert from 'f5/utils/sweetAlert';
import SharedTitle from './SharedTitle';
import SharedDescription from './SharedDescription';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  saveButton: {
    color: 'white',
    backgroundColor: '#21cc37',
    '&:hover': {
      backgroundColor: '#32e33b',
    },
  },
}));

const OpenAppeal = ({ ocurrence }) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [alertOpen, setAlertOpen] = useState(false);
  const [reason, setReason] = useState('');
  const [note, setNote] = useState('');
  const [appealStatus, setAppealStatus] = useState({ can: false, message: '' });

  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    const checkAppeal = async () => {
      setLoading({ status: true, message: 'Checando informações internas...' });
      const res = await ocurrencesActionService.canAppeal(ocurrence.ocorrencia_id);

      setAppealStatus({
        can: res.success,
        message: res.message ? res.message : '',
      });
      setLoading({ status: false, message: '' });
    };

    checkAppeal();
  }, [ocurrence.ocorrencia_id]);

  // TODO: Melhorar validações de formulário
  const validateForm = () => {
    if (!reason) {
      return false;
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validateForm()) {
      setLoading({ status: true, message: 'Abrindo recurso...' });

      const postData = {
        observacao: note,
        descricao: reason,
        ocorrencia_id: ocurrence.ocorrencia_id,
      };

      await ocurrencesActionService.sendAppeal(postData);

      dispatch(selectOcurrence(ocurrence.ocorrencia_id));
      sweetAlert('success', 'Sucesso', 'Recurso aberto com sucesso!', () => {
        setLoading({ status: false, message: '' });
      });
    } else {
      setAlertOpen(true);
    }
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={500}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  if (!appealStatus.can) {
    return (
      <Box display="flex" alignItems="center" justifyContent="center" height={300} width="100%">
        <Box fontSize={20} fontWeight={500}>
          {appealStatus.message}
        </Box>
      </Box>
    );
  }

  return (
    <div className={classes.root}>
      <SharedTitle icon={<ReportIcon />} title="Abrir Recurso" />
      <SharedDescription
        requester={ocurrence.solicitante.nome}
        date={ocurrence.abertura}
        service={ocurrence.servico}
        protocol={ocurrence.protocolo}
      />

      <Box width="100%" mb={4}>
        <TextField
          label="Motivo do recurso"
          required
          multiline
          rows={4}
          variant="outlined"
          fullWidth
          value={reason}
          onChange={e => setReason(e.target.value)}
        />
      </Box>
      <Box width={'100%'}>
        <TextField
          label="Observação"
          fullWidth
          variant="outlined"
          size="small"
          value={note}
          onChange={e => setNote(e.target.value)}
        />
      </Box>
      <Box mt={2} px={4} display="flex" justifyContent="end">
        <Button classes={{ root: classes.saveButton }} onClick={handleSubmit}>
          enviar
        </Button>
      </Box>
      <FeedbackAlert message="Por favor, preencha os campos obrigatórios." open={alertOpen} setOpen={setAlertOpen} />
    </div>
  );
};

export default OpenAppeal;
