import React from 'react';
import ocurrencesActionService from 'services/f5-services/ocurrencesActionService';
import { Box, Button, TextField, InputLabel, MenuItem, Select } from '@material-ui/core';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import FormLabel from '@material-ui/core/FormLabel';
import InputMask from 'react-input-mask';

import SimpleDatePicker from './SimpleDatePicker';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionText: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  checkButton: {
    fontSize: 12,
    color: 'white',
    backgroundColor: '#dbc60d',
    '&:hover': {
      backgroundColor: '#d9c623',
    },
  },
}));

const unformatCep = c => {
  return c.replace(/[^0-9]/g, '');
};

const PersonFields = ({ formFieldReference, createOcurrenceForm, handleFormChange, companyOwner, validateMe }) => {
  const classes = useStyles();

  const handleAddress = async () => {
    const rawCep = unformatCep(createOcurrenceForm.requesterCepValue);
    if (rawCep.length === 8) {
      const addressData = await ocurrencesActionService.getAddressData(rawCep);

      handleFormChange(null, {
        personAddressCity: addressData.localidade,
        personAddressStreet: addressData.logradouro,
        personAddressComplement: addressData.complemento,
        personAddressDistrict: addressData.bairro,
      });
    }
  };

  const handleBirthday = date => {
    handleFormChange(null, {
      personBirthday: date,
    });
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box>
        <Box className={classes.sectionText} fontWeight={700} fontSize={12} mb={1}>
          {companyOwner ? 'RESPONSÁVEL PELA OCORRÊNCIA' : 'IDENTIDADE'}
        </Box>
        <Box display="flex">
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb={8} width="95%">
              <TextField
                error={validateMe('personName').isError}
                helperText={validateMe('personName').message}
                fullWidth
                size="small"
                id="person-name-input"
                label="Nome"
                required
                name="personName"
                value={createOcurrenceForm.personName}
                onChange={handleFormChange}
                inputProps={{ tabIndex: 50 }}
              />
            </Box>
            <Box mb={8} width="95%">
              <InputMask mask="(99)99999-9999" value={createOcurrenceForm.personCellphone} onChange={handleFormChange}>
                <TextField
                  error={validateMe('personCellphone').isError}
                  helperText={validateMe('personCellphone').message}
                  name="personCellphone"
                  fullWidth
                  size="small"
                  id="person-cellphone-input"
                  label="Celular"
                  required={!createOcurrenceForm.personPhone}
                  inputProps={{ tabIndex: 54 }}
                />
              </InputMask>
            </Box>
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <FormControl required fullWidth variant="outlined" error={validateMe('personMaritalStatus').isError}>
                  <InputLabel id="maritalStatus-input-label">Estado Civil</InputLabel>
                  <Select
                    label="Estado Civil"
                    inputProps={{ tabIndex: 56 }}
                    labelId="maritalStatus-input-label"
                    id="person-maritalStatus-input"
                    name="personMaritalStatus"
                    value={createOcurrenceForm.personMaritalStatus.toString()}
                    onChange={handleFormChange}>
                    <MenuItem disabled value="">
                      <em>Selecione uma opção:</em>
                    </MenuItem>
                    {formFieldReference.civil.map(item => (
                      <MenuItem key={item.value} value={item.value.toString()}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validateMe('personMaritalStatus').message}</FormHelperText>
                </FormControl>
              </Box>
              <Box width="100%" ml={4}>
                <FormControl fullWidth required variant="outlined" error={validateMe('personEducation').isError}>
                  <InputLabel id="education-input-label">Escolaridade</InputLabel>
                  <Select
                    label="Escolaridade"
                    inputProps={{ tabIndex: 57 }}
                    labelId="education-input-label"
                    id="person-education-input"
                    name="personEducation"
                    value={createOcurrenceForm.personEducation.toString()}
                    onChange={handleFormChange}>
                    <MenuItem disabled value="">
                      <em>Selecione uma opção:</em>
                    </MenuItem>
                    {formFieldReference.escolaridade.map(item => (
                      <MenuItem key={item.value} value={item.value.toString()}>
                        {item.label}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText>{validateMe('personEducation').message}</FormHelperText>
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <TextField
                  type="email"
                  fullWidth
                  size="small"
                  id="person-email-input"
                  label="E-mail"
                  required
                  name="personEmail"
                  error={validateMe('personEmail').isError}
                  helperText={validateMe('personEmail').message}
                  value={createOcurrenceForm.personEmail}
                  onChange={handleFormChange}
                  inputProps={{ tabIndex: 52 }}
                />
              </Box>
              <Box width="100%" ml={4}>
                <SimpleDatePicker
                  date={createOcurrenceForm.personBirthday}
                  handleDate={handleBirthday}
                  label="Data de nascimento"
                  tabindex="53"
                />
              </Box>
            </Box>
            <Box mb={8} width="95%">
              <InputMask mask="(99)9999-9999" value={createOcurrenceForm.personPhone} onChange={handleFormChange}>
                <TextField
                  error={validateMe('personPhone').isError}
                  helperText={validateMe('personPhone').message}
                  name="personPhone"
                  fullWidth
                  size="small"
                  id="person-phone-input"
                  label="Telefone"
                  required={!createOcurrenceForm.personCellphone}
                  inputProps={{ tabIndex: 55 }}
                />
              </InputMask>
            </Box>
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <FormControl required component="fieldset" error={validateMe('personSex').isError}>
                  <FormLabel component="legend">Sexo</FormLabel>
                  <RadioGroup
                    row
                    aria-label="Sexo"
                    name="personSex"
                    value={createOcurrenceForm.personSex}
                    onChange={handleFormChange}>
                    <FormControlLabel
                      value="Masculino"
                      control={<Radio inputProps={{ tabIndex: 58 }} size="small" />}
                      label={'Masculino'}
                    />
                    <FormControlLabel
                      value="Feminino"
                      control={<Radio inputProps={{ tabIndex: 58 }} size="small" />}
                      label={'Feminino'}
                    />
                  </RadioGroup>
                  <FormHelperText>{validateMe('personSex').message}</FormHelperText>
                </FormControl>
              </Box>
              <Box width="100%" ml={4}>
                <TextField
                  inputProps={{ tabIndex: 60 }}
                  fullWidth
                  size="small"
                  id="person-profession-input"
                  label="Profissão"
                  name="personProfession"
                  error={validateMe('personProfession').isError}
                  helperText={validateMe('personProfession').message}
                  value={createOcurrenceForm.personProfession}
                  onChange={handleFormChange}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box mb={4}>
        <Box className={classes.sectionText} fontWeight={700} fontSize={12} mb={1}>
          {companyOwner ? 'ENDEREÇO DO RESPONSÁVEL' : 'ENDEREÇO'}
        </Box>
        <Box display="flex">
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb={8} display="flex" alignItems="center">
              <InputMask mask="99.999-999" value={createOcurrenceForm.requesterCepValue} onChange={handleFormChange}>
                <TextField
                  inputProps={{ tabIndex: 61 }}
                  name="requesterCepValue"
                  error={validateMe('requesterCepValue').isError}
                  helperText={validateMe('requesterCepValue').message}
                  fullWidth
                  size="small"
                  id="cep-search-input"
                  label="CEP"
                  required
                />
              </InputMask>
              <Box ml={2} display="flex" alignItems="center" width="50%">
                <Button
                  disabled={unformatCep(createOcurrenceForm.requesterCepValue).length !== 8}
                  variant="contained"
                  classes={{ root: classes.checkButton }}
                  size="small"
                  onClick={handleAddress}>
                  Buscar por CEP
                </Button>
              </Box>
            </Box>
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <TextField
                  inputProps={{ tabIndex: 63 }}
                  fullWidth
                  size="small"
                  id="person-number-input"
                  label="Número"
                  required
                  name="personAddressNumber"
                  error={validateMe('personAddressNumber').isError}
                  helperText={validateMe('personAddressNumber').message}
                  value={createOcurrenceForm.personAddressNumber}
                  onChange={handleFormChange}
                />
              </Box>
              <Box width="100%" ml={4}>
                <TextField
                  inputProps={{ tabIndex: 64 }}
                  fullWidth
                  size="small"
                  id="person-complement-input"
                  label="Complemento"
                  name="personAddressComplement"
                  error={validateMe('personAddressComplement').isError}
                  helperText={validateMe('personAddressComplement').message}
                  value={createOcurrenceForm.personAddressComplement}
                  onChange={handleFormChange}
                />
              </Box>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="column">
            <Box width="95%" mb={8}>
              <TextField
                inputProps={{ tabIndex: 62 }}
                fullWidth
                size="small"
                id="person-street-input"
                label="Rua"
                required
                name="personAddressStreet"
                error={validateMe('personAddressStreet').isError}
                helperText={validateMe('personAddressStreet').message}
                value={createOcurrenceForm.personAddressStreet}
                onChange={handleFormChange}
              />
            </Box>
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <TextField
                  inputProps={{ tabIndex: 65 }}
                  fullWidth
                  size="small"
                  id="person-district-input"
                  label="Bairro"
                  required
                  name="personAddressDistrict"
                  error={validateMe('personAddressDistrict').isError}
                  helperText={validateMe('personAddressDistrict').message}
                  value={createOcurrenceForm.personAddressDistrict}
                  onChange={handleFormChange}
                />
              </Box>
              <Box width="100%" ml={4}>
                <TextField
                  inputProps={{ tabIndex: 66 }}
                  fullWidth
                  size="small"
                  id="person-city-input"
                  label="Cidade"
                  name="personAddressCity"
                  error={validateMe('personAddressCity').isError}
                  helperText={validateMe('personAddressCity').message}
                  value={createOcurrenceForm.personAddressCity}
                  onChange={handleFormChange}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PersonFields;
