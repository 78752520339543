import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  CircularProgress,
  IconButton,
} from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

import generalService from '../../../../services/f5-services/generalService';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  buttonOutlined: {
    color: 'green',
    border: '2px solid green',
    '&:hover': {
      backgroundColor: 'rgba(0, 255, 0, 0.15)',
    },
  },
  buttonAdd: {
    color: 'white',
    backgroundColor: '#0fa31b',
    '&:hover': {
      backgroundColor: '#1db82a',
    },
  },
}));

const RedirectOcurrenceRelatedDialog = ({
  departmentDialogOpen,
  setDepartmentDialogOpen,
  departments,
  setRelatedDepartments,
  relatedDepartments,
}) => {
  const [loading, setLoading] = useState({ message: '', status: false });
  const [servicesList, setServicesList] = useState([]);

  const [localDepartment, setLocalDepartment] = useState({});
  const [localService, setLocalService] = useState({});

  const headers = ['Departamento', 'Serviço', 'Ação'];
  const rows = () =>
    relatedDepartments.map(item => {
      return { department: item.department, service: item.service };
    });

  const classes = useStyles();

  useEffect(() => {
    setLoading({ message: 'Carregando dados...', status: true });
    const getData = async () => {
      const services = await generalService.getServices();
      setServicesList(services.items);
    };

    getData();
    setLoading({ message: '', status: false });
  }, [setLoading, setServicesList]);

  const handleDepartmentChange = (e, newValue) => {
    setLocalDepartment(newValue ? newValue : {});
  };

  const handleServicesChange = (e, newValue) => {
    setLocalService(newValue ? newValue : {});
  };

  const handleAdd = () => {
    if (
      localDepartment &&
      localService &&
      relatedDepartments.filter(item => item.department === localDepartment && item.service === localService).length <= 0
    ) {
      setRelatedDepartments([...relatedDepartments, { department: localDepartment, service: localService }]);
    }
  };

  const handleDelete = (d, s) => {
    setRelatedDepartments(relatedDepartments.filter(item => !(item.department.id === d.id && item.service.id === s.id)));
  };

  if (loading.status) {
    return (
      <Box
        className={classes.root}
        height={250}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center">
        <CircularProgress />
        <Box mt={4} fontWeight={700}>
          {loading.message}
        </Box>
      </Box>
    );
  }

  return (
    <Dialog
      open={departmentDialogOpen}
      onClose={() => setDepartmentDialogOpen(false)}
      fullWidth
      maxWidth={'md'}
      aria-labelledby="dialogo de escolha de departamentos"
      aria-describedby="dialogo de escolha de departamentos">
      <DialogTitle>{'Selecione os departamento e serviços relacionados: '}</DialogTitle>
      <DialogContent>
        <Box display="flex" flexDirection="column">
          <Box display="flex">
            <Box flex={1} mr={2}>
              <Autocomplete
                clearText="Limpar"
                noOptionsText="Nenhuma opção"
                openText="Abrir"
                size="small"
                options={departments}
                getOptionLabel={option => option.nome}
                renderInput={params => <TextField {...params} label="Lista de departamentos" variant="outlined" />}
                onChange={handleDepartmentChange}
              />
            </Box>
            <Box flex={1} mr={2}>
              <Autocomplete
                clearText="Limpar"
                noOptionsText="Nenhuma opção"
                openText="Abrir"
                size="small"
                options={servicesList}
                getOptionLabel={option => option.nome}
                renderInput={params => <TextField {...params} label="Lista de serviços" variant="outlined" />}
                onChange={handleServicesChange}
              />
            </Box>
            <Button
              startIcon={<AddIcon />}
              classes={{ root: classes.buttonAdd }}
              onClick={handleAdd}
              children={
                <Box fontWeight={700} fontSize={12} fontStyle="uppercase">
                  Adicionar
                </Box>
              }
            />
          </Box>
          <Box my={4}>
            <TableContainer component={Paper}>
              <Table size="small" aria-label="histórico da ocorrência">
                <TableHead>
                  <TableRow>
                    {headers.map((item, index) => {
                      return (
                        <TableCell key={index}>
                          <Box fontWeight={700}>{item}</Box>
                        </TableCell>
                      );
                    })}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {rows().map((item, index) => {
                    return (
                      <TableRow key={index}>
                        <TableCell>{item.department.nome}</TableCell>
                        <TableCell>{item.service.nome}</TableCell>
                        <TableCell>
                          <IconButton
                            size="small"
                            aria-label="excluir"
                            onClick={() => handleDelete(item.department, item.service)}>
                            <DeleteIcon fontSize="small" />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          classes={{ outlined: classes.buttonOutlined }}
          variant="outlined"
          onClick={() => setDepartmentDialogOpen(false)}
          autoFocus>
          Salvar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default RedirectOcurrenceRelatedDialog;
