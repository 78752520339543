import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import OverdueOcurrences from '../../../../f5/ocurrences/OverdueOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Overdue = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <OverdueOcurrences />
    </PageContainer>
  );
};

export default Overdue;
