import React, { useState } from 'react';
import { Box, Paper } from '@material-ui/core';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import WarningIcon from '@material-ui/icons/Warning';
import ThumbDownIcon from '@material-ui/icons/ThumbDown';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    width: 'fit-content',
    margin: '0 auto',
    '& .count': {
      zIndex: 2,
      display: ({ count }) => (count > 0 ? 'flex' : 'none'),
      justifyContent: 'center',
      alignItems: 'center',
      width: '25px',
      height: '25px',
      padding: '0.25rem',
      position: 'absolute',
      top: '-7px',
      right: '7px',
      border: '1px solid rgba(150, 127, 14, 0.25)',
      borderRadius: '50%',
      backgroundColor: 'rgba(230, 230, 16, 1)',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },
    '& .count > span': {
      fontWeight: '700',
      fontSize: '11px',
      textAlign: 'center',
      color: 'black',
    },
  },
  customCardRoot: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '0.5rem',
    overflow: 'hidden',
    height: '200px',
    width: '400px',
    margin: '1rem',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  customCardHeader: {
    width: '100%',
    backgroundColor: ({ color }) => color,
    padding: '0.5rem 2rem',
    height: '20%',
    fontSize: '0.95rem',
    fontWeight: 900,
    textTransform: 'uppercase',
    color: 'white',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  customCardData: {
    width: '100%',
    height: '100%',
    padding: '0.5rem 1rem',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': {
      margin: '0.25rem 0',
    },
  },
  between: {
    width: '100%',
    justifyContent: 'space-between',
  },
}));

const CustomCard = ({ color, title, count, handleClick, data }) => {
  const [elevation, setElevation] = useState(2);
  const classes = useStyles({ color, count });

  return (
    <Box className={classes.root} onClick={handleClick}>
      <Paper
        className={classes.customCardRoot}
        elevation={elevation}
        onMouseOver={() => setElevation(8)}
        onMouseOut={() => setElevation(2)}>
        <Box className={classes.customCardHeader}>
          <Box>{title}</Box>
        </Box>
        <Box className={classes.customCardData}>
          <Box display="flex" className={classes.between}>
            <Box display="flex" alignItems="center">
              <HelpOutlineIcon />
              <Box fontWeight={500} ml={2}>
                Pendente:
              </Box>
              <Box fontWeight={700} ml={2}>
                {data.pending ? data.pending : 0}
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <ThumbUpIcon />
              <Box fontWeight={500} ml={2}>
                Dentro do prazo:
              </Box>
              <Box fontWeight={700} ml={2}>
                {data.ontime ? data.ontime : 0}
              </Box>
            </Box>
          </Box>
          <Box display="flex" className={classes.between}>
            <Box display="flex" alignItems="center">
              <WarningIcon />
              <Box fontWeight={500} ml={2}>
                A vencer:
              </Box>
              <Box fontWeight={700} ml={2}>
                {data.due ? data.due : 0}
              </Box>
            </Box>
            <Box display="flex" alignItems="center">
              <ThumbDownIcon />
              <Box fontWeight={500} ml={2}>
                Vencidas:
              </Box>
              <Box fontWeight={700} ml={2}>
                {data.overdue ? data.overdue : 0}
              </Box>
            </Box>
          </Box>
          <Box display="flex">
            <Box display="flex" alignItems="center">
              <CheckBoxIcon />
              <Box fontWeight={500} ml={2}>
                Respondidas:
              </Box>
              <Box fontWeight={700} ml={2}>
                {data.solved ? data.solved : 0}
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      {count > 0 && (
        <div className="count">
          <span>{count > 9 ? '9+' : count}</span>
        </div>
      )}
    </Box>
  );
};

export default CustomCard;
