import React from 'react';
import IntlMessages from '../../../utils/IntlMessages';
import {
  PostAdd,
  Assignment,
  Assessment,
  Report,
  ArrowForward,
  FindInPage,
  Create,
  Settings,
  Person,
  SupervisedUserCircle,
  AmpStories,
  Schedule,
  Category,
} from '@material-ui/icons';

const ocurrencesMenus = [
  {
    name: 'Recebidas',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/recebidas',
    access: ['admin', 'triagem', 'ouvidor'],
  },
  {
    name: 'Pendentes',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/pendentes',
    access: ['admin', 'triagem', 'ouvidor', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
  },
  {
    name: 'Em andamento',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/andamento',
    access: ['admin', 'triagem', 'ouvidor'],
  },
  {
    // Diferente nome é usado para usuário departamento
    name: 'Em análise',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/andamento',
    access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
  },
  {
    name: 'A vencer',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/vencer',
    access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
  },
  {
    name: 'Vencidas',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/vencidas',
    access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
  },
  {
    name: 'Respondidas',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/respondidas',
    access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
  },
  {
    name: 'Finalizadas',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/finalizadas',
    access: ['admin', 'triagem', 'ouvidor'],
  },
  {
    name: 'Recorrentes',
    type: 'item',
    icon: <ArrowForward />,
    link: '/ocorrencias/recorrentes',
    access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento', 'triagem', 'ouvidor'],
  },
];

const configMenus = [
  {
    name: 'Categorias',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/categoria',
    access: ['admin', 'ouv_master'],
  },
  {
    name: 'Setores',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/setor',
    access: ['admin', 'ouv_master'],
  },
  {
    name: 'Entradas',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/entrada',
    access: ['admin', 'ouv_master'],
  },
  {
    name: 'Gravidades',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/gravidade',
    access: ['admin', 'ouv_master'],
  },
  {
    name: 'Temas',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/tema',
    access: ['admin', 'ouv_master'],
  },
  {
    name: 'Assuntos',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/assunto',
    access: ['admin', 'ouv_master'],
  },
  {
    name: 'Serviços',
    type: 'item',
    icon: <Category />,
    link: '/configuracoes/servico',
    access: ['admin', 'ouv_master'],
  },
];

const ocurrencesMenusFiltered = access => {
  return ocurrencesMenus.filter(item => item.access.includes(access));
};

// const secretaryMenus = [
//   {
//     name: 'Triagem',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/triagem',
//   },
//   {
//     name: 'Ouvidoria',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/ouvidoria',
//   },
//   {
//     name: 'Finanças',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/financas',
//   },
//   {
//     name: 'Procuradoria Geral',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/procuradoria',
//   },
//   {
//     name: 'Administração',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/administracao',
//   },
//   {
//     name: 'Assistência Social',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/social',
//   },
//   {
//     name: 'Esporte Cultura Recreação',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/esporte',
//   },
//   {
//     name: 'Educação',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/educacao',
//   },
//   {
//     name: 'Obras',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/obras',
//   },
//   {
//     name: 'Saúde',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/saude',
//   },
//   {
//     name: 'Planejamento Urbano',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/urbano',
//   },
//   {
//     name: 'Trabalho e Indústria',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/trabalho',
//   },
//   {
//     name: 'Secretaria Pública e Trânsito',
//     type: 'item',
//     icon: <ArrowForward />,
//     link: '/secretarias/publica',
//   },
// ];

const generateSidebarNavs = access => {
  return [
    {
      name: 'Informações',
      type: 'section',
      children: [
        {
          name: 'Geral',
          type: 'item',
          icon: <Assignment />,
          link: '/ocorrencias',
        },
        {
          name: 'Nova Ocorrência',
          type: 'item',
          icon: <Create />,
          link: '/nova',
        },
        {
          name: 'Pesquisar',
          type: 'item',
          icon: <FindInPage />,
          link: '/pesquisar',
        },
        // {
        //   name: 'Secretarias',
        //   icon: <Assessment />,
        //   type: 'collapse',
        //   children: secretaryMenus,
        // },
        {
          name: 'Ocorrências',
          icon: <Report />,
          type: 'collapse',
          children: ocurrencesMenusFiltered(access),
        },
      ],
    },
    {
      name: 'Geral',
      type: 'section',
      children: [
        {
          name: 'Usuário',
          type: 'item',
          icon: <Person />,
          link: '/conta',
        },
      ],
    },
  ];
};

const generateSidebarNavsDepartment = (access, office) => {
  if (!office) {
    return [
      {
        name: 'Setores',
        type: 'item',
        icon: <AmpStories />,
        link: '/setores',
      },
    ];
  }

  return [
    {
      name: 'Setores',
      type: 'item',
      icon: <AmpStories />,
      link: '/setores',
    },
    {
      name: 'Informações',
      type: 'section',
      children: [
        {
          name: 'Geral',
          type: 'item',
          icon: <Assignment />,
          link: '/ocorrencias',
        },
        // {
        //   name: 'Nova Ocorrência',
        //   type: 'item',
        //   icon: <Create />,
        //   link: '/nova',
        // },
        {
          name: 'Pesquisar',
          type: 'item',
          icon: <FindInPage />,
          link: '/pesquisar',
        },
        // {
        //   name: 'Secretarias',
        //   icon: <Assessment />,
        //   type: 'collapse',
        //   children: secretaryMenus,
        // },
        {
          name: 'Ocorrências',
          icon: <Report />,
          type: 'collapse',
          children: ocurrencesMenusFiltered(access),
        },
      ],
    },
    {
      name: 'Geral',
      type: 'section',
      children: [
        {
          name: 'Usuário',
          type: 'item',
          icon: <Person />,
          link: '/conta',
        },
      ],
    },
  ];
};

const generateSidebarNavsRegistration = () => {
  return [
    {
      name: 'Informações',
      type: 'section',
      children: [
        {
          name: 'Nova Ocorrência',
          type: 'item',
          icon: <Create />,
          link: '/nova',
        },
      ],
    },
    {
      name: 'Geral',
      type: 'section',
      children: [
        {
          name: 'Usuário',
          type: 'item',
          icon: <Person />,
          link: '/conta',
        },
      ],
    },
  ];
};

const generateSidebarNavsMaster = () => {
  return [
    {
      name: 'Informações',
      type: 'section',
      children: [
        {
          name: 'Geral',
          type: 'item',
          icon: <Assignment />,
          link: '/ocorrencias',
        },
        {
          name: 'Prorrogações',
          type: 'item',
          icon: <Schedule />,
          link: '/ocorrencias/prorrogacoes',
        },
      ],
    },
    {
      name: 'Geral',
      type: 'section',
      children: [
        {
          name: 'Usuário',
          type: 'item',
          icon: <Person />,
          link: '/conta',
        },
        {
          name: 'Configurações',
          type: 'collapse',
          icon: <Settings />,
          children: configMenus,
        },
      ],
    },
  ];
};

export const getSidebarNavs = (access, office, isDepartment) => {
  if (access === 'ouv_cadastro') {
    return generateSidebarNavsRegistration();
  }

  if (access === 'ouv_master') {
    return generateSidebarNavsMaster();
  }

  const filteredsideBarNavs = isDepartment
    ? generateSidebarNavsDepartment(access, office)
    : generateSidebarNavs(access, office);

  return filteredsideBarNavs;
};

export const horizontalDefaultNavs = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/ocorrencias',
      },
    ],
  },
];

export const minimalHorizontalMenus = [
  {
    name: <IntlMessages id={'sidebar.main'} />,
    type: 'collapse',
    children: [
      {
        name: <IntlMessages id={'pages.samplePage'} />,
        type: 'item',
        icon: <PostAdd />,
        link: '/ocorrencias',
      },
    ],
  },
];
