import React from 'react';
import {
  Paper,
  Box,
  DialogTitle,
  Dialog,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import TimelineIcon from '@material-ui/icons/Timeline';

const formatZero = n => {
  return n < 10 ? `0${n}` : n;
};

const formatDate = date => {
  const [month, day, year] = [date.getMonth(), date.getDate(), date.getFullYear()];
  const [hour, minutes, seconds] = [date.getHours(), date.getMinutes(), date.getSeconds()];
  const formattedDate = `${formatZero(day)}/${formatZero(month)}/${year} ${formatZero(hour)}:${formatZero(
    minutes,
  )}:${formatZero(seconds)}`;
  return formattedDate;
};

const HistoryInfo = ({ open, setOpen, movements }) => {
  const headers = ['Data/Hora', 'Ação'];
  const rows = movements.map(item => {
    return { action: item.message, date: formatDate(new Date(item.inicio)) };
  });

  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth={'md'}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <TimelineIcon />
          <Box ml={2}>Histórico de Atividades</Box>
        </Box>
      </DialogTitle>
      <Box px={8} mb={4}>
        <TableContainer component={Paper}>
          <Table aria-label="histórico da ocorrência">
            <TableHead>
              <TableRow>
                {headers.map((item, index) => {
                  return (
                    <TableCell key={index}>
                      <Box fontWeight={700}>{item}</Box>
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((item, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>{item.date}</TableCell>
                    <TableCell>{item.action}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Dialog>
  );
};

export default HistoryInfo;
