import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeOcurrencesExtend,
  initializeOcurrencesExtendAccepted,
  initializeOcurrencesExtendRejected,
} from '../../../redux/actions/f5-actions/ocurrences-list-init';
import { Grid } from '@material-ui/core';
import GridContainer from '../../../@jumbo/components/GridContainer';

import SimpleDataCard from '../../ocurrences/SharedComponents/SimpleDataCard';
import OcurrencesDataRender from '../../ocurrences/SharedComponents/OcurrencesDataRender';

import ScheduleIcon from '@material-ui/icons/Schedule';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import AlarmOffIcon from '@material-ui/icons/AlarmOff';

const ExtendOcurrences = ({ initView }) => {
  const [currentList, setCurrentList] = useState(
    initView === 'extend_accepted' || initView === 'extend_rejected' ? initView : 'extend_pending',
  );
  const extendData = useSelector(({ ocurrences_data }) => ocurrences_data.extend.caixa);
  const extendAcceptedData = useSelector(({ ocurrences_data }) => ocurrences_data.extend_accepted.caixa);
  const extendRejectedData = useSelector(({ ocurrences_data }) => ocurrences_data.extend_rejected.caixa);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesExtend());
    dispatch(initializeOcurrencesExtendAccepted());
    dispatch(initializeOcurrencesExtendRejected());
  }, [dispatch]);

  const ocurrencesData = {
    extend_pending: {
      icon: <ScheduleIcon fontSize="large" />,
      label: 'Prorrogações Pendentes',
      colors: ['#2f6bcc', '#042d80'],
      data: extendData,
    },
    extend_accepted: {
      icon: <AlarmOnIcon fontSize="large" />,
      label: 'Prorrogações Aceitas',
      colors: ['#3dd461', '#099c2c'],
      data: extendAcceptedData,
    },
    extend_rejected: {
      icon: <AlarmOffIcon fontSize="large" />,
      label: 'Prorrogações Rejeitadas',
      colors: ['#b3597b', '#7a1d41'],
      data: extendRejectedData,
    },
  };

  return (
    <GridContainer>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.extend_pending.label}
          icon={ocurrencesData.extend_pending.icon}
          colors={ocurrencesData.extend_pending.colors}
          value={ocurrencesData.extend_pending.data ? ocurrencesData.extend_pending.data.length : 0}
          setList={() => setCurrentList('extend_pending')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.extend_accepted.label}
          icon={ocurrencesData.extend_accepted.icon}
          colors={ocurrencesData.extend_accepted.colors}
          value={ocurrencesData.extend_accepted.data ? ocurrencesData.extend_accepted.data.length : 0}
          setList={() => setCurrentList('extend_accepted')}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={3}>
        <SimpleDataCard
          label={ocurrencesData.extend_rejected.label}
          icon={ocurrencesData.extend_rejected.icon}
          colors={ocurrencesData.extend_rejected.colors}
          value={ocurrencesData.extend_rejected.data ? ocurrencesData.extend_rejected.data.length : 0}
          setList={() => setCurrentList('extend_rejected')}
        />
      </Grid>

      <Grid item xs={12}>
        <OcurrencesDataRender
          bgcolor={ocurrencesData[currentList].colors[0]}
          data={ocurrencesData[currentList].data}
          label={ocurrencesData[currentList].label}
          icon={ocurrencesData[currentList].icon}
        />
      </Grid>
    </GridContainer>
  );
};

export default ExtendOcurrences;
