import {
  RESET_NEW_OCURRENCE,
  CHECK_CPF_DATA,
  CHECK_CNPJ_DATA,
  INIT_CREATE_FORM_DATA,
} from '../../actions/f5-actions/action-types';

const INIT_STATE = {
  status: 'clean',
  fieldReference: null,
  cpfIdentity: {},
  cnpjIdentity: {},
  validation: { requester: false, ocurrence: false },
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case RESET_NEW_OCURRENCE: {
      return INIT_STATE;
    }
    case INIT_CREATE_FORM_DATA: {
      return { ...state, fieldReference: action.payload };
    }
    case CHECK_CPF_DATA: {
      return {
        ...state,
        status: 'checkedCpf',
        cpfIdentity: action.payload ? action.payload : {},
      };
    }
    case CHECK_CNPJ_DATA: {
      return {
        ...state,
        status: 'checkedCnpj',
        cnpjIdentity: action.payload ? action.payload : {},
      };
    }
    default:
      return state;
  }
};
