import axios from '../auth/jwt/config';

const getConfigParameter = async type => {
  const response = await axios.get(`ouvidoria/auxiliar/${type}`);
  return response.data;
};

const getConfigParameterId = async (id, type) => {
  const response = await axios.get(`ouvidoria/auxiliar/${type}/${id}`);
  return response.data;
};

const deleteConfigParameter = async (id, type) => {
  const response = await axios.post(`ouvidoria/crud/${type}/${id}/delete`);
  return response.data;
};

const addConfigParameter = async (type, data) => {
  const response = await axios.post(`ouvidoria/crud/${type}/`, data);
  return response.data;
};

const editConfigParameter = async (type, data) => {
  const response = await axios.post(`ouvidoria/crud/${type}/${data.id}`, data);
  return response.data;
};

export default {
  getConfigParameter,
  getConfigParameterId,
  deleteConfigParameter,
  addConfigParameter,
  editConfigParameter,
};
