import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import { GoogleMap, Marker } from '@react-google-maps/api';
import LocationOnIcon from '@material-ui/icons/LocationOn';

const containerStyle = {
  width: '100%',
  minHeight: '250px',
};

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
}));

const LocationCard = ({ lat, lng, address }) => {
  const classes = useStyles();

  const center = {
    lat,
    lng,
  };

  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <LocationOnIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Localização
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box display="flex" mb={1}>
          <Box mr={2} fontWeight={700}>
            Endereço:
          </Box>
          <Box>{address}</Box>
        </Box>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={10}>
          <Marker position={center} />
        </GoogleMap>
      </Box>
    </Paper>
  );
};

export default LocationCard;
