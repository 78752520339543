import React from 'react';
import { Box, Paper, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CreateIcon from '@material-ui/icons/Create';
import AddIcon from '@material-ui/icons/Add';
import SimpleLoader from 'f5/shared/SimpleLoader';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(2),
  },
  addButton: {
    color: 'white',
    backgroundColor: '#0ddb48',
    '&:hover': {
      backgroundColor: '#26ed5e',
    },
  },
  editButton: {
    color: 'white',
    backgroundColor: 'rgb(36, 20, 250)',
    '&:hover': {
      backgroundColor: 'rgb(66, 33, 250)',
    },
  },
}));

const NewOrUpdate = ({
  addTitle,
  editTitle,
  newOrUpdate,
  setNewOrUpdate,
  loading,
  handleCreate,
  handleEdit,
  validator,
  children,
}) => {
  const classes = useStyles();

  if (loading) {
    return <SimpleLoader />;
  }

  const handleSubmitCreate = () => {
    const validatedData = validator();
    if (validatedData) {
      handleCreate(validatedData);
    }
  };

  const handleSubmitEdit = () => {
    const validatedData = validator();
    if (validatedData) {
      handleEdit(validatedData);
    }
  };

  return (
    <Paper classes={{ root: classes.root }}>
      <Button startIcon={<ArrowBackIcon />} onClick={() => setNewOrUpdate({ mode: 'none' })}>
        Voltar
      </Button>
      <Box mt={4} px={5} display="flex" flexDirection="column">
        <Box mb={2} fontWeight={500}>
          {newOrUpdate.mode === 'edit' ? editTitle : addTitle}
        </Box>
        <Box my={3}>{children}</Box>
        <Box mt={3} display="flex" justifyContent="end">
          {newOrUpdate.mode === 'edit' ? (
            <Button startIcon={<CreateIcon />} onClick={handleSubmitEdit} className={classes.editButton}>
              Editar
            </Button>
          ) : (
            <Button startIcon={<AddIcon />} onClick={handleSubmitCreate} className={classes.addButton}>
              Adicionar
            </Button>
          )}
        </Box>
      </Box>
    </Paper>
  );
};

export default NewOrUpdate;
