import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesDaily } from '../../../redux/actions/f5-actions/ocurrences-daily';
import { Box, Paper, CircularProgress } from '@material-ui/core';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { Area, AreaChart, CartesianGrid, ResponsiveContainer, Tooltip, Legend, XAxis, YAxis } from 'recharts';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
  },
}));

const GeneralDailyGraph = ({ boxHeight }) => {
  const dailyData = useSelector(({ ocurrences_daily }) => ocurrences_daily.data);

  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(initializeOcurrencesDaily());
  }, [dispatch]);

  if (!dailyData) {
    return (
      <Paper className={classes.root}>
        <Box
          p={2}
          minHeight={boxHeight - 50}
          display="flex"
          flexDirection="column"
          alignItems="center"
          justifyContent="center">
          <CircularProgress />
          <Box mt={2} fontWeight={700} textAlign="center">
            Carregando dados...
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      <Box p={2} height={boxHeight}>
        <Box mb={3} display="flex" alignItems="center">
          <ShowChartIcon />
          <Box ml={1} fontWeight={700}>
            Fluxo De Ocorrências
          </Box>
        </Box>
        <Box>
          <ResponsiveContainer width="100%" height={boxHeight - 50}>
            <AreaChart data={dailyData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
              <XAxis dataKey="dia" />
              <YAxis />
              <CartesianGrid strokeDasharray="3 3" />
              <Tooltip labelStyle={{ color: 'black' }} itemStyle={{ color: 'black' }} cursor={false} />
              <Legend />
              <defs>
                <linearGradient id="colorCurrent" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#f7aa1b" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#f7aa1b" stopOpacity={0} />
                </linearGradient>
                <linearGradient id="colorLast" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#a19b91" stopOpacity={0.8} />
                  <stop offset="95%" stopColor="#a19b91" stopOpacity={0} />
                </linearGradient>
              </defs>

              <Area
                type="monotone"
                dataKey="anterior"
                name="Mês Passado"
                stroke="#a19b91"
                fill="url(#colorLast)"
                fillOpacity={1}
              />
              <Area
                type="monotone"
                dataKey="atual"
                name="Mês Atual"
                stroke="#f7aa1b"
                fill="url(#colorCurrent)"
                fillOpacity={1}
              />
            </AreaChart>
          </ResponsiveContainer>
        </Box>
      </Box>
    </Paper>
  );
};

export default GeneralDailyGraph;
