import React from 'react';
import { Button } from '@material-ui/core';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonRoot: {
    justifyContent: 'flex-start',
    fontSize: 11,
    textTransform: 'none',
    padding: '0.75rem 0.25rem 0.75rem 0.5rem',
    backgroundColor: ({ bgColor }) => bgColor,
    color: ({ color }) => color,
    '&:hover': {
      backgroundColor: ({ bgColorHover }) => bgColorHover,
    },
  },
}));

const ActionsButton = ({ pos, icon, handleClick, text, colors }) => {
  const classes = useStyles({
    color: pos === 0 ? 'black' : colors ? colors.textColor : 'white',
    bgColor: pos === 0 ? 'rgba(0, 237, 75, 1)' : colors ? colors.highlight : 'rgba(2, 92, 209, 1)',
    bgColorHover: pos === 0 ? 'rgba(71, 252, 95, 1)' : colors ? colors.highlightHover : 'rgba(34, 142, 224, 1)',
  });

  return (
    <Button variant="outlined" classes={{ root: classes.buttonRoot }} startIcon={icon} onClick={handleClick}>
      {text}
    </Button>
  );
};

export default ActionsButton;
