import * as yup from 'yup';
import { isValidCPF, isValidCNPJ } from '../../../utils/generalUtils';

export const schemaRequesterCPF = yup.object().shape(
  {
    requesterCpfValue: yup
      .string()
      .test('is-cpf-valid', 'CPF is invalid.', (value, _c) => isValidCPF(value))
      .required(),
    personName: yup.string().required(),
    personEmail: yup
      .string()
      .email()
      .required(),
    personPhone: yup.string().when('personCellphone', {
      is: personCellphone => !personCellphone || personCellphone.length === 0,
      then: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/)
        .required(),
      otherwise: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/, { excludeEmptyString: true }),
    }),
    personCellphone: yup.string().when('personPhone', {
      is: personPhone => !personPhone || personPhone.length === 0,
      then: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/)
        .required(),
      otherwise: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/, { excludeEmptyString: true }),
    }),
    personMaritalStatus: yup.string().required(),
    personEducation: yup.string().required(),
    personSex: yup
      .string()
      .matches(/(masculino|feminino)/i)
      .required(),
    personBirthday: yup.date(),
    personProfession: yup.string(),
    requesterCepValue: yup
      .string()
      .matches(/^[0-9]{2}.[0-9]{3}-[\d]{3}$/)
      .required(),
    personAddressNumber: yup
      .string()
      .matches(/^[0-9]+$/)
      .required(),
    personAddressComplement: yup.string(),
    personAddressStreet: yup.string().required(),
    personAddressDistrict: yup.string().required(),
    personAddressCity: yup.string().required(),
  },
  [['personPhone', 'personCellphone']],
);

export const schemaRequesterCNPJ = yup.object().shape(
  {
    personName: yup.string().required(),
    personEmail: yup
      .string()
      .email()
      .required(),
    personPhone: yup.string().when('personCellphone', {
      is: personCellphone => !personCellphone || personCellphone.length === 0,
      then: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/)
        .required(),
      otherwise: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/, { excludeEmptyString: true }),
    }),
    personCellphone: yup.string().when('personPhone', {
      is: personPhone => !personPhone || personPhone.length === 0,
      then: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/)
        .required(),
      otherwise: yup
        .string()
        .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/, { excludeEmptyString: true }),
    }),
    personMaritalStatus: yup.string().required(),
    personEducation: yup.string().required(),
    personSex: yup
      .string()
      .matches(/(masculino|feminino)/i)
      .required(),
    personBirthday: yup.date(),
    personProfession: yup.string(),
    requesterCepValue: yup
      .string()
      .matches(/^[0-9]{2}.[0-9]{3}-[\d]{3}$/)
      .required(),
    personAddressNumber: yup
      .string()
      .matches(/^[0-9]+$/)
      .required(),
    personAddressComplement: yup.string(),
    personAddressStreet: yup.string().required(),
    personAddressDistrict: yup.string().required(),
    personAddressCity: yup.string().required(),

    requesterCnpjValue: yup
      .string()
      .test('is-cnpj-valid', 'CNPJ is invalid.', (value, _c) => isValidCNPJ(value))
      .required(),
    companyCep: yup
      .string()
      .matches(/^[0-9]{2}.[0-9]{3}-[\d]{3}$/)
      .required(),
    companyName: yup.string().required(),
    companyEmail: yup
      .string()
      .email()
      .required(),
    companyRegister: yup.string().required(),
    companyAddressCity: yup.string(),
    companyAddressComplement: yup.string(),
    companyPhone: yup
      .string()
      .matches(/(?:^\([0]?[1-9]{2}\)|^[0]?[1-9]{2}[.-\s]?)[9]?[1-9]\d{3}[.-\s]?\d{4}$/)
      .required(),
    companyAddressNumber: yup
      .string()
      .matches(/^[0-9]+$/)
      .required(),
    companyAddressStreet: yup.string().required(),
    companyAddressDistrict: yup.string().required(),
  },
  [['personPhone', 'personCellphone']],
);

export const schemaOthers = yup.object().shape({
  personName: yup.string().required(),
});

export const schemaData = yup.object().shape({
  ocurrenceDataCategory: yup.string().required(),
  ocurrenceDataGravity: yup.string().required(),
  ocurrenceDataSector: yup.string().required(),
  ocurrenceDataEntry: yup.string().required(),
  ocurrenceDataInputFile: yup.string(),
  ocurrenceDataInputFileData: yup.string(),
  ocurrenceDataTheme: yup.string().required(),
  ocurrenceDataSubject: yup.string().required(),
  ocurrenceDataService: yup.string().required(),
  ocurrenceDataDescription: yup.string().required(),
  ocurrenceDataCep: yup
    .string()
    .matches(/^[0-9]{2}.[0-9]{3}-[\d]{3}$/)
    .required(),
  ocurrenceDataAddressNumber: yup
    .string()
    .matches(/^[0-9]+$/)
    .required(),
  ocurrenceDataAddressComplement: yup.string(),
  ocurrenceDataAddressStreet: yup.string().required(),
  ocurrenceDataAddressDistrict: yup.string().required(),
});
