import ocurrencesService from '../../../services/f5-services/ocurrencesService';
import { INIT_OCURRENCES_COUNTER } from './action-types';

export const initializeOcurrencesCounter = () => {
  return async dispatch => {
    const countOcurrences = await ocurrencesService.getOcurrencesCount();
    dispatch({
      type: INIT_OCURRENCES_COUNTER,
      data: countOcurrences,
    });
  };
};
