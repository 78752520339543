export const sidebarLightTheme = {
  bgColor: 'rgba(25, 25, 25, 1)',
  textColor: 'rgba(255, 255, 255, 0.75)',
  textDarkColor: 'rgba(255, 255, 255, 1)',
  textActiveColor: 'rgba(255, 255, 255, 1)',
  navHoverBgColor: 'rgba(50, 50, 50, 0.75)',
  navActiveBgColor: 'rgba(50, 50, 50, 0.75)',
  borderColor: 'rgba(255, 255, 255, 0.1)',
};

export const sidebarSemiDarkTheme = {
  bgColor: '#363636',
  textColor: 'rgba(255, 255, 255, 0.3)',
  textDarkColor: '#fff',
  textActiveColor: '#fff',
  navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
  navActiveBgColor: '#3f51b5',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  ...JSON.parse(localStorage.getItem('sidebar-colors')),
};

export const sidebarDarkTheme = {
  bgColor: '#363636',
  textColor: 'rgba(255, 255, 255, 0.3)',
  textDarkColor: '#fff',
  textActiveColor: '#fff',
  navHoverBgColor: 'rgba(187, 134, 252, 0.08)',
  navActiveBgColor: '#3f51b5',
  borderColor: 'rgba(255, 255, 255, 0.08)',
  ...JSON.parse(localStorage.getItem('sidebar-colors')),
};

const themeSidebarColor = {
  light: sidebarLightTheme,
  'semi-dark': sidebarSemiDarkTheme,
  dark: sidebarDarkTheme,
};

export default themeSidebarColor;
