import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useLocation, NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  navItem: {
    fontWeight: 500,
    position: 'relative',
    padding: theme.spacing(1, 4),
    margin: '0.25rem 0.25rem',
    borderRadius: '5px',
    borderBottom: '1px solid rgba(0, 0, 0, 0)',
    color: ({ selected }) => (selected ? 'white' : 'black'),
    backgroundColor: ({ bgcolor, selected }) => (selected ? bgcolor : 'rgba(0, 0, 0, 0)'),
    opacity: ({ selected }) => (selected ? 0.9 : 0.75),
    '&:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
    '&:hover .hover-line': {
      display: ({ selected }) => (selected ? 'none' : 'block'),
      zIndex: 1,
      position: 'absolute',
      width: '50%',
      height: '2px',
      backgroundColor: ({ bgcolor }) => (bgcolor ? bgcolor : 'black'),
      bottom: '-3px',
      left: 0,
      right: 0,
      margin: '0 auto',
    },
    '& .notifications': {
      zIndex: 2,
      display: ({ notifications }) => (notifications > 0 ? 'flex' : 'none'),
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',
      padding: '0.25rem',
      position: 'absolute',
      top: '-7px',
      right: '-7px',
      border: '1px solid rgba(150, 57, 14, 0.25)',
      borderRadius: '50%',
      backgroundColor: 'rgba(230, 109, 16, 1)',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
    },
    '& .notifications > span': {
      fontWeight: '700',
      fontSize: '11px',
      textAlign: 'center',
      color: 'white',
    },
  },
}));

const OcurrenceNavItem = ({ name, bgcolor, page, notifications }) => {
  const location = useLocation();

  const selected = location.pathname === page;

  const classes = useStyles({ bgcolor, selected, notifications });

  return (
    <NavLink to={page ? page : '/'}>
      <div className={classes.navItem}>
        <div className="notifications">
          <span>{notifications > 9 ? '9+' : notifications}</span>
        </div>
        <div className="hover-line" />
        {name}
      </div>
    </NavLink>
  );
};

export default OcurrenceNavItem;
