import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { initializePiechart } from '../../../redux/actions/f5-actions/ocurrences-piechart-actions';
import { Cell, Pie, PieChart, ResponsiveContainer, Legend, Tooltip } from 'recharts';
import { Box, Paper, Button, CircularProgress } from '@material-ui/core';
import clsx from 'clsx';
import FolderIcon from '@material-ui/icons/Folder';
import WarningIcon from '@material-ui/icons/Warning';
import MapIcon from '@material-ui/icons/Map';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  cardRoot: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardHead: {
    fontWeight: 700,
    fontSize: 12,
    display: 'flex',
    justifyContent: 'space-between',
    '& .main-value': {
      display: 'flex',
      alignItems: 'center',
    },
  },
  cardAction: {
    display: 'flex',
    justifyContent: 'center',
  },
  actionButtonRoot: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  actionButtonChildren: {
    fontSize: 10,
    textTransform: 'capitalize',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  tabWrapper: {
    display: 'flex',
    justifyContent: 'center',
    borderRadius: '15px',
    overflow: 'hidden',
  },
  cardTab: {
    fontSize: 11,
    fontWeight: 700,
    width: '25%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0.25rem',
    backgroundColor: 'rgba(0, 0, 0, 0.1)',
    opacity: 0.75,
    '&.tab-selected': {
      backgroundColor: 'rgba(0, 200, 0, 0.75)',
    },
    '&:hover': {
      opacity: 1,
      cursor: 'pointer',
    },
  },
}));

const COLORS = ['#ffa600', '#ff7c43', '#f95d6a', '#003f5c', '#d45087', '#a05195', '#665191', '#2f4b7c'];

const GeneralPieChart = () => {
  const [currentChartTab, setCurrentChartTab] = useState(0);
  const piechartData = useSelector(({ ocurrences_piechart }) => ocurrences_piechart.data);
  const dispatch = useDispatch();
  let history = useHistory();

  const changeRoute = link => {
    history.push(link);
  };

  useEffect(() => {
    dispatch(initializePiechart());
  }, [dispatch]);

  const classes = useStyles();

  const chartTabs = [
    {
      dataKey: 'situacao',
      title: 'Situação',
      icon: <i className="fas fa-bullhorn fa-lg" />,
    },
    {
      dataKey: 'setor',
      title: 'Setor',
      icon: <FolderIcon fontSize="small" />,
    },
    {
      dataKey: 'gravidade',
      title: 'Gravidade',
      icon: <WarningIcon fontSize="small" />,
    },
    {
      dataKey: 'entrada',
      title: 'Entrada',
      icon: <MapIcon fontSize="small" />,
    },
  ];

  const changeTab = i => {
    setCurrentChartTab(i);
  };

  const getChartData = () => {
    return piechartData[chartTabs[currentChartTab].dataKey];
  };

  if (!piechartData) {
    return (
      <Paper>
        <Box p={2} minHeight={250} display="flex" flexDirection="column" alignItems="center" justifyContent="center">
          <CircularProgress />
          <Box mt={2} fontWeight={700} textAlign="center">
            Carregando dados...
          </Box>
        </Box>
      </Paper>
    );
  }

  return (
    <Paper>
      <Box p={2} className={classes.cardRoot}>
        <Box className={classes.cardHead}>
          <Box className="main-value">
            {chartTabs[currentChartTab].icon}
            <Box ml={1}>{chartTabs[currentChartTab].title}</Box>
          </Box>
        </Box>
        <Box className={classes.cardAction} my={1}>
          <Button
            classes={{
              root: classes.actionButtonRoot,
              label: classes.actionButtonChildren,
            }}
            variant="contained"
            onClick={() => changeRoute('/pesquisar')}>
            <Box display="flex" alignItems="center">
              <i className="fas fa-book" />
              <Box ml={1}>Solicitados</Box>
            </Box>
            <span>{piechartData.solicitado}</span>
          </Button>
          <Button
            classes={{
              root: classes.actionButtonRoot,
              label: classes.actionButtonChildren,
            }}
            variant="contained"
            onClick={() => changeRoute('/ocorrencias/pendentes')}>
            <Box display="flex" alignItems="center">
              <i className="far fa-question-circle" />
              <Box ml={1}>Pendentes</Box>
            </Box>
            <span>{piechartData.pendente}</span>
          </Button>
        </Box>
        <ResponsiveContainer width="100%" height={250}>
          <PieChart>
            <Pie
              dataKey="value"
              nameKey="key"
              data={getChartData()}
              innerRadius={50}
              outerRadius={80}
              fill="#6200EE"
              paddingAngle={5}>
              {getChartData().map((entry, index) => (
                <Cell key={index} fill={COLORS[index]} />
              ))}
            </Pie>
            <Legend formatter={(value, entry, index) => `${value} (${entry.payload.value})`} />
            <Tooltip />
          </PieChart>
        </ResponsiveContainer>
        <Box my={1} className={classes.tabWrapper}>
          {chartTabs.map((chartItem, index) => (
            <Box
              key={index}
              className={clsx(classes.cardTab, index === currentChartTab ? 'tab-selected' : '')}
              onClick={() => changeTab(index)}>
              <span>{chartItem.title}</span>
              <span>{chartItem.icon}</span>
            </Box>
          ))}
        </Box>
      </Box>
    </Paper>
  );
};

export default GeneralPieChart;
