import React from 'react';
import { format, parseISO } from 'date-fns';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  container: {
    padding: 5,
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  title: {
    fontSize: 16,
    marginBottom: 5,
  },
  normal: {
    borderStyle: 'solid',
    borderColor: 'rgb(53, 53, 53)',
    borderWidth: '2px',
    paddingLeft: 5,
    paddingTop: 5,
    width: 570,
    marginTop: 2,
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },

  logo: {
    backgroundColor: 'rgb(53, 53, 53)',
    color: 'white',
    width: 90,
    paddingTop: 20,
    paddingBottom: 20,
    position: 'absolute',
    textAlign: 'center',
    marginLeft: 50,
  },
});

export const RequestHeader = ({ ocurrence, ...props }) => {
  return (
    <View>
      <Text style={[styles.logo]}>LOGO</Text>
      <Text
        style={{
          textAlign: 'center',
          fontSize: 18,
          marginTop: 10,
          marginBottom: 5,
        }}>
        Ocorrência: {ocurrence.protocolo}
      </Text>
      <Text style={{ textAlign: 'center', fontSize: 16, marginBottom: 5 }}>Solicitante: {ocurrence.solicitante.nome}</Text>
    </View>
  );
};

export const RequestInformation = ({ ocurrence, ...props }) => {
  return (
    <View style={styles.container}>
      <Text style={[styles.title, { marginTop: 10 }]}>Informações</Text>
      <View>
        <View style={styles.row}>
          <Text style={[styles.normal, { fontSize: 12 }]}>Solicitante: {ocurrence.solicitante.nome}</Text>
        </View>
        <View>
          <Text style={[styles.normal, { fontSize: 12 }]}>
            Aberta em: {ocurrence ? format(parseISO(ocurrence.abertura), 'dd/MM/yyyy hh:mm:ss') : 'Não informado'}
          </Text>
        </View>
        {ocurrence.coletivo ? (
          <View>
            <Text style={{ fontSize: 12 }}>Secundários: </Text>
            <Text style={{ fontSize: 12 }}>{ocurrence.coletivo}</Text>
          </View>
        ) : null}
      </View>
    </View>
  );
};

export const RequestLocation = ({ ocurrence, ...props }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Localização</Text>
      <Text style={[styles.normal, { fontSize: 12 }]}>{ocurrence.endereco}</Text>
    </View>
  );
};

export const RequestTriage = ({ ocurrence, ...props }) => {
  return (
    <View style={styles.container}>
      <View>
        <View>
          <Text style={styles.title}>Dados da Triagem</Text>
        </View>
      </View>

      <View>
        <View>
          <Text style={[styles.normal, { fontSize: 12 }]}>Categoria: {ocurrence.categoria}</Text>
        </View>
        <View>
          <Text style={[styles.normal, { fontSize: 12 }]}>Entrada: {ocurrence.entrada}</Text>
        </View>
        <View>
          <Text style={[styles.normal, { fontSize: 12 }]}>Gravidade: {ocurrence.gravidade}</Text>
        </View>
        <View>
          <Text style={[styles.normal, { fontSize: 12 }, { marginTop: 2 }]}>Setor: {ocurrence.setor}</Text>
        </View>
      </View>
    </View>
  );
};

export const RequestDescription = ({ ocurrence, ...props }) => {
  return (
    <View style={styles.container}>
      <View>
        <Text style={styles.title}>Solicitação</Text>
      </View>
      <View>
        <Text style={[styles.normal, { fontSize: 12 }]}>Protocolo: {ocurrence.protocolo}</Text>
      </View>
      <View>
        <Text style={[styles.title, { marginTop: 20 }, { marginBottom: 10 }]}>Assunto: </Text>
      </View>
      <View>
        <Text style={[styles.normal, { fontSize: 12, color: 'grey' }]}>
          {ocurrence.tema}/{ocurrence.assunto}/<Text style={{ color: 'black' }}>{ocurrence.servico}</Text>
        </Text>
      </View>
      <View>
        <Text style={[styles.normal, { fontSize: 12 }]}>Descrição:{ocurrence.descricao}</Text>
      </View>
    </View>
  );
};

export const RequestCommunication = ({ communication, pos, ...props }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>
        Comunique-se. Solicitação de Informação #{pos + 1} (Aberta em:
        {communication ? format(parseISO(communication.created_at), 'dd/MM/yyyy hh:mm:ss') : 'Não informado'})
      </Text>
      <View>
        <Text style={[styles.normal, { fontSize: 12 }]}>Pergunta: {communication.pedido}</Text>
      </View>
      <View>
        <Text style={[styles.normal, { fontSize: 12 }]}>Resposta: {communication.resposta}</Text>
      </View>
    </View>
  );
};

export const RequestRedirect = ({ movement, ...props }) => {
  return (
    <View style={styles.container}>
      <View>
        <View>
          <Text style={styles.title}>Encaminhamento</Text>
        </View>
      </View>

      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <Text style={{ fontSize: 12 }}>Destino:</Text>
        <Text style={{ fontSize: 18, backgroundColor: 'orange' }}>{movement.destinatario}</Text>
      </View>
    </View>
  );
};

export const RequestAnswer = ({ movement, json, ...props }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Resposta</Text>
      {json.compete === 'N' ? (
        <View>
          <Text style={{ fontSize: 12 }}>Compete a Outro Departamento: </Text>
          <Text style={{ fontSize: 12 }}>{json.departamento}</Text>
        </View>
      ) : (
        <View>
          {json.envolvedepto === 'S' ? (
            <View>
              <Text style={{ fontSize: 12 }}>Envolve Outro Departamento: </Text>
              <Text style={{ fontSize: 12 }}>{json.departamento}</Text>
            </View>
          ) : json.envolveorgao === 'S' ? (
            <View>
              <Text style={{ fontSize: 12 }}>Envolve Outro Órgão: </Text>
              <Text style={{ fontSize: 12 }}>{json.orgao}</Text>
            </View>
          ) : null}
          <View>
            <Text style={{ fontSize: 12 }}>Prazo Inicial: </Text>
            <Text style={{ fontSize: 12 }}>{json.prazo} dias </Text>
          </View>
        </View>
      )}
    </View>
  );
};

export const RequestCompleted = ({ movement, json, ...props }) => {
  return (
    <View>
      <Text style={styles.title}>Finalizar</Text>

      <View>
        {json.solucionada === 'S' ? (
          <Text style={{ fontSize: 12 }}>Situação: Solucionada</Text>
        ) : (
          <Text style={{ fontSize: 12 }}>Situação: Não Solucionada</Text>
        )}
      </View>
      <View>
        <Text style={{ fontSize: 12 }}>Posicionamento: {json.posicionamento}</Text>
      </View>
    </View>
  );
};

export const RequestAppeal = ({ movement, ...props }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.title}>Recurso</Text>
      <View>
        <Text style={{ fontSize: 12 }}>Motivo: </Text>
        <Text style={{ fontSize: 12 }}>{movement.descricao}</Text>
      </View>
    </View>
  );
};

export const RequestFooter = () => {
  return (
    <View style={{ marginTop: 15, marginLeft: 4 }}>
      <Text style={[styles.normal, { fontSize: 12 }]}>
        Prazo de resposta: 20 dias podendo haver uma prorrogação de 10 dias para o fornecimento de um parecer.
      </Text>
      <Text style={[styles.normal, { fontSize: 12 }]}>Canais de resposta: </Text>
      <Text style={[styles.normal, { fontSize: 12 }]}>Site: sorocaba.sp.gov.br/atendimento</Text>
      <Text style={[styles.normal, { fontSize: 12 }]}>
        Presencialmente: Em qualquer Casa do Cidadão ou na Sala da Ouvidoria (localizada no Paço Municipal)
      </Text>
      <Text style={[styles.normal, { fontSize: 12 }]}>Telefone: 156</Text>
    </View>
  );
};
