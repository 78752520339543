import React from 'react';
import { Box, DialogTitle, Dialog } from '@material-ui/core';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';

const MoreInfo = ({ open, setOpen, requester }) => {
  const formattedData = [
    { field: 'Nome:', value: requester.nome },
    { field: 'CPF:', value: requester.cpf },
    { field: 'Data de Nascimento:', value: requester.data_nascimento },
    {
      field: 'Estado Civil:',
      value: requester.estado_civil ? requester.estado_civil.descricao : '',
    },
    {
      field: 'Escolaridade:',
      value: requester.escolaridade ? requester.escolaridade.descricao : '',
    },
    { field: 'E-mail:', value: requester.email },
    { field: 'Telefone:', value: requester.telefone },
    { field: 'Celular:', value: requester.celular },
    { field: 'Rua:', value: requester.rua },
    { field: 'N°:', value: requester.numero },
    { field: 'Bairro:', value: requester.bairro },
    { field: 'Cidade:', value: requester.cidade },
    { field: 'CEP:', value: requester.cep },
    { field: 'Complemento:', value: requester.complemento },
  ];

  return (
    <Dialog onClose={() => setOpen(false)} open={open} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <Box display="flex" alignItems="center">
          <PermIdentityIcon />
          <Box ml={2}>Informações do Solicitante</Box>
        </Box>
      </DialogTitle>
      <Box display="flex" flexDirection="column" px={8} mb={4}>
        {formattedData.map((item, index) => {
          return (
            <Box display="flex" fontSize={12} mb={1} key={index}>
              <Box fontWeight={700}>{item.field}</Box>
              <Box fontWeight={500} ml={1}>
                {item.value}
              </Box>
            </Box>
          );
        })}
      </Box>
    </Dialog>
  );
};

export default MoreInfo;
