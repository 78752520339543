import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { format } from 'date-fns';
import { Paper, Box, CircularProgress } from '@material-ui/core';
import { useSelector } from 'react-redux';
import ocurrencesActionService from 'services/f5-services/ocurrencesActionService';

import { getLatLng } from '../../utils/getLatLngGoogle';
import validate from './validation/validate';

import Requester from './Requester';
import OtherRequesters from './OtherRequesters';
import OcurrenceData from './OcurrenceData';

import sweetAlert from 'f5/utils/sweetAlert';

import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    padding: theme.spacing(4, 8),
  },
  button: {
    marginRight: theme.spacing(1),
  },
  buttonPosition: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '25%',
    margin: theme.spacing(4, 'auto'),
  },
  checkButton: {
    color: 'white',
    backgroundColor: '#0ddb48',
    '&:hover': {
      backgroundColor: '#26ed5e',
    },
  },
}));

const OcurrenceCreate = ({ formFieldReference }) => {
  const formStatus = useSelector(({ ocurrences_create }) => ocurrences_create.status);
  const cpfIdentity = useSelector(({ ocurrences_create }) => ocurrences_create.cpfIdentity);
  const cnpjIdentity = useSelector(({ ocurrences_create }) => ocurrences_create.cnpjIdentity);
  const classes = useStyles();
  const [loader, setLoader] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [isColetive, setIsColetive] = useState(false);
  const [useAddressReference, setUseAddressReference] = useState(false);
  const [validationErrors, setValidationErrors] = useState({
    step: '',
    checked: false,
    list: {},
  });

  const [createOcurrenceForm, setCreateOcurrenceForm] = useState({
    privacyType: 'public',
    requesterType: 'person',
    requestType: 'unique',
    requesterCpfValue: '',
    requesterCnpjValue: '',
    requesterCepValue: cpfIdentity.cep ? cpfIdentity.cep : '',
    personSex: cpfIdentity.sexo ? cpfIdentity.sexo : 'Masculino',
    personEducation: cpfIdentity.escolaridade_id ? cpfIdentity.escolaridade_id.toString() : '',
    personMaritalStatus: cpfIdentity.estado_civil_id ? cpfIdentity.estado_civil_id.toString() : '',
    personName: cpfIdentity.nome ? cpfIdentity.nome : '',
    personEmail: cpfIdentity.email ? cpfIdentity.email : '',
    personBirthday: cpfIdentity.data_nascimento ? new Date(`${cpfIdentity.data_nascimento} 00:00:00`) : new Date(),
    personPhone: cpfIdentity.telefone ? cpfIdentity.telefone : '',
    personCellphone: cpfIdentity.celular ? cpfIdentity.celular : '',
    personProfession: cpfIdentity.profissao ? cpfIdentity.profissao : '',
    personAddressNumber: cpfIdentity.numero ? cpfIdentity.numero : '',
    personAddressComplement: cpfIdentity.complemento ? cpfIdentity.complemento : '',
    personAddressStreet: cpfIdentity.rua ? cpfIdentity.rua : '',
    personAddressDistrict: cpfIdentity.bairro ? cpfIdentity.bairro : '',
    personAddressCity: cpfIdentity.cidade ? cpfIdentity.cidade : '',
    companyUser: cnpjIdentity.usuario_id ? cnpjIdentity.usuario_id : '',
    companyCep: cnpjIdentity.cep ? cnpjIdentity.cep : '',
    companyName: cnpjIdentity.nome ? cnpjIdentity.nome : '',
    companyEmail: cnpjIdentity.email ? cnpjIdentity.email : '',
    companyRegister: cnpjIdentity.im ? cnpjIdentity.im : '',
    companyPhone: cnpjIdentity.telefone ? cnpjIdentity.telefone : '',
    companyAddressNumber: cnpjIdentity.numero ? cnpjIdentity.numero : '',
    companyAddressComplement: cnpjIdentity.complemento ? cnpjIdentity.complemento : '',
    companyAddressStreet: cnpjIdentity.rua ? cnpjIdentity.rua : '',
    companyAddressDistrict: cnpjIdentity.bairro ? cnpjIdentity.bairro : '',
    companyAddressCity: cnpjIdentity.cidade ? cnpjIdentity.cidade : '',
    ocurrenceDataCategory: '',
    ocurrenceDataGravity: '',
    ocurrenceDataSector: '',
    ocurrenceDataEntry: '',
    ocurrenceDataInputFile: '',
    ocurrenceDataInputFileData: '',
    ocurrenceDataTheme: '',
    ocurrenceDataSubject: '',
    ocurrenceDataService: '',
    ocurrenceDataDescription: '',
    ocurrenceDataCep: '',
    ocurrenceDataAddressNumber: '',
    ocurrenceDataAddressComplement: '',
    ocurrenceDataAddressStreet: '',
    ocurrenceDataAddressDistrict: '',
    useAddressReference: false,
    coletiveList: [],
  });

  const history = useHistory();

  const handleFormChange = (e, optionals) => {
    // Depois que o usuário tentou prosseguir um passo ou finalizar, o formulário será válidado.
    // Se tiver erros, essa função abaixo serve para atualizar as validações conforme o usuário digita.
    // Feito dessa forma por causa dos problemas com setState sendo assíncrono.
    // O MELHOR seria substituir tudo por uma biblioteca de formulários como o formik.
    const newFormValidations = nf => {
      if (validationErrors.checked) {
        const stepId = steps[activeStep].id;
        const validation = validate(nf, stepId);
        setValidationErrors({
          step: stepId,
          checked: true,
          list: validation.errorList,
        });
      }
    };

    // 'optionals' é um objeto espelhando o createOcurrenceForm, com os novos valores.
    // Está como objeto, pois não pode fazer múltiplas chamadas consecutivas ao setState sem causar problemas (Apenas a última chamada é aplicada)
    if (e === null) {
      const newForm = {
        ...createOcurrenceForm,
        ...optionals,
      };
      newFormValidations(newForm);
      setCreateOcurrenceForm(newForm);
    } else {
      const newForm = {
        ...createOcurrenceForm,
        [e.target.name]: e.target.value,
      };
      newFormValidations(newForm);
      setCreateOcurrenceForm(newForm);
    }
  };

  // Atualiza os campos do formulário se os dados de identidade forem atualizados
  useEffect(() => {
    setCreateOcurrenceForm(createOcurrenceForm => {
      return {
        ...createOcurrenceForm,
        requesterCepValue: cpfIdentity.cep ? cpfIdentity.cep : '',
        personSex: cpfIdentity.sexo ? cpfIdentity.sexo : 'Masculino',
        personEducation: cpfIdentity.escolaridade_id ? cpfIdentity.escolaridade_id.toString() : '',
        personMaritalStatus: cpfIdentity.estado_civil_id ? cpfIdentity.estado_civil_id.toString() : '',
        personName: cpfIdentity.nome ? cpfIdentity.nome : '',
        personEmail: cpfIdentity.email ? cpfIdentity.email : '',
        personBirthday: cpfIdentity.data_nascimento ? new Date(`${cpfIdentity.data_nascimento} 00:00:00`) : new Date(),
        personPhone: cpfIdentity.telefone ? cpfIdentity.telefone : '',
        personCellphone: cpfIdentity.celular ? cpfIdentity.celular : '',
        personProfession: cpfIdentity.profissao ? cpfIdentity.profissao : '',
        personAddressNumber: cpfIdentity.numero ? cpfIdentity.numero : '',
        personAddressComplement: cpfIdentity.complemento ? cpfIdentity.complemento : '',
        personAddressStreet: cpfIdentity.rua ? cpfIdentity.rua : '',
        personAddressDistrict: cpfIdentity.bairro ? cpfIdentity.bairro : '',
        personAddressCity: cpfIdentity.cidade ? cpfIdentity.cidade : '',
      };
    });
  }, [cpfIdentity]);

  // Atualiza os dados de endereço baseado na opção de refêrencia
  useEffect(() => {
    if (useAddressReference) {
      setCreateOcurrenceForm(createOcurrenceForm => {
        return {
          ...createOcurrenceForm,
          ocurrenceDataCep: createOcurrenceForm.requesterCepValue,
          ocurrenceDataAddressNumber: createOcurrenceForm.personAddressNumber,
          ocurrenceDataAddressComplement: createOcurrenceForm.personAddressComplement,
          ocurrenceDataAddressStreet: createOcurrenceForm.personAddressStreet,
          ocurrenceDataAddressDistrict: createOcurrenceForm.personAddressDistrict,
        };
      });
    } else {
      setCreateOcurrenceForm(createOcurrenceForm => {
        return {
          ...createOcurrenceForm,
          ocurrenceDataCep: '',
          ocurrenceDataAddressNumber: '',
          ocurrenceDataAddressComplement: '',
          ocurrenceDataAddressStreet: '',
          ocurrenceDataAddressDistrict: '',
        };
      });
    }
  }, [useAddressReference, setCreateOcurrenceForm]);

  const steps = [
    {
      id: 'requester',
      label: 'Solicitante',
    },
    { id: 'others', label: 'Outros Solicitantes' },
    { id: 'data', label: 'Dados da Ocorrência' },
  ];

  const goNext = async () => {
    // Envia o formulário
    if (activeStep === steps.length - 1) {
      setLoader(true);

      const coords = await getLatLng(
        `${createOcurrenceForm.ocurrenceDataAddressStreet}, ${createOcurrenceForm.ocurrenceDataAddressNumber} - ${createOcurrenceForm.ocurrenceDataAddressDistrict}, ${createOcurrenceForm.ocurrenceDataAddressCity} - São Paulo`, // TODO: Definir melhor como pegar o valor do estado
      );

      let formData = new FormData();
      if (createOcurrenceForm.ocurrenceDataInputFileData) {
        formData.append('anexo', createOcurrenceForm.ocurrenceDataInputFileData);
      }

      formData.append('modo', createOcurrenceForm.requesterType === 'person' ? 'CPF' : 'CNPJ');
      formData.append('coletivo', !createOcurrenceForm.requestType === 'unique');
      formData.append(
        'ocorrencia',
        JSON.stringify({
          sigilo: createOcurrenceForm.privacyType === 'public' ? 0 : 1,
          descricao: createOcurrenceForm.ocurrenceDataDescription,
          ouv__categoria_id: parseInt(createOcurrenceForm.ocurrenceDataCategory),
          ouv__entrada_id: parseInt(createOcurrenceForm.ocurrenceDataEntry),
          ouv__gravidade_id: parseInt(createOcurrenceForm.ocurrenceDataGravity),
          ouv__setor_id: parseInt(createOcurrenceForm.ocurrenceDataSector),
          ouv__servico_id: parseInt(createOcurrenceForm.ocurrenceDataService),
          rua: createOcurrenceForm.ocurrenceDataAddressStreet,
          numero: createOcurrenceForm.ocurrenceDataAddressNumber,
          bairro: createOcurrenceForm.ocurrenceDataAddressDistrict,
          complemento: createOcurrenceForm.ocurrenceDataAddressComplement,
          cidade: 'Botucatu', // TODO: Definir melhor como pegar o valor dessa variável.
          cep: createOcurrenceForm.ocurrenceDataCep,
          lat: coords.lat,
          lng: coords.lng,
        }),
      );
      formData.append(
        'solicitante',
        JSON.stringify({
          cpf: createOcurrenceForm.requesterCpfValue,
          id: cpfIdentity.id,
          nome: createOcurrenceForm.personName,
          sexo: createOcurrenceForm.personSex,
          email: createOcurrenceForm.personEmail,
          telefone: createOcurrenceForm.personPhone,
          celular: createOcurrenceForm.personCellphone,
          data_nascimento: format(createOcurrenceForm.personBirthday, 'yyyy-MM-dd'),
          cep: createOcurrenceForm.requesterCepValue,
          rua: createOcurrenceForm.personAddressStreet,
          numero: createOcurrenceForm.personAddressNumber,
          bairro: createOcurrenceForm.personAddressDistrict,
          complemento: createOcurrenceForm.personAddressComplement,
          cidade: createOcurrenceForm.personAddressCity,
          estado_civil_id: parseInt(createOcurrenceForm.personMaritalStatus),
          escolaridade_id: parseInt(createOcurrenceForm.personEducation),
          nascimento: format(createOcurrenceForm.personBirthday, 'yyyy-MM-dd'), // Repetido?
          profissao: createOcurrenceForm.personProfession,
          estado: 'SP', // TODO: Deveria ter esse campo? É fixo?
          usuario_id: null, // TODO: Necessário?
          created_at: null, // TODO: Necessário?
          updated_at: null, // TODO: Necessário?
        }),
      );
      formData.append(
        'empresa',
        JSON.stringify({
          nome: createOcurrenceForm.companyName,
          cnpj: createOcurrenceForm.requesterCnpjValue,
          im: createOcurrenceForm.companyRegister,
          email: createOcurrenceForm.companyEmail,
          telefone: createOcurrenceForm.companyPhone,
          cep: createOcurrenceForm.companyCep,
          rua: createOcurrenceForm.companyAddressStreet,
          numero: createOcurrenceForm.companyAddressNumber,
          bairro: createOcurrenceForm.companyAddressDistrict,
          complemento: createOcurrenceForm.companyAddressComplement,
          cidade: createOcurrenceForm.companyAddressCity,
          estado: 'SP',
        }),
      );
      formData.append(
        'coletivo',
        JSON.stringify(
          createOcurrenceForm.coletiveList.map(item => ({
            nome: item.name,
            cpf: item.cpf,
            email: item.email,
          })),
        ),
      );

      const res = await ocurrencesActionService.createOcurrence(formData);
      setLoader(false);
      // TODO: Criar alerta para erro e preencher sucesso com o departamento para o qual a ocorrência foi encaminhada
      if (res.success) {
        sweetAlert('success', 'Sucesso', 'Sua ocorrência foi registrada com sucesso!', () => {
          history.push(`/ocorrencias/recebidas`);
        });
      }
    }

    setActiveStep(prevActiveStep => (isColetive ? prevActiveStep + 1 : 2));
    setValidationErrors({ step: '', checked: false, list: {} });
    window.scrollTo(0, 0);
  };

  const goBack = () => {
    setActiveStep(prevActiveStep => (isColetive ? prevActiveStep - 1 : 0));
    setValidationErrors({ step: '', checked: false, list: {} });
    window.scrollTo(0, 0);
  };

  const validateForm = () => {
    const stepId = steps[activeStep].id;
    const validation = validate(createOcurrenceForm, stepId);
    if (validation.isValid) {
      setValidationErrors({
        step: stepId,
        checked: true,
        list: validation.errorList,
      });
      return true;
    } else {
      setValidationErrors({
        step: stepId,
        checked: true,
        list: validation.errorList,
      });
      return false;
    }
  };

  const handleNext = () => {
    if (validateForm()) {
      goNext();
    }
  };
  const handleBack = () => {
    goBack();
  };

  const getStepContent = step => {
    switch (step) {
      case 0:
        return (
          <Requester
            setColetive={setIsColetive}
            formFieldReference={formFieldReference}
            formStatus={formStatus}
            createOcurrenceForm={createOcurrenceForm}
            handleFormChange={handleFormChange}
            validationErrors={validationErrors}
          />
        );
      case 1:
        return (
          <OtherRequesters
            validationErrors={validationErrors}
            createOcurrenceForm={createOcurrenceForm}
            handleFormChange={handleFormChange}
          />
        );
      case 2:
        return (
          <OcurrenceData
            validationErrors={validationErrors}
            formFieldReference={formFieldReference}
            createOcurrenceForm={createOcurrenceForm}
            handleFormChange={handleFormChange}
            setUseAddressReference={setUseAddressReference}
            useAddressReference={useAddressReference}
          />
        );
      default:
        return 'Passo inválido...';
    }
  };

  const isStepValid = () => {
    return !(activeStep >= steps.length);
  };

  if (loader) {
    return (
      <Box width="100%" height={350} display="flex" justifyContent="center" alignItems="center">
        <CircularProgress />
        <Box fontWeight={700} fontSize={20} textAlign="center" px={8} mt={4}>
          Enviando ocorrência...
        </Box>
      </Box>
    );
  }

  return (
    <Paper className={classes.root}>
      <Box px={32}>
        <Stepper activeStep={activeStep}>
          {steps.map(step => {
            return (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            );
          })}
        </Stepper>
      </Box>
      <Box>
        {getStepContent(activeStep)}
        <Box className={classes.buttonPosition}>
          <Button disabled={activeStep === 0} onClick={handleBack}>
            Voltar
          </Button>

          <Button variant="contained" classes={{ root: classes.checkButton }} onClick={handleNext} disabled={!isStepValid()}>
            {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
          </Button>
        </Box>
      </Box>
    </Paper>
  );
};

export default OcurrenceCreate;
