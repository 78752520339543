import generalService from '../../../services/f5-services/generalService';
import { INIT_PIE_CHART } from './action-types';

export const initializePiechart = () => {
  return async dispatch => {
    const piechart_data = await generalService.getGeneralOcurrencesPieChart();
    dispatch({
      type: INIT_PIE_CHART,
      data: piechart_data,
    });
  };
};
