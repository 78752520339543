import React from 'react';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';

import CommunicationPage from '../../../f5/communication/CommunicationPage';
import OcurrenceNavbar from '../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Communication = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <CommunicationPage />
    </PageContainer>
  );
};

export default Communication;
