import { CLEAN_DEPARTMENT_OMBUDSMAN, SET_DEPARTMENT_OMBUDSMAN } from './action-types';

export const cleanDepartmentOmbudsman = () => {
  return {
    type: CLEAN_DEPARTMENT_OMBUDSMAN,
  };
};

export const setDepartmentOmbudsman = office => {
  return {
    type: SET_DEPARTMENT_OMBUDSMAN,
    payload: office,
  };
};
