import React from 'react';
import ocurrencesActionService from 'services/f5-services/ocurrencesActionService';
import { Box, Button, TextField } from '@material-ui/core';
import InputMask from 'react-input-mask';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  sectionText: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
  checkButton: {
    fontSize: 12,
    color: 'white',
    backgroundColor: '#dbc60d',
    '&:hover': {
      backgroundColor: '#d9c623',
    },
  },
}));

const unformatCep = c => {
  return c.replace(/[^0-9]/g, '');
};

const CompanyFields = ({ createOcurrenceForm, handleFormChange, validateMe }) => {
  const classes = useStyles();

  const handleAddress = async () => {
    const rawCep = unformatCep(createOcurrenceForm.companyCep);
    if (rawCep.length === 8) {
      const addressData = await ocurrencesActionService.getAddressData(rawCep);

      handleFormChange(null, {
        companyAddressCity: addressData.localidade,
        companyAddressStreet: addressData.logradouro,
        companyAddressComplement: addressData.complemento,
        companyAddressDistrict: addressData.bairro,
      });
    }
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box mb={4}>
        <Box className={classes.sectionText} fontWeight={700} fontSize={12} mb={1}>
          INFORMAÇÕES DA EMPRESA
        </Box>
        <Box display="flex">
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb={8} width="95%">
              <TextField
                error={validateMe('companyName').isError}
                helperText={validateMe('companyName').message}
                inputProps={{ tabIndex: 10 }}
                fullWidth
                size="small"
                id="company-name-input"
                label="Nome"
                required
                name="companyName"
                value={createOcurrenceForm.companyName}
                onChange={handleFormChange}
              />
            </Box>
            <Box mb={8} width="95%">
              <InputMask mask="(99)9999-9999" value={createOcurrenceForm.companyPhone} onChange={handleFormChange}>
                <TextField
                  error={validateMe('companyPhone').isError}
                  helperText={validateMe('companyPhone').message}
                  inputProps={{ tabIndex: 12 }}
                  required
                  name="companyPhone"
                  fullWidth
                  size="small"
                  id="company-phone-input"
                  label="Telefone"
                />
              </InputMask>
            </Box>
            <Box width="95%" mb={8}>
              <TextField
                error={validateMe('companyAddressStreet').isError}
                helperText={validateMe('companyAddressStreet').message}
                inputProps={{ tabIndex: 14 }}
                fullWidth
                required
                size="small"
                id="company-street-input"
                label="Rua"
                name="companyAddressStreet"
                value={createOcurrenceForm.companyAddressStreet}
                onChange={handleFormChange}
              />
            </Box>
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <TextField
                  inputProps={{ tabIndex: 16 }}
                  fullWidth
                  required
                  size="small"
                  id="company-district-input"
                  label="Bairro"
                  name="companyAddressDistrict"
                  error={validateMe('companyAddressDistrict').isError}
                  helperText={validateMe('companyAddressDistrict').message}
                  value={createOcurrenceForm.companyAddressDistrict}
                  onChange={handleFormChange}
                />
              </Box>
              <Box width="100%" ml={4}>
                <TextField
                  error={validateMe('companyAddressCity').isError}
                  helperText={validateMe('companyAddressCity').message}
                  inputProps={{ tabIndex: 17 }}
                  fullWidth
                  size="small"
                  id="company-city-input"
                  label="Cidade"
                  name="companyAddressCity"
                  value={createOcurrenceForm.companyAddressCity}
                  onChange={handleFormChange}
                />
              </Box>
            </Box>
          </Box>
          <Box width="50%" display="flex" flexDirection="column">
            <Box mb={8} width="95%">
              <TextField
                error={validateMe('companyEmail').isError}
                helperText={validateMe('companyEmail').message}
                inputProps={{ tabIndex: 11 }}
                type="email"
                fullWidth
                size="small"
                id="company-email-input"
                label="E-mail"
                required
                name="companyEmail"
                value={createOcurrenceForm.companyEmail}
                onChange={handleFormChange}
              />
            </Box>
            <Box mb={8} width="95%">
              <TextField
                error={validateMe('companyRegister').isError}
                helperText={validateMe('companyRegister').message}
                inputProps={{ tabIndex: 13 }}
                fullWidth
                required
                size="small"
                id="company-register-input"
                label="Inscrição Municipal"
                name="companyRegister"
                value={createOcurrenceForm.companyRegister}
                onChange={handleFormChange}
              />
            </Box>
            <Box mb={8} display="flex" alignItems="center">
              <InputMask mask="99.999-999" value={createOcurrenceForm.companyCep} onChange={handleFormChange}>
                <TextField
                  error={validateMe('companyCep').isError}
                  helperText={validateMe('companyCep').message}
                  inputProps={{ tabIndex: 15 }}
                  name="companyCep"
                  fullWidth
                  size="small"
                  id="cep-search-input"
                  label="CEP"
                  required
                />
              </InputMask>
              <Box ml={2} display="flex" alignItems="center" width="50%">
                <Button
                  disabled={unformatCep(createOcurrenceForm.companyCep).length !== 8}
                  variant="contained"
                  classes={{ root: classes.checkButton }}
                  size="small"
                  onClick={handleAddress}>
                  Buscar por CEP
                </Button>
              </Box>
            </Box>
            <Box mb={8} display="flex" width="95%">
              <Box width="100%">
                <TextField
                  error={validateMe('companyAddressNumber').isError}
                  helperText={validateMe('companyAddressNumber').message}
                  inputProps={{ tabIndex: 18 }}
                  fullWidth
                  size="small"
                  id="company-number-input"
                  label="Número"
                  required
                  name="companyAddressNumber"
                  value={createOcurrenceForm.companyAddressNumber}
                  onChange={handleFormChange}
                />
              </Box>
              <Box width="100%" ml={4}>
                <TextField
                  error={validateMe('companyAddressComplement').isError}
                  helperText={validateMe('companyAddressComplement').message}
                  inputProps={{ tabIndex: 19 }}
                  fullWidth
                  size="small"
                  id="company-complement-input"
                  label="Complemento"
                  name="companyAddressComplement"
                  value={createOcurrenceForm.companyAddressComplement}
                  onChange={handleFormChange}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default CompanyFields;
