import Geocode from 'react-geocode';

Geocode.setApiKey('AIzaSyABsHySYzx7AAJBuk-8Eb2Ds-INGtaTfik');
Geocode.setLanguage('pt-BR');

export const getLatLng = async address => {
  const response = await Geocode.fromAddress(address);
  const { lat, lng } = response.results[0].geometry.location;

  return { lat, lng };
};
