import React from 'react';
import { Box } from '@material-ui/core';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  grey: {
    color: 'grey',
  },
}));

const Profile = ({ data }) => {
  const classes = useStyles();

  const notDefined = () => {
    return <Box className={classes.grey}>Não definido</Box>;
  };

  return (
    <Box display="flex" flexDirection="column">
      <Box fontWeight={700} fontSize={12} mb={8}>
        Informações de Usuário
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box fontWeight={700}>Nome: </Box>
        <Box fontWeight={500} ml={2}>
          {data.nome ? data.nome : notDefined()}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box fontWeight={700}>E-mail: </Box>
        <Box fontWeight={500} ml={2}>
          {data.email ? data.email : notDefined()}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box fontWeight={700}>Telefone: </Box>
        <Box fontWeight={500} ml={2}>
          {data.telefone ? data.telefone : notDefined()}
        </Box>
      </Box>
      <Box display="flex" alignItems="center" mb={2}>
        <Box fontWeight={700}>Celular: </Box>
        <Box fontWeight={500} ml={2}>
          {data.celular ? data.celular : notDefined()}
        </Box>
      </Box>
    </Box>
  );
};

export default Profile;
