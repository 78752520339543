import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { initializeOcurrencesFormData } from '../../../redux/actions/f5-actions/ocurrences-actions';
import { Box, ButtonGroup } from '@material-ui/core';

import ActionsButton from './ActionsButton';
import ActionsModal from './ActionsModal';

import QuickAnswer from './ActionsModals/QuickAnswer';
import DepartmentRedirect from './ActionsModals/DepartmentRedirect';
// import MayorRedirect from './ActionsModals/MayorRedirect';
import GroupOcurrence from './ActionsModals/GroupOcurrence';
import UngroupOcurrence from './ActionsModals/UngroupOcurrence';
import AttachFiles from './ActionsModals/AttachFiles';
import CancelOcurrence from './ActionsModals/CancelOcurrence';
import SendEmail from './ActionsModals/SendEmail';
import FinishOcurrence from './ActionsModals/FinishOcurrence';
import DepartmentTransfer from './ActionsModals/DepartmentTransfer';
import RequestDepartment from './ActionsModals/RequestDepartment';
import ExtendDeadline from './ActionsModals/ExtendDeadline';
import VerifyExtendDeadline from './ActionsModals/VerifyExtendDeadline';
import ReturnOcurrence from './ActionsModals/ReturnOcurrence';
import AnalysisOcurrence from './ActionsModals/AnalysisOcurrence';
import AttendOcurrence from './ActionsModals/AttendOcurrence';
import Communication from './ActionsModals/Communication';
import OpenAppeal from './ActionsModals/OpenAppeal';
import VerifyAppeal from './ActionsModals/VerifyAppeal';
import OmbudsmanRedirect from './ActionsModals/OmbudsmanRedirect';
import PrintOcurrence from './ActionsModals/PrintOcurrence';
// import PrintOrder from './ActionsModals/PrintOrder';

import ReplyIcon from '@material-ui/icons/Reply';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
// import SendIcon from '@material-ui/icons/Send';
import HorizontalSplitIcon from '@material-ui/icons/HorizontalSplit';
import CreateNewFolderIcon from '@material-ui/icons/CreateNewFolder';
import EmailIcon from '@material-ui/icons/Email';
import PrintIcon from '@material-ui/icons/Print';
// import ReceiptIcon from '@material-ui/icons/Receipt';
import CancelIcon from '@material-ui/icons/Cancel';
import VerticalSplitIcon from '@material-ui/icons/VerticalSplit';
import CheckIcon from '@material-ui/icons/Check';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import CallMissedOutgoingIcon from '@material-ui/icons/CallMissedOutgoing';
import RestoreIcon from '@material-ui/icons/Restore';
import AddAlarmIcon from '@material-ui/icons/AddAlarm';
import UndoIcon from '@material-ui/icons/Undo';
import FindReplaceIcon from '@material-ui/icons/FindReplace';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import ForumIcon from '@material-ui/icons/Forum';
import ReportIcon from '@material-ui/icons/Report';
import CallMadeIcon from '@material-ui/icons/CallMade';

const Actions = ({ ocurrence, userAccess }) => {
  const userOffice = useSelector(({ department_ombudsman }) => department_ombudsman.office);
  const formData = useSelector(({ ocurrences_actions }) => ocurrences_actions);
  const [open, setOpen] = useState(false);
  const [modalIndex, setModalIndex] = useState(0);

  const dispatch = useDispatch();

  // console.log(ocurrence);

  useEffect(() => {
    dispatch(initializeOcurrencesFormData());
  }, [dispatch]);

  useEffect(() => {
    setOpen(false);
  }, [ocurrence]);

  const handleOpen = i => {
    setModalIndex(i);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const ocurrenceActions = [
    {
      priority: 0,
      text: 'Resposta Rápida ao Munícipe',
      icon: <ReplyIcon fontSize="small" />,
      component: <QuickAnswer triage={formData.triage} ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: ['nova', 'ouv-nova', 'estornada', 'ouv-estornada'],
    },
    {
      priority: 1,
      text: 'Não Compete ao Departamento',
      colors: {
        textColor: 'white',
        highlight: 'rgba(232, 30, 30, 1)',
        highlightHover: 'rgba(247, 83, 62, 1)',
      },
      icon: <UndoIcon fontSize="small" />,
      component: <ReturnOcurrence ocurrence={ocurrence} />,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: ['pendente', 'ouv-pendente', 'andamento', 'ouv-andamento'],
    },
    {
      priority: 2,
      text: 'Colocar em Análise',
      colors: {
        textColor: 'black',
        highlight: 'rgba(227, 220, 20, 1)',
        highlightHover: 'rgba(237, 237, 45, 1)',
      },
      icon: <FindReplaceIcon fontSize="small" />,
      component: <AnalysisOcurrence ocurrence={ocurrence} />,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: ['pendente', 'ouv-pendente'],
    },
    {
      priority: 3,
      text: userOffice === '156' ? 'Responder 156' : 'Responder Ouvidoria',
      icon: <AssignmentTurnedInIcon fontSize="small" />,
      component: <AttendOcurrence ocurrence={ocurrence} />,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: ['pendente', 'ouv-pendente', 'andamento', 'ouv-andamento'],
    },
    {
      priority: 1,
      text: 'Encaminhar a um Departamento',
      icon: <ArrowRightAltIcon fontSize="small" />,
      component: <DepartmentRedirect triage={formData.triage} departments={formData.departments} ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: ['nova', 'ouv-nova', 'estornada', 'ouv-estornada'],
    },
    {
      priority: 0,
      text: 'Transferir de Departamento',
      icon: <SyncAltIcon fontSize="small" />,
      component: <DepartmentTransfer departments={formData.departments} ocurrence={ocurrence} closeIt={handleClose} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: ['pendente', 'ouv-pendente'],
    },
    {
      priority: 0,
      text: 'Encaminhar a Ouvidoria',
      icon: <CallMadeIcon fontSize="small" />,
      component: <OmbudsmanRedirect ocurrence={ocurrence} />,
      access: ['admin', 'triagem'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'estornada',
        'ouv-estornada',
      ],
    },
    {
      priority: 1,
      text: 'Solicitar Posição do Departamento',
      icon: <CallMissedOutgoingIcon fontSize="small" />,
      component: <RequestDepartment ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: ['pendente', 'ouv-pendente', 'andamento', 'ouv-andamento', 'solucionada', 'ouv-solucionada'],
    },
    // {
    //   priority: 0,
    //   text: 'Encaminhar ao Prefeito',
    //   icon: <SendIcon fontSize="small" />,
    //   component: <MayorRedirect ocurrence={ocurrence} />,
    //   access: ['admin', 'ouv_master'],
    //   phases: ['nova', 'ouv-nova', 'pendente', 'ouv-pendente', 'andamento', 'ouv-andamento', 'solucionada', 'ouv-solucionada', 'finalizada', 'ouv-finalizada', 'cancelada'],
    // },
    {
      priority: 0,
      text: 'Solicitar Prorrogação',
      icon: <AddAlarmIcon fontSize="small" />,
      component: <ExtendDeadline ocurrence={ocurrence} />,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: ['andamento', 'ouv-andamento'],
    },
    {
      priority: 0,
      text: 'Verificar Prorrogação',
      icon: <RestoreIcon fontSize="small" />,
      component: <VerifyExtendDeadline ocurrence={ocurrence} />,
      access: ['admin', 'ouv_master'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'estornada',
        'ouv-estornada',
        'recurso',
      ],
    },
    {
      priority: 0,
      text: !ocurrence.agrupada ? 'Agrupar Ocorrência' : 'Desagrupar Ocorrência',
      icon: !ocurrence.agrupada ? <HorizontalSplitIcon fontSize="small" /> : <VerticalSplitIcon fontSize="small" />,
      component: !ocurrence.agrupada ? (
        <GroupOcurrence ocurrence={ocurrence} />
      ) : (
        <UngroupOcurrence ocurrence={ocurrence} closeIt={handleClose} />
      ),
      access: ['admin', 'ouvidor', 'triagem'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'estornada',
        'ouv-estornada',
        'recurso',
      ],
    },
    {
      priority: 0,
      text: 'Anexar Arquivos',
      icon: <CreateNewFolderIcon fontSize="small" />,
      component: <AttachFiles ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'estornada',
        'ouv-estornada',
        'recurso',
      ],
    },
    {
      priority: 0,
      text: 'Solicitar Comunique-se',
      icon: <ForumIcon fontSize="small" />,
      component: <Communication ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'estornada',
        'ouv-estornada',
        'recurso',
      ],
    },
    {
      priority: 0,
      text: 'Abrir Recurso',
      icon: <ReportIcon fontSize="small" />,
      component: <OpenAppeal ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'estornada',
        'ouv-estornada',
      ],
    },
    {
      priority: 0,
      text: 'Verificar Recurso',
      icon: <AssignmentTurnedInIcon fontSize="small" />,
      component: <VerifyAppeal ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor'],
      phases: ['recurso'],
    },
    {
      priority: 0,
      text: 'Cópia por E-mail',
      icon: <EmailIcon fontSize="small" />,
      component: <SendEmail ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'cancelada',
        'estornada',
        'ouv-estornada',
        'recurso',
      ],
    },
    {
      priority: 0,
      text: 'Imprimir',
      icon: <PrintIcon fontSize="small" />,
      component: <PrintOcurrence ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'finalizada',
        'ouv-finalizada',
        'cancelada',
        'estornada',
        'ouv-estornada',
        'recurso',
        'ouv-encaminhada',
      ],
    },
    // {
    //   priority: 0,
    //   text: 'Ordem de Serviço',
    //   icon: <ReceiptIcon fontSize="small" />,
    //   component: <PrintOrder ocurrence={ocurrence} />,
    //   access: ['admin', 'ouvidor', 'triagem', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
    //   phases: [
    //     'pendente',
    //     'ouv-pendente',
    //     'andamento',
    //     'ouv-andamento',
    //     'solucionada',
    //     'ouv-solucionada',
    //     'finalizada',
    //     'ouv-finalizada',
    //   ],
    // },
    {
      priority: 0,
      text: 'Cancelar',
      icon: <CancelIcon fontSize="small" />,
      component: <CancelOcurrence ocurrence={ocurrence} closeIt={handleClose} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: [
        'nova',
        'ouv-nova',
        'pendente',
        'ouv-pendente',
        'andamento',
        'ouv-andamento',
        'solucionada',
        'ouv-solucionada',
        'estornada',
        'ouv-estornada',
      ],
    },
    {
      priority: 0,
      text: 'Responder ao Munícipe',
      icon: <CheckIcon fontSize="small" />,
      component: <FinishOcurrence ocurrence={ocurrence} />,
      access: ['admin', 'ouvidor', 'triagem'],
      phases: ['solucionada', 'ouv-solucionada'],
    },
    {
      priority: 0,
      text: 'Responder ao Munícipe',
      icon: <CheckIcon fontSize="small" />,
      component: <FinishOcurrence ocurrence={ocurrence} />,
      access: ['admin', 'gestao', 'funcionario', 'departamento', 'sub_departamento'],
      phases: ['solucionada'],
    },
  ];

  const ocurrenceActionsWithAccess = () => {
    return ocurrenceActions
      .filter(item => item.access.includes(userAccess) && item.phases.includes(ocurrence.passo_interno))
      .sort((a, b) => (a.priority === b.priority ? 0 : a.priority > b.priority ? -1 : 1));
  };

  return (
    <Box>
      <Box fontWeight={700} textAlign="center" mb={3}>
        Ações
      </Box>
      <ButtonGroup fullWidth orientation="vertical" aria-label="botões de ação" variant="contained">
        {ocurrenceActionsWithAccess().map((item, index) => (
          <ActionsButton
            key={index}
            pos={index}
            icon={item.icon}
            text={item.text}
            handleClick={() => handleOpen(index)}
            colors={item.colors ? item.colors : null}
          />
        ))}
      </ButtonGroup>
      <ActionsModal open={open} handleClose={handleClose}>
        {ocurrenceActionsWithAccess().length >= modalIndex + 1 ? (
          ocurrenceActionsWithAccess()[modalIndex].component
        ) : (
          <React.Fragment />
        )}
      </ActionsModal>
    </Box>
  );
};

export default Actions;
