import React from 'react';
import PageContainer from '../../../../@jumbo/components/PageComponents/layouts/PageContainer';

import AppealedOcurrences from '../../../../f5/appeal/AppealedOcurrences';
import OcurrenceNavbar from '../../../../f5/ocurrences/SharedComponents/OcurrenceNavbar';

const Appealed = () => {
  return (
    <PageContainer>
      <OcurrenceNavbar />
      <AppealedOcurrences />
    </PageContainer>
  );
};

export default Appealed;
