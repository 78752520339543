import generalService from '../../../services/f5-services/generalService';
import { INIT_OCURRENCES_DAILY } from './action-types';

export const initializeOcurrencesDaily = () => {
  return async dispatch => {
    const dailyOcurrences = await generalService.getDashboardData();
    dispatch({
      type: INIT_OCURRENCES_DAILY,
      data: dailyOcurrences.meses,
    });
  };
};
