import React, { useState } from 'react';
import { Box, Menu, IconButton } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import OcurrenceNavMenuItem from './OcurrenceNavMenuItem';

const OcurrenceNavMenu = ({ menus, selected, setSelected }) => {
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  if (!menus || menus.length === 0) {
    return <React.Fragment />;
  }

  return (
    <Box ml={2}>
      <IconButton aria-label="abrir menu ocorrências" onClick={handleClick} size="small">
        <MoreHorizIcon fontSize="small" />
      </IconButton>
      <Menu id="ocurrences-dropmenu" anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleClose}>
        {menus.map(item => (
          <OcurrenceNavMenuItem
            key={item.id}
            notifications={item.notifications}
            name={item.name}
            bgcolor={item.bgcolor}
            page={item.page}
          />
        ))}
      </Menu>
    </Box>
  );
};

export default OcurrenceNavMenu;
