import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { MenuItem } from '@material-ui/core';
import { useLocation, NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  menuItemRoot: {
    display: 'flex',
    alignItems: 'center',
    color: 'black',
    backgroundColor: 'rgba(0, 0, 0, 0)',
    '& .notifications': {
      display: ({ notifications }) => (notifications > 0 ? 'flex' : 'none'),
      justifyContent: 'center',
      alignItems: 'center',
      width: '20px',
      height: '20px',
      padding: '0.25rem',
      border: '1px solid white',
      borderRadius: '50%',
      backgroundColor: '#cf580e',
      boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
      marginLeft: '0.5rem',
    },
    '& .notifications > span': {
      fontWeight: '700',
      fontSize: '12px',
      textAlign: 'center',
      color: 'white',
    },
  },
  menuItemSelected: {
    '&.Mui-selected': {
      backgroundColor: ({ bgcolor }) => bgcolor,
      color: 'white',
    },
    '&.Mui-selected:hover': {
      backgroundColor: ({ bgcolor }) => bgcolor,
      opacity: 0.75,
    },
  },
}));

const OcurrenceNavMenuItem = React.forwardRef(({ notifications, page, name, bgcolor }, ref) => {
  const classes = useStyles({ notifications, bgcolor });

  const location = useLocation();

  const selected = location.pathname === page;

  return (
    <NavLink to={page ? page : '/'}>
      <MenuItem
        selected={selected}
        classes={{
          root: classes.menuItemRoot,
          selected: classes.menuItemSelected,
        }}
        ref={ref}>
        <span>{name}</span>
        <div className="notifications">
          <span>{notifications > 9 ? '9+' : notifications}</span>
        </div>
      </MenuItem>
    </NavLink>
  );
});

export default OcurrenceNavMenuItem;
