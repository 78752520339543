import axios from '../auth/jwt/config';

const changeUserInfo = async formData => {
  const response = await axios.post('atendente/user/change', formData);
  return response.data;
};

const changeUserPassword = async formData => {
  const response = await axios.post('atendente/user/changepass', formData);
  return response.data;
};

export default {
  changeUserInfo,
  changeUserPassword,
};
