import React from 'react';
import { Box, CircularProgress } from '@material-ui/core';

const SimpleLoader = ({ customMessage }) => {
  return (
    <Box width="100%" height={150} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
      <CircularProgress />
      <Box mt={4} fontWeight={500}>
        {customMessage || 'Carregando dados...'}
      </Box>
    </Box>
  );
};

export default SimpleLoader;
