import React from 'react';
import { Paper, Box, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
// import BlockIcon from '@material-ui/icons/Block'; // TODO: Depende do backend para saber se é sucesso ou não a solução, adaptando o ícone de acordo. Ver: FinishOcurrence.js

const useStyles = makeStyles(theme => ({
  paperRoot: { height: '100%' },
  cardRoot: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  textGrey: {
    color: 'rgba(0, 0, 0, 0.5)',
  },
}));

const isValidJSON = t => {
  try {
    JSON.parse(t);
    return true;
  } catch (e) {
    return false;
  }
};

const FinishedCard = ({ movement }) => {
  const classes = useStyles();

  const getSituation = () => {
    let situation = 'Indefinida';

    if (isValidJSON(movement.descricao)) {
      situation = JSON.parse(movement.descricao).situacao;
      return situation;
    }

    return situation;
  };

  const getPosition = () => {
    let position = 'Indefinida';

    if (isValidJSON(movement.descricao)) {
      position = JSON.parse(movement.descricao).posicionamento;
      return position;
    }

    return position;
  };

  return (
    <Paper className={classes.paperRoot}>
      <Box className={classes.cardRoot} py={2} px={4}>
        <Box>
          <Box display="flex" alignItems="center" my={2}>
            <CheckCircleOutlineIcon fontSize="small" />
            <Box ml={2} fontWeight={500}>
              Finalização
            </Box>
          </Box>
          <Box mb={2}>
            <Divider />
          </Box>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Box fontWeight={700} fontSize={14}>
            Situação:
          </Box>
          <Box ml={2}>{movement.descricao ? getSituation() : ''}</Box>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Box fontWeight={700} fontSize={14}>
            Posicionamento:
          </Box>
          <Box ml={2}>{movement.descricao ? getPosition() : ''}</Box>
        </Box>
        <Box mb={2} display="flex" alignItems="center">
          <Box fontWeight={700} fontSize={14}>
            Observação:
          </Box>
          <Box ml={2}>{movement.observacao}</Box>
        </Box>
      </Box>
    </Paper>
  );
};

export default FinishedCard;
